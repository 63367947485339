import React, { useEffect, useState } from 'react';
import {
    Form,
    Input,
    Button,
    notification,
    TimePicker,
    Table,
    Popconfirm,
    Modal
} from 'antd';
import moment from 'moment';
import { useParams, useHistory } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
    EditOutlined,
    DeleteOutlined
} from '@ant-design/icons';

import ChecklistService from '../../../../http/ChecklistService';
import DefaultContainer from '../../../../components/DefaultContainer';

/**
 * ChecklistForm:
 *  - If `checklistId` is present, we're in "edit" mode; fetch existing data.
 *  - If not, we're in "create" mode.
 */
const ChecklistForm = () => {
    const { branchId, checklistId } = useParams();
    const history = useHistory();
    const [form] = Form.useForm();

    // For main checklist
    const [isEdit, setIsEdit] = useState(false);
    const [loading, setLoading] = useState(false);

    // The items in this checklist
    const [items, setItems] = useState([]);

    // For "add item" sub-form
    const [itemForm] = Form.useForm();

    // For "edit item" modal
    const [editModalVisible, setEditModalVisible] = useState(false);
    const [currentItem, setCurrentItem] = useState(null);
    const [editForm] = Form.useForm(); // form inside the edit modal

    useEffect(() => {
        if (checklistId) {
            setIsEdit(true);
            fetchChecklist();
        } else {
            setIsEdit(false);
            // do not fetch items; new checklist
        }
    }, [branchId, checklistId]);

    /**
     * Fetch the existing checklist (and items) for editing
     */
    const fetchChecklist = () => {
        setLoading(true);
        ChecklistService.show(
            branchId,
            checklistId,
            (response) => {
                setLoading(false);
                const data = response.data.data;
                // Fill form with the main checklist fields
                form.setFieldsValue({
                    name: data.name,
                    description: data.description,
                    cycle_time: data.cycle_time
                        ? moment(data.cycle_time, 'HH:mm')
                        : null
                });
                // Store items in local state
                setItems(data.items || []);
            },
            (error) => {
                setLoading(false);
                notification.error({
                    message: 'Error',
                    description: 'Failed to load checklist data.'
                });
            }
        );
    };

    /**
     * On main form submit: create or update the checklist
     */
    const handleFinish = (values) => {
        setLoading(true);

        // Convert TimePicker's moment to "HH:mm"
        let cycleTimeStr = null;
        if (values.cycle_time) {
            cycleTimeStr = moment(values.cycle_time).format('HH:mm');
        }

        const payload = {
            name: values.name,
            description: values.description || '',
            cycle_time: cycleTimeStr
        };

        if (isEdit) {
            // UPDATE
            ChecklistService.update(
                branchId,
                checklistId,
                payload,
                (response) => {
                    setLoading(false);
                    notification.success({
                        message: 'Success',
                        description: response.data.message
                    });
                    // redirect to manage checklists
                    history.push(`/dashboard/operations/ck/checklists/manage/${branchId}/manage`);
                },
                (error) => {
                    setLoading(false);
                    notification.error({
                        message: 'Error',
                        description: 'Failed to update checklist.'
                    });
                }
            );
        } else {
            // CREATE
            ChecklistService.store(
                branchId,
                payload,
                (response) => {
                    setLoading(false);
                    notification.success({
                        message: 'Success',
                        description: response.data.message
                    });
                    // After creation, redirect or do whatever you prefer
                    history.push(`/dashboard/operations/ck/checklists/manage/${branchId}/manage`);
                },
                (error) => {
                    setLoading(false);
                    notification.error({
                        message: 'Error',
                        description: 'Failed to create checklist.'
                    });
                }
            );
        }
    };

    /**
     * ADD a new item (sub-form)
     */
    const handleAddItem = (values) => {
        if (!checklistId) {
            notification.warning({
                message: 'Not available',
                description: 'You cannot add items until the checklist is created.'
            });
            return;
        }

        const payload = {
            title: values.title,
            description: values.description || ''
        };

        setLoading(true);
        ChecklistService.addItem(
            branchId,
            checklistId,
            payload,
            (response) => {
                setLoading(false);
                notification.success({
                    message: 'Success',
                    description: response.data.message
                });
                // add to local state
                const newItem = response.data.data;
                setItems([...items, newItem]);
                // reset the "Add item" form
                itemForm.resetFields();
            },
            (error) => {
                setLoading(false);
                notification.error({
                    message: 'Error',
                    description: 'Failed to add item.'
                });
            }
        );
    };

    /**
     * DRAG & DROP reorder
     */
    const handleDragEnd = (result) => {
        if (!result.destination) return;

        const newItems = Array.from(items);
        const [moved] = newItems.splice(result.source.index, 1);
        newItems.splice(result.destination.index, 0, moved);
        setItems(newItems);

        // persist reorder to backend
        const reorderPayload = newItems.map((item, idx) => ({
            id: item.id,
            position: idx + 1
        }));

        setLoading(true);
        ChecklistService.reorderItems(
            branchId,
            checklistId,
            reorderPayload,
            (response) => {
                setLoading(false);
                notification.success({
                    message: 'Success',
                    description: 'Items reordered successfully.'
                });
            },
            (error) => {
                setLoading(false);
                notification.error({
                    message: 'Error',
                    description: 'Failed to reorder items.'
                });
            }
        );
    };

    /**
     * EDIT item => open modal
     */
    const handleEditItemClick = (item) => {
        setCurrentItem(item);
        editForm.setFieldsValue({
            title: item.title,
            description: item.description
        });
        setEditModalVisible(true);
    };

    const handleEditModalOk = () => {
        editForm.validateFields().then((values) => {
            setLoading(true);
            const payload = {
                title: values.title,
                description: values.description
            };
            ChecklistService.updateItem(
                branchId,
                checklistId,
                currentItem.id,
                payload,
                (response) => {
                    setLoading(false);
                    notification.success({
                        message: 'Success',
                        description: 'Item updated successfully.'
                    });
                    // update local state
                    const updated = items.map((itm) => {
                        if (itm.id === currentItem.id) {
                            return {
                                ...itm,
                                title: values.title,
                                description: values.description
                            };
                        }
                        return itm;
                    });
                    setItems(updated);

                    setEditModalVisible(false);
                    setCurrentItem(null);
                },
                (error) => {
                    setLoading(false);
                    notification.error({
                        message: 'Error',
                        description: 'Failed to update item.'
                    });
                }
            );
        });
    };

    const handleEditModalCancel = () => {
        setEditModalVisible(false);
        setCurrentItem(null);
    };

    /**
     * DELETE item
     */
    const handleDeleteItem = (itemId) => {
        setLoading(true);
        ChecklistService.removeItem(
            branchId,
            checklistId,
            itemId,
            (response) => {
                setLoading(false);
                notification.success({
                    message: 'Success',
                    description: response.data.message
                });
                const updated = items.filter((x) => x.id !== itemId);
                setItems(updated);
            },
            (error) => {
                setLoading(false);
                notification.error({
                    message: 'Error',
                    description: 'Failed to delete item.'
                });
            }
        );
    };

    return (
        <DefaultContainer>
            <h2 className="text-xl font-bold uppercase mb-4">
                {isEdit ? 'Edit Checklist' : 'Add New Checklist'}
            </h2>

            {/* MAIN CHECKLIST FORM */}
            <Form
                form={form}
                layout="vertical"
                onFinish={handleFinish}
                initialValues={{
                    name: '',
                    description: '',
                    cycle_time: null
                }}
            >
                <Form.Item
                    label="Checklist Name"
                    name="name"
                    rules={[{ required: true, message: 'Please enter a checklist name' }]}
                >
                    <Input placeholder="Enter checklist name" />
                </Form.Item>

                <Form.Item label="Description" name="description">
                    <Input.TextArea rows={3} placeholder="Enter any additional info" />
                </Form.Item>

                <Form.Item label="Cycle Time (HH:mm)" name="cycle_time">
                    <TimePicker format="HH:mm" placeholder="Select time" />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit" loading={loading}>
                        {isEdit ? 'Update Checklist' : 'Create Checklist'}
                    </Button>
                </Form.Item>
            </Form>

            {/* ONLY show items if we're editing an EXISTING checklist */}
            {isEdit && (
                <div className="mt-8">
                    <h3 className="text-lg font-bold mb-2">Checklist Items</h3>

                    {/* Drag & Drop context */}
                    <DragDropContext onDragEnd={handleDragEnd}>
                        <Droppable droppableId="checklistItems">
                            {(provided) => (
                                <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    style={{
                                        background: '#f9f9f9',
                                        padding: '10px',
                                        minHeight: '100px'
                                    }}
                                >
                                    {items.map((item, index) => (
                                        <Draggable
                                            key={item.id}
                                            draggableId={String(item.id)}
                                            index={index}
                                        >
                                            {(provided) => (
                                                <div
                                                    className="bg-white p-2 mb-2 border border-gray-200 flex justify-between items-center"
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                >
                                                    <div>
                                                        <div className="font-semibold">{item.title}</div>
                                                        {item.description && (
                                                            <div className="text-gray-500 text-sm">
                                                                {item.description}
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="flex space-x-2">
                                                        <Button
                                                            icon={<EditOutlined />}
                                                            onClick={() => handleEditItemClick(item)}
                                                        />
                                                        <Popconfirm
                                                            title="Are you sure to delete this item?"
                                                            onConfirm={() => handleDeleteItem(item.id)}
                                                            okText="Yes"
                                                            cancelText="No"
                                                        >
                                                            <Button icon={<DeleteOutlined />} danger />
                                                        </Popconfirm>
                                                    </div>
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>

                    {/* Add new item form */}
                    <div className="mt-4">
                        <h4 className="font-bold mb-2">Add a New Item</h4>
                        <Form
                            form={itemForm}
                            layout="vertical"
                            onFinish={handleAddItem}
                            initialValues={{
                                title: '',
                                description: ''
                            }}
                        >
                            <Form.Item
                                label="Title"
                                name="title"
                                rules={[{ required: true, message: 'Please enter item title' }]}
                            >
                                <Input placeholder="e.g. Check ovens" />
                            </Form.Item>

                            <Form.Item label="Description" name="description">
                                <Input.TextArea rows={2} placeholder="Details or instructions" />
                            </Form.Item>

                            <Form.Item>
                                <Button type="primary" htmlType="submit" loading={loading}>
                                    Add Item
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            )}

            {/* EDIT ITEM MODAL */}
            <Modal
                title="Edit Checklist Item"
                visible={editModalVisible}
                onOk={handleEditModalOk}
                onCancel={handleEditModalCancel}
                confirmLoading={loading}
            >
                <Form form={editForm} layout="vertical">
                    <Form.Item
                        name="title"
                        label="Title"
                        rules={[{ required: true, message: 'Please enter a title' }]}
                    >
                        <Input placeholder="e.g. Check ovens" />
                    </Form.Item>
                    <Form.Item name="description" label="Description">
                        <Input.TextArea rows={3} placeholder="Additional details" />
                    </Form.Item>
                </Form>
            </Modal>
        </DefaultContainer>
    );
};

export default ChecklistForm;
