import React, { useEffect } from 'react';
import { Button, Form, Input, DatePicker } from 'antd';
import FormItem from '../../components/Form/FormItem';
import { useDispatch, useSelector } from 'react-redux';
import {
    ResetAuthErrors,
    resetPassword,
    resetPasswordReset
} from '../../redux/authentication/actionCreator';
import { Link, useParams } from 'react-router-dom';
import FormError from '../../components/Form/FormError';
import { ResetActiveOrders } from "../../redux/checkout/actionCreator";
import dayjs from 'dayjs';

const AcceptInvitation = () => {
    const dispatch = useDispatch();
    const { token, email } = useParams();
    const [form] = Form.useForm();

    // States from Redux
    const isLoading = useSelector(state => state.auth.loading);
    const resetPasswordError = useSelector(state => state.auth.error);
    const resetPasswordDone = useSelector(state => state.auth.done);
    const resetPasswordValidationFields = useSelector(state => state.auth.fields);

    // Convert URL-encoded param
    const getDecoded = (txt) => decodeURIComponent(txt || '');

    useEffect(() => {
        // Reset any leftover states
        dispatch(resetPasswordReset());
        dispatch(ResetActiveOrders());
        dispatch(ResetAuthErrors());
    }, [dispatch]);

    // Handle form submission
    const handleSubmit = (values) => {
        // Convert DatePicker value to YYYY-MM-DD
        const birthdayString = values.birthday
            ? dayjs(values.birthday).format('YYYY-MM-DD')
            : null;

        dispatch(resetPassword({
            email: values.email,
            token: values.token,
            password: values.password,
            password_confirmation: values.password_confirmation,
            birthday: birthdayString, // pass birthday to the action
        }));
    };

    return (
        <div className="auth-contents container mx-auto bg-white mt-4 py-2 px-4 lg:py-4 lg:px-8">
            {resetPasswordDone ? (
                <div>
                    <div className="text-2xl font-bold mb-4">Registration Complete</div>
                    <p>
                        You can now log in with your new password.{" "}
                        <Link to="/login">Sign in</Link>.
                    </p>
                </div>
            ) : (
                <>
                    <Form
                        name="resetPassword"
                        form={form}
                        onFinish={handleSubmit}
                        layout="vertical"
                        initialValues={{
                            token,
                            email: getDecoded(email),
                        }}
                    >
                        <div className="text-2xl font-bold mb-4">Accept Your Invitation</div>

                        <FormError error={resetPasswordError} />

                        {/* Hidden fields for token & email */}
                        <FormItem name="token" hidden>
                            <Input type="hidden" />
                        </FormItem>
                        <FormItem name="email" hidden>
                            <Input type="hidden" />
                        </FormItem>

                        {/* Informational text */}
                        <div className="mb-6">
                            <p>
                                Hello <b>{getDecoded(email)}</b>,
                            </p>
                            <p>
                                You have been invited to join Patty Slaps. Please set your
                                password (and fill out your birthday) to complete your
                                registration.
                            </p>
                        </div>

                        {/* Birthday Field */}
                        <FormItem
                            name="birthday"
                            label="Birthday"
                            errors={resetPasswordValidationFields}
                            disabled={isLoading}
                        >
                            {/* or <Input placeholder="YYYY-MM-DD" /> if you prefer manual input */}
                            <DatePicker
                                disabled={isLoading}
                                style={{ width: "100%" }}
                                format="YYYY-MM-DD"
                            />
                        </FormItem>

                        <FormItem
                            name="password"
                            label="Password"
                            errors={resetPasswordValidationFields}
                            disabled={isLoading}
                            initialValue=""
                        >
                            <Input.Password
                                placeholder="Enter your new password"
                                disabled={isLoading}
                            />
                        </FormItem>

                        <FormItem
                            name="password_confirmation"
                            label="Confirm Password"
                            errors={resetPasswordValidationFields}
                            disabled={isLoading}
                            initialValue=""
                        >
                            <Input.Password
                                placeholder="Confirm your new password"
                                disabled={isLoading}
                            />
                        </FormItem>

                        <Button
                            htmlType="submit"
                            size="large"
                            type="primary"
                            loading={isLoading}
                        >
                            Accept Invitation
                        </Button>
                    </Form>
                </>
            )}
        </div>
    );
};

export default AcceptInvitation;
