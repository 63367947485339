import React, {useEffect, useState, useCallback} from 'react';
import {useParams, Link, useLocation, useHistory} from 'react-router-dom';
import {Button, notification, Pagination, Tag, Space, Table} from 'antd';
import BranchInventory from '../../../../../http/BranchInventory';
import SkeletonWrapper from '../../../../../components/skeletons/SkeletonWrapper';
import GenericSkeleton from '../../../../../components/skeletons/GenericSkeleton';
import DefaultContainer from '../../../../../components/DefaultContainer';
import moment from 'moment';
import Styled from 'styled-components';
import usePermission from '../../../../../layouts/usePermission';

const ContainerWrapper = Styled.div`
    .ant-card-body div {
        white-space: nowrap;
        width: auto !important;
    }
`;

const EndOfShiftReportsIndex = () => {
    const {branchId} = useParams();
    const location = useLocation();
    const history = useHistory();

    const isManager = usePermission(null, [
        'inventory_assistant_general_manager',
        'inventory_general_manager',
    ]);

    // Extract query params from URL
    const getQueryParams = useCallback(() => {
        const params = new URLSearchParams(location.search);
        return {
            page: parseInt(params.get('page'), 10) || 1,
            pageSize: parseInt(params.get('pageSize'), 10) || 10,
            sortField: params.get('sortField') || 'created_at',
            sortOrder: params.get('sortOrder') || 'desc',
        };
    }, [location.search]);

    const initialParams = getQueryParams();

    // Pagination & Sorter states
    const [pagination, setPagination] = useState({
        current: initialParams.page,
        pageSize: initialParams.pageSize,
        total: 0,
    });
    const [sorter, setSorter] = useState({
        field: initialParams.sortField,
        order: initialParams.sortOrder,
    });

    // Loading states
    const [loading, setLoading] = useState(true); // For the initial skeleton
    const [tableLoading, setTableLoading] = useState(false); // For subsequent table spinner
    const [didInitialLoad, setDidInitialLoad] = useState(false);

    // Data
    const [reports, setReports] = useState([]);

    // Check if the URL query params changed (page, pageSize, sortField, sortOrder)
    useEffect(() => {
        const {page, pageSize, sortField, sortOrder} = getQueryParams();
        const isDifferent =
            page !== pagination.current ||
            pageSize !== pagination.pageSize ||
            sortField !== sorter.field ||
            sortOrder !== sorter.order;

        if (isDifferent) {
            setPagination((prev) => ({
                ...prev,
                current: page,
                pageSize: pageSize,
            }));
            setSorter({
                field: sortField,
                order: sortOrder,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.search]);

    // Whenever branchId, pagination, or sorter changes => load reports
    useEffect(() => {
        loadReports();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagination.current, pagination.pageSize, sorter.field, sorter.order, branchId]);

    // -----------------------------------------------
    // Load reports from the server
    // -----------------------------------------------
    const loadReports = () => {
        // If we haven't done the initial load yet, show skeleton
        // Otherwise, show the table spinner
        if (!didInitialLoad) {
            setLoading(true);
        } else {
            setTableLoading(true);
        }

        BranchInventory.IndexEndOfShiftReports(
            branchId,
            {
                params: {
                    per_page: pagination.pageSize,
                    page: pagination.current,
                    sort_by: sorter.field,
                    sort_direction: sorter.order === 'ascend' ? 'asc' : 'desc',
                },
            },
            (response) => {
                if (response && response.data && response.data.data) {
                    const {data} = response.data;
                    const processedReports = data.reports.data.map((record) => {
                        let noticesInfo = 'None';
                        if (record.notices_given) {
                            const assignedUserName = record.notice_user
                                ? record.notice_user.name
                                : 'Unknown User';
                            noticesInfo = `${record.notice_type || 'Notice/Warning'} given to ${assignedUserName}`;
                        }

                        return {
                            ...record,
                            userName: record.user?.name || '-',
                            createdAtFormatted: moment(record.created_at).format('DD/MM/YYYY HH:mm'),
                            noticesInfo,
                        };
                    });

                    setReports(processedReports);
                    setPagination((prev) => ({
                        ...prev,
                        total: data.reports.total,
                    }));
                } else {
                    notification.error({
                        message: 'Error!',
                        description:
                            'Unexpected response format while loading end of shift reports.',
                    });
                }
                setLoading(false);
                setTableLoading(false);
                setDidInitialLoad(true);
            },
            (err) => {
                notification.error({
                    message: 'Error!',
                    description:
                        err.response?.data?.message ||
                        'Failed to load end of shift reports.',
                });
                setLoading(false);
                setTableLoading(false);
                setDidInitialLoad(true);
            }
        );
    };

    // -----------------------------------------------
    // Update the URL query params
    // -----------------------------------------------
    const updateURL = (newParams) => {
        const params = new URLSearchParams(location.search);
        Object.keys(newParams).forEach((key) => {
            if (newParams[key] !== undefined && newParams[key] !== null) {
                params.set(key, newParams[key]);
            } else {
                params.delete(key);
            }
        });
        history.push({
            pathname: location.pathname,
            search: params.toString(),
        });
    };

    // Handle table changes (pagination, sorting)
    const handleTableChange = (paginationConfig, filters, sorterConfig) => {
        const newPagination = {
            current: paginationConfig.current,
            pageSize: paginationConfig.pageSize,
            total: pagination.total,
        };
        const newSorter = {
            field: sorterConfig.field || sorter.field,
            order: sorterConfig.order || sorter.order,
        };
        setPagination(newPagination);
        setSorter(newSorter);
        updateURL({
            page: newPagination.current,
            pageSize: newPagination.pageSize,
            sortField: newSorter.field,
            sortOrder: newSorter.order,
        });
    };

    // Pagination
    const handlePageChange = (page, pageSize) => {
        setPagination((prev) => ({
            ...prev,
            current: page,
            pageSize: pageSize,
        }));
        updateURL({
            page,
            pageSize,
            sortField: sorter.field,
            sortOrder: sorter.order,
        });
    };

    const handlePerPageChange = (current, size) => {
        setPagination((prev) => ({
            ...prev,
            pageSize: size,
            current: 1,
        }));
        updateURL({
            page: 1,
            pageSize: size,
            sortField: sorter.field,
            sortOrder: sorter.order,
        });
    };

    // -----------------------------------------------
    // Quick calls to update a report's status
    // -----------------------------------------------
    const updateReportStatus = (id, newStatus) => {
        // Show table spinner (not skeleton)
        setTableLoading(true);

        BranchInventory.UpdateEndOfShiftReport(
            branchId,
            id,
            {status: newStatus},
            () => {
                notification.success({
                    message: 'Success',
                    description: `Report #${id} has been ${newStatus}.`,
                });
                loadReports();
            },
            () => {
                notification.error({
                    message: 'Error',
                    description: 'Failed to update status',
                });
                setTableLoading(false);
            }
        );
    };

    // -----------------------------------------------
    // Define columns
    // -----------------------------------------------
    const columns = [
        {
            title: 'Issuer',
            dataIndex: 'userName',
            key: 'userName',
            sorter: true,
            sortOrder: sorter.field === 'user.name' && sorter.order,
        },
        {
            title: 'Date of Report',
            dataIndex: 'date',
            key: 'date',
            sorter: true,
            sortOrder: sorter.field === 'date' && sorter.order,
        },
        {
            title: 'Expire At',
            dataIndex: 'expiration_date',
            key: 'expiration_date',
            sorter: true,
            sortOrder: sorter.field === 'expiration_date' && sorter.order,
        },
        {
            title: 'Submitted At',
            dataIndex: 'createdAtFormatted',
            key: 'createdAtFormatted',
            sorter: true,
            sortOrder: sorter.field === 'created_at' && sorter.order,
        },
        {
            title: 'Shift',
            dataIndex: 'shift',
            key: 'shift',
        },
        {
            title: 'Notices/Warnings',
            dataIndex: 'noticesInfo',
            key: 'noticesInfo',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (status) => {
                if (status === 'pending') return <Tag color="orange">Pending</Tag>;
                if (status === 'confirmed') return <Tag color="green">Confirmed</Tag>;
                if (status === 'rejected') return <Tag color="red">Rejected</Tag>;
                return status;
            },
        },
        {
            title: 'Actions',
            dataIndex: 'id',
            key: 'actions',
            fixed: 'right',
            width: '182px',
            render: (id, record) => (
                <div className={"flex flex-col"}>
                    <div>
                        <Link to={`/dashboard/operations/daily-logs/end-of-shift/report/${id}`}>
                            <Button type="link">View</Button>
                        </Link>

                        {/* If manager, also show Edit link */}
                        {isManager && (
                            <Link
                                to={`/dashboard/operations/daily-logs/end-of-shift/${branchId}/report/${id}/edit`}
                            >
                                <Button type="link">Edit</Button>
                            </Link>
                        )}
                    </div>

                    {/* If manager & status is pending => quick confirm/reject */}
                    {isManager && record && record.status === 'pending' && (
                        <div className={"flex flex-row space-x-2"}>
                            <Button onClick={() => updateReportStatus(id, 'confirmed')}>
                                Confirm
                            </Button>
                            <Button danger onClick={() => updateReportStatus(id, 'rejected')}>
                                Reject
                            </Button>
                        </div>
                    )}
                </div>
            ),
        },
    ];

    // -----------------------------------------------
    // Render
    // -----------------------------------------------
    return (
        <ContainerWrapper>
            {/* Only show skeleton during the very first load */}
            <SkeletonWrapper loading={loading} skeleton={GenericSkeleton}>
                <DefaultContainer>
                    <h2>End of Shift Reports</h2>

                    <Table
                        columns={columns}
                        dataSource={reports}
                        rowKey="id"
                        pagination={false}
                        loading={tableLoading}
                        onChange={handleTableChange}
                        // Add horizontal scroll so fixed column works properly
                        scroll={{x: 1200}}
                    />

                    <div className="mt-4 ml-4 w-full">
                        <Pagination
                            current={pagination.current}
                            pageSize={pagination.pageSize}
                            total={pagination.total}
                            onChange={handlePageChange}
                            onShowSizeChange={handlePerPageChange}
                            showSizeChanger
                            pageSizeOptions={['10', '20', '50']}
                            showTotal={(total) => `Total ${total} items`}
                            className="w-full"
                        />
                    </div>
                </DefaultContainer>
            </SkeletonWrapper>
        </ContainerWrapper>
    );
};

export default EndOfShiftReportsIndex;
