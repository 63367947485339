import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Form, Input, Button, Select, notification } from 'antd';
import BranchInventory from '../../../../../http/BranchInventory';
import SkeletonWrapper from '../../../../../components/skeletons/SkeletonWrapper';
import GenericSkeleton from '../../../../../components/skeletons/GenericSkeleton';
import DefaultContainer from '../../../../../components/DefaultContainer';
import usePermission from "../../../../../layouts/usePermission";

const { Option } = Select;

const EditEndOfShiftReport = () => {
    const [form] = Form.useForm();
    const history = useHistory();
    const { branchId, reportId } = useParams();

    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [report, setReport] = useState(null);
    const [employees, setEmployees] = useState([]);

    const [incidentsReported, setIncidentsReported] = useState(false);
    const [noticesGiven, setNoticesGiven] = useState(false);

    // Only managers can actually change the status in the UI
    const isManager = usePermission(null, [
        "inventory_assistant_general_manager",
        "inventory_general_manager"
    ]);

    useEffect(() => {
        // Load possible employees (for notices)
        BranchInventory.IndexBranchEmployees(branchId,
        response => {
            const data = response.data.data;
            setEmployees(data.employees || []);
            setLoading(false);
        },
        error => {
            notification.error({
                message: 'Error',
                description: 'Failed to load employees'
            });
            setLoading(false);
        });

        // Load existing report
        BranchInventory.ShowEndOfShiftReport(
            reportId,
            (response) => {
                const existingReport = response.data.data.report;
                setReport(existingReport);
                setLoading(false);

                // Initialize form fields
                form.setFieldsValue({
                    date: existingReport.date,
                    shift: existingReport.shift,
                    status: existingReport.status,
                    summary: existingReport.summary,
                    challenges: existingReport.challenges,
                    out_of_stock_materials: existingReport.out_of_stock_materials,
                    unresolved_issues: existingReport.unresolved_issues,
                    incidents: existingReport.incidents ? 'Yes' : 'No',
                    incident_description: existingReport.incident_description,
                    notices_given: existingReport.notices_given ? 'Yes' : 'No',
                    notice_type: existingReport.notice_type,
                    notice_user_id: existingReport.notice_user_id,
                    notice_description: existingReport.notice_description
                });

                setIncidentsReported(existingReport.incidents);
                setNoticesGiven(existingReport.notices_given);
            },
            (err) => {
                notification.error({
                    message: 'Error!',
                    description: 'Failed to load end of shift report.',
                });
                setLoading(false);
            }
        );
    }, [branchId, reportId, form]);

    const onFinish = (values) => {
        setSaving(true);

        // Convert 'Yes'/'No' strings to booleans
        const payload = {
            ...values,
            incidents: values.incidents === 'Yes',
            notices_given: values.notices_given === 'Yes',
        };

        // Call update
        BranchInventory.UpdateEndOfShiftReport(
            branchId,
            reportId,
            payload,
            (response) => {
                notification.success({
                    message: 'Success!',
                    description: 'End of shift report updated successfully.',
                });
                // After saving, go back to the show page (or index):
                history.push(`/dashboard/operations/daily-logs/end-of-shift/report/${reportId}`);
                setSaving(false);
            },
            (err) => {
                if (err.response && err.response.data && err.response.data.errors) {
                    form.setFields(
                        Object.keys(err.response.data.errors).map((key) => ({
                            name: key,
                            errors: err.response.data.errors[key],
                        }))
                    );
                } else {
                    notification.error({
                        message: 'Error!',
                        description: 'Failed to update end of shift report.',
                    });
                }
                setSaving(false);
            }
        );
    };

    return (
        <SkeletonWrapper loading={loading} skeleton={GenericSkeleton}>
            <DefaultContainer>
                <h2>Edit End of Shift Report</h2>
                {report && (
                    <Form
                        form={form}
                        onFinish={onFinish}
                        layout="vertical"
                    >
                        <Form.Item
                            name="date"
                            label="Date"
                            rules={[{ required: true, message: 'Please select a date' }]}
                        >
                            <Input type="date" />
                        </Form.Item>

                        <Form.Item
                            name="shift"
                            label="Shift"
                            rules={[{ required: true, message: 'Please select a shift' }]}
                        >
                            <Select>
                                <Option value="Start of Day">Start of Day</Option>
                                <Option value="End of Day">End of Day</Option>
                            </Select>
                        </Form.Item>

                        {/* Only managers should be able to change status */}
                        {isManager && (
                            <Form.Item
                                name="status"
                                label="Report Status"
                            >
                                <Select>
                                    <Option value="pending">Pending</Option>
                                    <Option value="confirmed">Confirmed</Option>
                                    <Option value="rejected">Rejected</Option>
                                </Select>
                            </Form.Item>
                        )}

                        <Form.Item
                            name="summary"
                            label="Summary of Operations"
                            rules={[{ required: true, message: 'Please provide a summary of operations' }]}
                        >
                            <Input.TextArea rows={4} />
                        </Form.Item>

                        <Form.Item
                            name="challenges"
                            label="Challenges Encountered"
                            rules={[{ required: true, message: 'Please provide challenges encountered' }]}
                        >
                            <Input.TextArea rows={4} />
                        </Form.Item>

                        <Form.Item
                            name="out_of_stock_materials"
                            label="Materials Out of Stock"
                            rules={[{ required: true, message: 'Please list the materials that were out of stock' }]}
                        >
                            <Input.TextArea rows={4} />
                        </Form.Item>

                        <Form.Item
                            name="unresolved_issues"
                            label="Unresolved Issues"
                        >
                            <Input.TextArea rows={4} />
                        </Form.Item>

                        <Form.Item
                            name="incidents"
                            label="Any Incidents to Report?"
                            rules={[{ required: true, message: 'Please specify if there were any incidents' }]}
                        >
                            <Select onChange={(value) => setIncidentsReported(value === 'Yes')}>
                                <Option value="No">No</Option>
                                <Option value="Yes">Yes</Option>
                            </Select>
                        </Form.Item>

                        {incidentsReported && (
                            <Form.Item
                                name="incident_description"
                                label="Incident Description"
                                rules={[{ required: true, message: 'Please describe the incident' }]}
                            >
                                <Input.TextArea rows={4} />
                            </Form.Item>
                        )}

                        <Form.Item
                            name="notices_given"
                            label="Were Any Notices or Warnings Given Out?"
                            rules={[{ required: true, message: 'Please specify if any notices/warnings were given' }]}
                        >
                            <Select onChange={(value) => setNoticesGiven(value === 'Yes')}>
                                <Option value="No">No</Option>
                                <Option value="Yes">Yes</Option>
                            </Select>
                        </Form.Item>

                        {noticesGiven && (
                            <>
                                <Form.Item
                                    name="notice_type"
                                    label="Type"
                                    rules={[{ required: true, message: 'Please select a type' }]}
                                >
                                    <Select>
                                        <Option value="Notice">Notice</Option>
                                        <Option value="Warning">Warning</Option>
                                    </Select>
                                </Form.Item>

                                <Form.Item
                                    name="notice_user_id"
                                    label="Select Employee"
                                    rules={[{ required: true, message: 'Please select an employee' }]}
                                >
                                    <Select
                                        showSearch
                                        filterOption={(input, option) =>
                                            (option.children || '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        placeholder="Search Employee"
                                    >
                                        {employees.map(emp => (
                                            <Option key={emp.id} value={emp.id}>
                                                {emp.name}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>

                                <Form.Item
                                    name="notice_description"
                                    label="Notice/Warning Description"
                                    rules={[{ required: true, message: 'Please provide a description' }]}
                                >
                                    <Input.TextArea rows={4} />
                                </Form.Item>
                            </>
                        )}

                        <Form.Item>
                            <Button type="primary" htmlType="submit" loading={saving}>
                                Save Changes
                            </Button>
                        </Form.Item>
                    </Form>
                )}
            </DefaultContainer>
        </SkeletonWrapper>
    );
};

export default EditEndOfShiftReport;
