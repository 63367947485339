import axios from 'axios';
import { checkLoggedIn, getAuthenticationHeaders } from './Headers';

const MeatTypeService = {
    index: (successCb, failCb) => {
        axios.get('/meat-types', getAuthenticationHeaders())
            .then(resp => successCb(resp))
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },

    store: (data, successCb, failCb) => {
        axios.post('/meat-types', data, getAuthenticationHeaders())
            .then(resp => successCb(resp))
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },

    show: (id, successCb, failCb) => {
        axios.get(`/meat-types/${id}`, getAuthenticationHeaders())
            .then(resp => successCb(resp))
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },

    update: (id, data, successCb, failCb) => {
        axios.put(`/meat-types/${id}`, data, getAuthenticationHeaders())
            .then(resp => successCb(resp))
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },

    destroy: (id, successCb, failCb) => {
        axios.delete(`/meat-types/${id}`, getAuthenticationHeaders())
            .then(resp => successCb(resp))
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },

    upsertBulk: (meatTypesArray, successCb, failCb) => {
        axios.post('/meat-types/bulk', { meat_types: meatTypesArray }, getAuthenticationHeaders())
            .then(resp => successCb(resp))
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },

};

export default MeatTypeService;
