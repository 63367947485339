import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { InputNumber, Button, Switch, notification } from 'antd';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { motion, AnimatePresence } from 'framer-motion';
import SkeletonWrapper from "../../../../../components/skeletons/SkeletonWrapper";
import GenericSkeleton from "../../../../../components/skeletons/GenericSkeleton";
import DefaultContainer from "../../../../../components/DefaultContainer";
import BranchInventoryGroup from "../../../../../http/BranchInventoryGroup";

function getLocalStorageKey(branchId, isConfirmed, workingDate) {
    return `inventoryEndOfDayData_${branchId}_${isConfirmed}_${workingDate}`;
}

/**
 * Remove localStorage items whose key starts with "inventoryEndOfDayData_"
 * and whose embedded date is older than `currentDate`.
 * E.g. key = "inventoryEndOfDayData_123_true_2023-09-05"
 */
function cleanupOldInventoryData(currentDate) {
    // Make sure we have a valid date to compare.
    // We'll parse currentDate as YYYY-MM-DD into a Date object for comparison.
    const cutoff = new Date(currentDate); // e.g. "2024-01-15"

    // We'll collect keys first; modifying localStorage while iterating can be tricky.
    const keysToRemove = [];

    for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        if (key && key.startsWith("inventoryEndOfDayData_")) {
            // key format: inventoryEndOfDayData_{branchId}_{isConfirmed}_{YYYY-MM-DD}
            const parts = key.split("_");
            if (parts.length === 4) {
                const dateString = parts[3]; // e.g. "2023-09-05"
                const keyDate = new Date(dateString);

                // if it's older, mark for removal
                if (keyDate < cutoff) {
                    keysToRemove.push(key);
                }
            }
        }
    }

    // Remove them
    keysToRemove.forEach((k) => {
        localStorage.removeItem(k);
    });
}

// Promise wrapper, if needed
function getBranchInventoryGroupsPromise(branchId, isConfirmed) {
    return new Promise((resolve, reject) => {
        BranchInventoryGroup.GetBranchInventoryGroups(
            branchId,
            isConfirmed,
            (response) => resolve(response),
            (error) => reject(error)
        );
    });
}

const InventoryEndOfDay = () => {
    const { branchId, confirmation } = useParams();
    const isConfirmed = confirmation === "1" || confirmation === "true";
    const history = useHistory();

    // Basic states
    const [branchInventoryGroups, setBranchInventoryGroups] = useState([]);
    const [branch, setBranch] = useState(null);
    const [workingDate, setWorkingDate] = useState("");
    const [quantities, setQuantities] = useState({});
    const [showActualWeight, setShowActualWeight] = useState({});

    // Loading / submission
    const [loading, setLoading] = useState(true);
    const [submitting, setSubmitting] = useState(false);

    // A flag to ensure single initialization
    const [initialized, setInitialized] = useState(false);

    // -------------------------
    // 1) Single-time init + cleanup
    // -------------------------
    useEffect(() => {
        async function loadData() {
            setLoading(true);
            try {
                // 1. Get data from server
                const response = await getBranchInventoryGroupsPromise(branchId, isConfirmed);
                const { branch_inventory_groups, daily_records, branch, working_date } =
                    response.data.data;

                // 2. Clean localStorage from older dates
                cleanupOldInventoryData(working_date);

                // 3. Build initial structures
                const initialQuantities = {};
                const initialShowActualWeight = {};

                branch_inventory_groups.forEach((group) => {
                    initialQuantities[group.id] = {};
                    initialShowActualWeight[group.id] = true;

                    const record = daily_records.find((r) => r.branch_inventory_group_id === group.id);
                    if (record) {
                        const containers = isConfirmed
                            ? record.confirmed_containers
                            : record.not_confirmed_containers;

                        containers.forEach((container) => {
                            initialQuantities[group.id][container.branch_inventory_group_container_id] = {
                                full: container.quantity,
                                remainings: container.remainings || [],
                            };
                        });
                    }
                });

                // 4. Attempt to load existing localStorage for THIS date
                const storageKey = getLocalStorageKey(branchId, isConfirmed, working_date);
                let finalQuantities = initialQuantities;
                const savedData = localStorage.getItem(storageKey);
                if (savedData) {
                    finalQuantities = JSON.parse(savedData);
                }

                // 5. Update states
                setBranchInventoryGroups(branch_inventory_groups);
                setBranch(branch);
                setWorkingDate(working_date);
                setShowActualWeight(initialShowActualWeight);
                setQuantities(finalQuantities);

                setLoading(false);
                setInitialized(true);
            } catch (err) {
                setLoading(false);
                notification["error"]({
                    message: "Error!",
                    description: err.response?.data?.message || "Failed to load data",
                });
                history.push("/dashboard/operations/daily-logs/inventory/end-of-day/branch");
            }
        }

        if (!initialized) {
            loadData();
        }
    }, [initialized, branchId, isConfirmed, history]);

    // -------------------------
    // 2) Save to localStorage
    // -------------------------
    useEffect(() => {
        if (initialized && workingDate) {
            const storageKey = getLocalStorageKey(branchId, isConfirmed, workingDate);
            localStorage.setItem(storageKey, JSON.stringify(quantities));
        }
    }, [initialized, workingDate, quantities, branchId, isConfirmed]);

    // -------------------------
    // Handlers
    // -------------------------
    const handleQuantityChange = (groupId, containerId, value) => {
        setQuantities((prev) => {
            const updated = { ...prev };
            if (!updated[groupId]) {
                updated[groupId] = {};
            }
            if (!updated[groupId][containerId]) {
                updated[groupId][containerId] = { full: 0, remainings: [] };
            }
            updated[groupId][containerId].full = value;
            return updated;
        });
    };

    const handleRemainingWeightChange = (groupId, containerId, index, value) => {
        setQuantities((prev) => {
            const updated = { ...prev };
            if (!updated[groupId]) {
                updated[groupId] = {};
            }
            if (!updated[groupId][containerId]) {
                updated[groupId][containerId] = { full: 0, remainings: [] };
            }
            updated[groupId][containerId].remainings[index] = value;
            return updated;
        });
    };

    const addRemainingContainer = (groupId, containerId) => {
        setQuantities((prev) => {
            const updated = { ...prev };
            if (!updated[groupId][containerId]) {
                updated[groupId][containerId] = { full: 0, remainings: [] };
            }
            updated[groupId][containerId].remainings.push(0);
            return updated;
        });
    };

    const removeRemainingContainer = (groupId, containerId, idx) => {
        setQuantities((prev) => {
            const updated = { ...prev };
            updated[groupId][containerId].remainings.splice(idx, 1);
            return updated;
        });
    };

    const handleWeightToggle = (groupId) => {
        setShowActualWeight((prev) => ({
            ...prev,
            [groupId]: !prev[groupId],
        }));
    };

    const incrementValue = (groupId, containerId) => {
        const currentValue = quantities[groupId]?.[containerId]?.full || 0;
        handleQuantityChange(groupId, containerId, currentValue + 1);
    };

    const decrementValue = (groupId, containerId) => {
        const currentValue = quantities[groupId]?.[containerId]?.full || 0;
        handleQuantityChange(groupId, containerId, Math.max(currentValue - 1, 0));
    };

    // -------------------------
    // Reset
    // -------------------------
    const resetValues = () => {
        // blank structure
        const blankQuantities = {};
        branchInventoryGroups.forEach((group) => {
            blankQuantities[group.id] = {};
            group.branch_inventory_group_containers.forEach((container) => {
                blankQuantities[group.id][container.id] = { full: 0, remainings: [] };
            });
        });
        setQuantities(blankQuantities);

        if (workingDate) {
            const storageKey = getLocalStorageKey(branchId, isConfirmed, workingDate);
            localStorage.removeItem(storageKey);
        }
    };

    // -------------------------
    // Submit
    // -------------------------
    const handleSubmit = () => {
        setSubmitting(true);
        BranchInventoryGroup.SubmitEndOfDay(
            branchId,
            quantities,
            isConfirmed,
            (response) => {
                setSubmitting(false);
                notification["success"]({
                    message: "Success!",
                    description: response.data.message,
                });

                // remove this date’s localStorage
                if (workingDate) {
                    const storageKey = getLocalStorageKey(branchId, isConfirmed, workingDate);
                    localStorage.removeItem(storageKey);
                }

                // reset state
                setQuantities({});
                setInitialized(false);

                // navigate
                if (isConfirmed) {
                    history.push("/dashboard/operations/daily-logs/inventory/start-of-day/branch");
                } else {
                    history.push("/dashboard/operations/daily-logs/inventory/end-of-day/branch");
                }
            },
            (err) => {
                setSubmitting(false);
                notification["error"]({
                    message: "Error!",
                    description: err.response?.data?.message,
                });
            }
        );
    };

    // -------------------------
    // Calculations & Rendering
    // -------------------------
    const calculateActualWeight = (group) => {
        let totalWeight = 0;
        const groupQuantities = quantities[group.id] || {};

        group.branch_inventory_group_containers.forEach((container) => {
            const cQty = groupQuantities[container.id] || { full: 0, remainings: [] };
            const fullContainersWeight =
                cQty.full * (container.full_container_weight - container.container.empty_weight);

            let remainingsWeight = 0;
            cQty.remainings.forEach((rWeight) => {
                if (rWeight > container.container.empty_weight) {
                    remainingsWeight += rWeight - container.container.empty_weight;
                }
            });
            totalWeight += fullContainersWeight + remainingsWeight;
        });
        return totalWeight;
    };

    const getContainerWeight = (container, fullCount, partialPercent, showActual) => {
        const fullWt = showActual
            ? container.full_container_weight
            : (container.full_container_weight - container.container.empty_weight);

        return fullCount * fullWt + (partialPercent / 100) * fullWt;
    };

    const renderContainerPresentation = (groupId, container, group) => {
        const cQty = quantities[groupId]?.[container.id] || { full: 0, remainings: [] };
        const totalFullContainers = cQty.full || 0;
        const showActual = showActualWeight[groupId] || false;

        return (
            <AnimatePresence>
                {[...Array(totalFullContainers)].map((_, i) => (
                    <motion.div
                        key={i}
                        className="relative my-2"
                        initial={{ opacity: 0, y: -20 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, x: -20 }}
                        style={{ width: '60px', marginRight: '10px' }}
                    >
                        <img
                            src={container.container.image_url}
                            alt={container.container.name}
                            style={{ width: '60px' }}
                        />
                        <div className="absolute bottom-0 left-0 w-full text-center text-white bg-black bg-opacity-50 whitespace-nowrap">
                            {getContainerWeight(container, 1, 0, showActual).toFixed(0)}
                            {group.unit_type}
                        </div>
                    </motion.div>
                ))}
                {cQty.remainings.map((rWeight, idx) => (
                    <motion.div
                        key={`weight-${container.id}-${idx}`}
                        className="relative my-2"
                        style={{ width: '60px', marginRight: '10px' }}
                        initial={{ opacity: 0, y: -20 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, x: -20 }}
                    >
                        <img
                            src={container.container.image_url}
                            alt={container.container.name}
                            style={{ width: '60px' }}
                        />
                        <div
                            className="absolute bottom-0 left-0 w-full"
                            style={{ height: '100%', backgroundColor: 'rgba(0, 255, 255, 0.2)' }}
                        />
                        <div className="absolute bottom-0 left-0 w-full text-center text-white bg-black bg-opacity-50">
                            {Number(rWeight || 0).toFixed(0)}
                            {group.unit_type}
                        </div>
                    </motion.div>
                ))}
            </AnimatePresence>
        );
    };

    return (
        <DefaultContainer className="mx-auto max-w-8xl" append>
            <div className="flex flex-col py-3 w-full select-none">
                {branch && (
                    <h1 className="text-2xl font-bold mb-4 uppercase patty upper border-b border-gray-200">
                        {isConfirmed ? 'Start' : 'End'} of Day Inventory -{' '}
                        <span>{branch.name} ({workingDate})</span>
                    </h1>
                )}

                <SkeletonWrapper loading={loading} skeleton={GenericSkeleton}>
                    {branchInventoryGroups.map((group) => (
                        <div
                            key={group.id}
                            className="p-2 pt-0 border bg-white rounded-lg shadow-lg mb-4 border-gray-300"
                        >
                            <div className="font-bold text-3xl patty pt-2 border-b border-gray-300 flex flex-row items-center justify-between">
                                {group.name} (Total: {calculateActualWeight(group).toFixed(0)} {group.unit_type})
                                <div className="ml-4 text-lg flex flex-row items-center hidden">
                                    <div className="mr-2 -mb-1">
                                        {showActualWeight[group.id]
                                            ? "Ingredient with container weight"
                                            : "Ingredient weight only"}
                                    </div>
                                    <Switch
                                        checked={showActualWeight[group.id] || false}
                                        onChange={() => handleWeightToggle(group.id)}
                                    />
                                </div>
                            </div>

                            {group.branch_inventory_group_containers.map((container) => (
                                <div
                                    key={container.id}
                                    className="flex flex-col lg:flex-row border-b border-gray-300 py-1 lg:items-center"
                                >
                                    <div className="flex flex-row justify-between lg:justify-start">
                                        <div className="flex flex-row">
                                            <img
                                                src={container.container.image_url}
                                                alt={container.container.name}
                                                style={{ width: '60px', marginRight: '10px' }}
                                            />
                                            <span className="w-48 font-bold">{container.container.name}</span>
                                        </div>
                                        <div className="flex flex-col">
                                            <div className="pt-2 flex items-center pl-2">
                                                <Button
                                                    icon={<MinusOutlined />}
                                                    onClick={() => decrementValue(group.id, container.id)}
                                                />
                                                <InputNumber
                                                    min={0}
                                                    value={quantities[group.id]?.[container.id]?.full || 0}
                                                    onChange={(val) => handleQuantityChange(group.id, container.id, val)}
                                                    style={{ width: '60px', marginLeft: '10px', marginRight: '10px' }}
                                                    type="number"
                                                />
                                                <Button
                                                    icon={<PlusOutlined />}
                                                    onClick={() => incrementValue(group.id, container.id)}
                                                />
                                            </div>

                                            {/* partial remainings */}
                                            {(quantities[group.id]?.[container.id]?.remainings || []).map(
                                                (weightVal, idx) => (
                                                    <div key={idx} className="flex items-center my-2">
                                                        <InputNumber
                                                            min={0}
                                                            value={weightVal}
                                                            onChange={(val) =>
                                                                handleRemainingWeightChange(group.id, container.id, idx, val)
                                                            }
                                                            style={{ width: '150px', marginRight: '10px' }}
                                                            placeholder="Partial Container Weight"
                                                            type="number"
                                                        />
                                                        <Button
                                                            danger
                                                            onClick={() => removeRemainingContainer(group.id, container.id, idx)}
                                                        >
                                                            Remove
                                                        </Button>
                                                    </div>
                                                )
                                            )}

                                            <Button
                                                type="dashed"
                                                style={{ marginTop: '10px' }}
                                                onClick={() => addRemainingContainer(group.id, container.id)}
                                            >
                                                Add Partial Container
                                            </Button>
                                        </div>
                                    </div>

                                    <div className="bg-gray-200 px-2 lg:ml-5 mt-2 lg:mt-0 flex flex-row justify-center lg:justify-start rounded-lg overflow-hidden overflow-x-auto">
                                        {renderContainerPresentation(group.id, container, group)}
                                    </div>
                                </div>
                            ))}
                        </div>
                    ))}
                    <div className="flex flex-row justify-between items-center">
                        <Button danger onClick={resetValues}>
                            Reset Values
                        </Button>
                        <Button
                            type="primary"
                            className="primary-button"
                            onClick={handleSubmit}
                            loading={submitting}
                        >
                            Submit
                        </Button>
                    </div>
                </SkeletonWrapper>
            </div>
        </DefaultContainer>
    );
};

export default InventoryEndOfDay;
