import React from 'react';

const Privacy = () => {
    return (
        <div className="p-5 container mx-auto bg-white m-5 ">
            <div className="text-2xl font-extrabold uppercase mb-4">
                Privacy and Policy
            </div>
            <div className="PrivacyPolicy_privacy-policy__main__29LQH">
                <div className="PrivacyPolicy_content-wrapper__17LH4">
                    <div>
                        <div className="otnotice-content">
                            <ul className="otnotice-menu">
                                <li className="otnotice-menu-section">
                                    <a href="#otnotice-section-ed98e611-91ab-47f8-9bc9-53e2e7a75c76">
                                        Introduction
                                    </a>
                                </li>
                                <li className="otnotice-menu-section">
                                    <a href="#otnotice-section-04c2d59b-068a-4737-b1a1-35e7bfcf37bd">
                                        1. WHAT PERSONAL INFORMATION DO WE COLLECT ABOUT YOU?
                                    </a>
                                </li>
                                <li className="otnotice-menu-section">
                                    <a href="#otnotice-section-c997750b-2fce-4704-ad8f-d25f90c1137a">
                                        2. HOW DO WE USE PERSONAL INFORMATION?{" "}
                                    </a>
                                </li>
                                <li className="otnotice-menu-section">
                                    <a href="#otnotice-section-f5fded6b-5cd0-47fd-b737-611ec3ca2173">
                                        3. WHAT INFORMATION MAY BE COLLECTED AUTOMATICALLY?{" "}
                                    </a>
                                </li>
                                <li className="otnotice-menu-section">
                                    <a href="#otnotice-section-6befa163-b56f-4bea-9ae0-d8c0191b93c4">
                                        4. HOW WE SHARE YOUR INFORMATION
                                    </a>
                                </li>
                                <li className="otnotice-menu-section">
                                    <a href="#otnotice-section-70213f56-22bb-47cf-bdff-9bd91d4b03d3">
                                        5. YOUR CHOICES AND CONTROL OVER YOUR INFORMATION
                                    </a>
                                </li>
                                <li className="otnotice-menu-section">
                                    <a href="#otnotice-section-4cdd74cf-eac0-4538-a803-b5a02b586837">
                                        6. HOW WE STORE AND PROTECT YOUR INFORMATION
                                    </a>
                                </li>
                                <li className="otnotice-menu-section">
                                    <a href="#otnotice-section-655e64db-4032-4790-a3c1-9dab6787d82b">
                                        7. JURISDICTIONAL DISCLOSURES
                                    </a>
                                </li>
                                <li className="otnotice-menu-section">
                                    <a href="#otnotice-section-83199b1f-6a9b-4130-85b7-a36f4b622336">
                                        8. CHILDREN’S PRIVACY
                                    </a>
                                </li>
                                <li className="otnotice-menu-section">
                                    <a href="#otnotice-section-ea5c03f3-a9b3-4209-9c35-012d281ea62f">
                                        9. LINKS TO OTHER WEB SITES AND SERVICES
                                    </a>
                                </li>
                                <li className="otnotice-menu-section">
                                    <a href="#otnotice-section-8a726cb7-e160-4566-833b-64a4b5e62420">
                                        10. HOW TO CONTACT US
                                    </a>
                                </li>
                                <li className="otnotice-menu-section">
                                    <a href="#otnotice-section-14c547d4-cdf2-4073-9720-8b6c0f914004">
                                        11. CHANGES TO OUR PRIVACY POLICY
                                    </a>
                                </li>
                            </ul>
                            <div className="otnotice-sections">
                                <section
                                    className="otnotice-section"
                                    id="otnotice-section-ed98e611-91ab-47f8-9bc9-53e2e7a75c76"
                                >
                                    <div className="otnotice-section-content">
                                        <p id="isPasted">
                                            Patty Slaps®&nbsp;(“<strong>Patty Slaps</strong>,” “we,” “our,” or “us”) is
                                            committed to protecting your privacy. &nbsp; This Patty Slaps Privacy
                                            Policy (this “<strong>Policy</strong>”) applies to our websites,
                                            online experiences and mobile apps for Apple iOS, &nbsp;Windows,
                                            or Android mobile devices that link to this Policy
                                            (collectively, our “<strong>Sites</strong>”), and describes how
                                            we collect, use and disclose your personal information when you
                                            visit our Sites or our restaurants and in-store kiosks, or
                                            otherwise engage with us (collectively, our “
                                            <strong>Service</strong>”).&nbsp;
                                        </p>
                                    </div>
                                </section>
                                <section
                                    className="otnotice-section"
                                    id="otnotice-section-04c2d59b-068a-4737-b1a1-35e7bfcf37bd"
                                >
                                    <h2 className="otnotice-section-header">
                                        1. WHAT PERSONAL INFORMATION DO WE COLLECT ABOUT YOU?
                                    </h2>
                                    <div className="otnotice-section-content">
                                        <p id="isPasted">
                                            We collect personal information in a variety of ways. For
                                            example, you may provide us your personal information when you
                                            place an order, register for an account or loyalty membership,
                                            make an online purchase, post a review, send us messages,
                                            subscribe to our mailing lists, newsletters or other forms of
                                            marketing communications, submit a job application, participate
                                            in a survey, contest or sweepstakes, redeem a promotion, or use
                                            some other feature of our Service.
                                        </p>
                                        <p>
                                            We may link or combine your activities and information collected
                                            from you on our websites and mobile apps with information we
                                            have collected from you offline (e.g., in-store), information we
                                            receive from third parties, as well as information we collect
                                            automatically through tracking technologies (defined below). In
                                            some countries, we may also utilize software code to log your
                                            interactions with the Sites in a manner that allows us to
                                            reproduce your click mouse movements, scrolls, and
                                            keystrokes/key touches during sessions. This information allows
                                            us to provide you with a personalized experience regardless of
                                            how you interact with us, and helps us diagnose any usability
                                            problems to improve our Service.
                                        </p>
                                        <p>The categories of information we collect may include:&nbsp;</p>
                                        <p>
                                            <strong>Information you provide:</strong>
                                        </p>
                                        <ul>
                                            <li>
                                                <strong>Contact and profile information</strong>. We may
                                                collect personal information, such as your name, phone number,
                                                address, business information, gender, age, postal/zip code,
                                                and e-mail address, when you register for our Service, place
                                                an order for delivery, sign up for our mailing list,
                                                participate in an offer or loyalty program, redeem or
                                                participate in a promotion, enter a contest or sweepstakes,
                                                purchase or redeem a gift card, or otherwise communicate or
                                                interact with us. We use this information to create, verify
                                                and personalize your account, to fulfill your request or
                                                transaction, to communicate with you directly, and to send you
                                                marketing communications in accordance with your preferences.
                                            </li>
                                            <li>
                                                <strong>Payment and transaction information</strong>. We may
                                                collect information such as items purchased, date and time of
                                                your transaction, amount purchased, whether you used a
                                                particular coupon or deal, and payment information, such as
                                                your credit/debit card or gift card or loyalty program
                                                details, when you make an in-store or online purchase. We use
                                                this information to fulfill your order and complete your
                                                transaction, update your rewards, detect and prevent fraud,
                                                and to inform our advertising and marketing.
                                            </li>
                                            <li>
                                                <strong>Comments, chat and opinions</strong>. When you contact
                                                us directly (e.g., by email, phone, mail or by completing an
                                                online form or participating in online chat), we may
                                                transcribe your comments and opinions. We may also store
                                                comments and opinions you express when responding to surveys,
                                                entering a contest or sweepstakes or taking part in a
                                                promotion. We use this information to respond to your question
                                                or comment, to evaluate and improve our products and services
                                                and to inform our marketing and advertising activities. When
                                                you contact us directly to submit a concern, complaint or
                                                question, we may transcribe &nbsp;your comments and collect
                                                the personal information contained in your communications
                                                (e.g., contact information, health or medical information,
                                                photos, etc.). We use this information to respond to your
                                                questions or concerns, and to evaluate and improve our
                                                products and services.
                                            </li>
                                        </ul>
                                        <p>
                                            <strong>
                                                Information we collect when you use our Sites and our Service:
                                            </strong>
                                        </p>
                                        <ul>
                                            <li>
                                                <strong>Location i</strong>
                                                <strong>nformation.</strong> We may collect information about
                                                your location if you provide your address or postal/zip code
                                                or we may approximate your location based on your IP address.
                                                If you provide our mobile applications access to location
                                                services on your device, we and our&nbsp;third
                                                party&nbsp;providers may collect location data through GPS,
                                                Wi-Fi, wireless network triangulation or other similar
                                                methods. We may use your location information to provide
                                                personalized content and advertising, to enhance your shopping
                                                and dining experience, to allow you to view deals and products
                                                available to you based on your location, to assist us with
                                                analytics such as foot traffic measurement, and to improve the
                                                effectiveness of our websites, restaurants, mobile
                                                applications, merchandise, advertising, and customer service.
                                                We may also use your location information to provide you with
                                                more information regarding events, personalized offers
                                                regarding products, services, or other opportunities, and
                                                notifications via social media which may interest you. We may
                                                also use the Google Maps Application Programming Interface to
                                                gather information about your location. Google uses various
                                                technologies to determine your location, including your IP
                                                address, GPS, and other sensors that may, for example, provide
                                                Google with information on nearby devices, Wi-Fi access
                                                points, and cell towers (see Google Maps&nbsp;
                                                <a href="https://policies.google.com/privacy" rel="nofollow">
                                                    Privacy Policy
                                                </a>{" "}
                                                to learn more). &nbsp;&nbsp;
                                            </li>
                                        </ul>
                                        <p>&nbsp;</p>
                                        <ul>
                                            <li>
                                                <strong>
                                                    Information you may post or submit to our Sites
                                                </strong>
                                                . &nbsp;We may collect information about how you use and
                                                interact with our Sites and our Service, including any content
                                                you post to the Service. If you share photos and video from a
                                                mobile device, we will access your device camera roll and
                                                camera with your permission as needed to post your content.
                                                &nbsp; If you need to update your permissions, you can do so
                                                in the “Settings” app of your device. &nbsp;
                                            </li>
                                            <li>
                                                <strong>Address Book information.</strong>&nbsp; With your
                                                permission, we may access your contact list available on your
                                                mobile device or your email accounts, or you may choose to
                                                provide your friends’ contact information manually, so that
                                                you can send invitations or special content to your friends.
                                                When communicating with your friends, we may include your name
                                                and photo in order to let them know that you are the person
                                                extending the invitation or sending the message. &nbsp;With
                                                your permission, we may also send reminder emails to your
                                                friends on your behalf, which may also include your name and
                                                photo, and enable you to send invitations to your friends via
                                                email or SMS/text. We may retain your friends’ email addresses
                                                to let you know when the recipient received a gift card or
                                                other communication from us.
                                            </li>
                                        </ul>
                                        <p>
                                            <strong>&nbsp;In-store data we collect:</strong>
                                        </p>
                                        <ul>
                                            <li>
                                                <strong>In-store location information.</strong> In some
                                                locations, we may work with third party partners that collect
                                                location and movement data from your mobile device when you’re
                                                using a mobile app configured for this purpose. We receive
                                                aggregated information about visitors to our stores and may
                                                use this data to analyze foot traffic patterns and measure the
                                                effectiveness of our marketing and promotional campaigns. See
                                                “Your Choices and Control Over Your Information” below to
                                                learn how you may adjust location data collection through your
                                                mobile device.
                                            </li>
                                            <li>
                                                <strong>In-store Wi-Fi.&nbsp;</strong>In some locations, we
                                                may offer free Wi-Fi services to our guests. Our Wi-Fi network
                                                providers may capture certain data from devices you connect to
                                                it, such as a unique device identifier. We may also enable
                                                Bluetooth or other technologies in our stores which enable us
                                                to detect the presence of your device in our stores or provide
                                                operational insights.&nbsp;
                                            </li>
                                            <li>
                                                <strong>Transaction information</strong>. We may collect
                                                information about your in-store transaction such as items
                                                purchased, date and time of your transaction, amount
                                                purchased, and payment information, such as your credit/debit
                                                card or gift card details, when you make an in-store purchase.
                                            </li>
                                            <li>
                                                <strong>In-store video or images.</strong> In some locations,
                                                we use CCTV cameras in our stores for security, fraud,
                                                incident reporting and loss-prevention purposes, or to collect
                                                analytics, improve operations and other purposes.
                                            </li>
                                        </ul>
                                        <p>
                                            <strong>Information provided by others:</strong>
                                        </p>
                                        <ul>
                                            <li>
                                                <strong>Information provided by social networks.</strong> When
                                                you interact with our Service through various social media
                                                networks, such as when you “Like” us on Facebook or when you
                                                follow us or share our content on Facebook, Twitter, Snapchat,
                                                LinkedIn, Instagram or other social networks, we may receive
                                                some information about you that you permit the social network
                                                to share with third parties. We use this information to
                                                complete your profile, to better understand the demographics
                                                of our customers and to inform our advertising and marketing
                                                activities. The data we receive is dependent upon your privacy
                                                settings with the social network. You should always review
                                                and, if necessary, adjust your privacy settings on third party
                                                websites and social media networks and services before linking
                                                or connecting them to our Sites or Service.
                                            </li>
                                            <li>
                                                <strong>
                                                    Information we receive from authentication services you
                                                    connect to our Service
                                                </strong>
                                                . Some parts of our Service may allow you to login through a
                                                third party social network or authentication service such as
                                                Facebook. These services will authenticate your identity and
                                                provide you the option to share certain personal information
                                                with us, which could include your name, email address, address
                                                book and contacts, or other information. The data we receive
                                                is dependent on that third party’s policies and your privacy
                                                settings on that third-party site. If you choose to connect a
                                                Google or Gmail account to our Sites, we will ask you to grant
                                                us application permissions to access your Gmail account. These
                                                permissions are necessary to sustain the functionality of our
                                                Sites. We will store your authentication token and account
                                                email address. This data will be securely stored to be used by
                                                us to provide you with the Service (including but not limited
                                                to logging into your account and sending emails). This data
                                                will not be voluntarily shared with any third parties, but we
                                                may provide this information to legal authorities upon their
                                                lawful request. Should you choose to disconnect your Gmail
                                                account or decide to close your account, any information from
                                                Google that is stored on our servers will be removed
                                                permanently. We do not use data obtained from our customers
                                                (from their Google accounts) for advertising purposes. We may
                                                need access to the user data to resolve a support issue,
                                                provide advice on service usage or provide any other help
                                                requested by the user, or as such access may be necessary for
                                                a security investigation or to comply with applicable laws.
                                            </li>
                                        </ul>
                                        <p>
                                            We may offer Face ID, Touch ID or fingerprint authentication as
                                            a log-in method if you access the Service through an Apple or
                                            Android device equipped with authentication technology. We are
                                            only notified as to whether the authentication was successful
                                            and cannot access the data associated with the enrolled
                                            fingerprint. For more details, please refer to the fingerprint
                                            security guides offered by your device provider.
                                        </p>
                                        <ul>
                                            <li>
                                                <strong>Information provided by others</strong>. From time to
                                                time, we may receive information about you from third parties
                                                and other users. For example, we may obtain information from
                                                our marketing partners or from third parties to enhance or
                                                supplement our existing user information. We may also collect
                                                information about you that is publicly available. We may
                                                combine this information with the information we collect from
                                                you directly.
                                            </li>
                                            <li>
                                                <strong>
                                                    Information provided by Yum! Brands entities and Franchisees
                                                </strong>
                                                . We may receive information about you from other Yum! Brands
                                                companies, affiliates and our franchisees so that information
                                                you provide to one Brand may be used by another Brand to
                                                provide you services, communicate with you or provide you
                                                advertisements or other personalized content, in accordance
                                                with your permissions and applicable laws.
                                            </li>
                                        </ul>
                                        <p>
                                            <strong>
                                                Information collected from applicants for employment:
                                            </strong>
                                        </p>
                                        <p>
                                            If you apply for a job through our Sites, we collect the
                                            information you include in your application, which could include
                                            your name, address, phone number, email address as well as
                                            information regarding your work/education background and history
                                            and other such information. We may also obtain information about
                                            you from your references. We may also receive this information
                                            if you submit an application through a third party site or
                                            service, or if we receive your information through a recruiter
                                            or employment agency.&nbsp;
                                        </p>
                                        <p>
                                            We collect and use the personal information of applicants for
                                            employment to evaluate the application and the suitability of
                                            employment. We may retain an applicant’s personal information
                                            for a reasonable time period after the date of application for
                                            the purpose of assessing an individual's suitability for other
                                            employment opportunities within our organization, though we have
                                            no obligation to do so and the applicant may wish to submit
                                            another application to be considered for another job opening of
                                            interest.&nbsp;
                                        </p>
                                        <p>
                                            Please note that if you apply for a restaurant position, you may
                                            be submitting your application information to a franchisee
                                            organization. Our franchisees may have their own data collection
                                            and use practices that are not governed by this Policy.
                                        </p>
                                    </div>
                                </section>
                                <section
                                    className="otnotice-section"
                                    id="otnotice-section-c997750b-2fce-4704-ad8f-d25f90c1137a"
                                >
                                    <h2 className="otnotice-section-header">
                                        2. HOW DO WE USE PERSONAL INFORMATION?{" "}
                                    </h2>
                                    <div className="otnotice-section-content">
                                        <ul id="isPasted">
                                            <li>
                                                <strong>
                                                    To fulfill your requests and provide our Services to
                                                    you.&nbsp;
                                                </strong>
                                                For example, we or our franchisees may use your information to
                                                complete your order or other online and in-store transactions,
                                                deliver orders you place online, or respond to your customer
                                                service requests or feedback. We may also use your information
                                                to personalize your experience with us and facilitate our
                                                rewards or loyalty programs. &nbsp;We also use this
                                                information to provide the Service to you across our
                                                operations, including by supporting your in-store experience
                                                when you engage with our franchisee-owned locations.
                                            </li>
                                            <li>
                                                <strong>For marketing purposes. &nbsp;</strong>We may send you
                                                communications about new features, updates, products and
                                                special offers. We may also use your information to serve you
                                                ads about products and special offers about our products or
                                                other products or services we think you might find
                                                interesting. We may also use individual and aggregate
                                                information about you to inform our marketing and advertising
                                                campaigns. For more information on your choices about
                                                marketing communications, see “Your Choices and Control Over
                                                Your Information” below and our Cookie and Ads Policy,
                                                available on our Site.
                                            </li>
                                            <li>
                                                <strong>To communicate with you. &nbsp;</strong>For example,
                                                we may communicate with you about your account activities,
                                                such as by providing you transaction confirmations, alerting
                                                you when a gift card you sent has been received by the
                                                recipient, or updating you about employment opportunities for
                                                which you've applied. If you register with us, we may enroll
                                                you in our email newsletter or other periodic electronic
                                                communications and may also send you user surveys and
                                                promotional communications. We may communicate with you by
                                                email, postal mail, telephone, text message, or other means.
                                                We may use push notifications on our mobile apps to your
                                                mobile device. For more information on how to adjust your
                                                communications preferences, see “Your Choices and Control Over
                                                Your Information” below.
                                            </li>
                                        </ul>
                                        <ul>
                                            <li>
                                                <strong>
                                                    To monitor, improve and develop our products and services.
                                                    &nbsp;
                                                </strong>
                                                We may use your information to understand our visitors and
                                                customers and tailor our product offerings accordingly.
                                                &nbsp;From time to time, we analyze statistics and trends to
                                                make our Sites and Services better and to develop new services
                                                or features.
                                            </li>
                                            <li>
                                                <strong>
                                                    To protect the security and integrity of our business,
                                                    comply with legal requirements and obligations, or as
                                                    otherwise permitted by law. &nbsp;&nbsp;
                                                </strong>
                                                We may use information to protect our company, our affiliates,
                                                our customers, our franchisees and our websites. We may also
                                                use information in order to comply with laws, regulations,
                                                court orders, or other legal obligations or to assist in an
                                                investigation, to protect and defend our rights and property,
                                                or the rights or safety of third parties, to enforce our Terms
                                                of Use, this Policy, or agreements with third parties, to
                                                detect and prevent fraud or for crime-prevention purposes, or
                                                for any other reason permitted by law.
                                            </li>
                                        </ul>
                                    </div>
                                </section>
                                <section
                                    className="otnotice-section"
                                    id="otnotice-section-f5fded6b-5cd0-47fd-b737-611ec3ca2173"
                                >
                                    <h2 className="otnotice-section-header">
                                        3. WHAT INFORMATION MAY BE COLLECTED AUTOMATICALLY?{" "}
                                    </h2>
                                    <div className="otnotice-section-content">
                                        <p id="isPasted">
                                            When you visit our Sites and Service, read our emails, or
                                            otherwise engage with us through a computer or mobile device, we
                                            and our third party partners may automatically collect
                                            information about how you access and use the Service and
                                            information about the device you use to access the Service.
                                        </p>
                                        <p>
                                            This information allows us to improve your customer experience.
                                            For example, we may use this information to enhance and
                                            personalize your user experience, to monitor and improve our
                                            Sites and Services, and for other internal purposes. We
                                            typically collect this information through a variety of tracking
                                            technologies, including cookies, software code, Flash objects,
                                            web beacons, embedded scripts, location-identifying
                                            technologies, and similar technology (collectively, “
                                            <strong>tracking technologies</strong>”). We may also collect
                                            this information if you connect to our in-store Wi-Fi, or if
                                            your mobile device is configured to search for available Wi-Fi
                                            networks, we may receive information about your device even if
                                            you don’t connect to our Wi-Fi services. Information we collect
                                            automatically about you may be combined with other personal
                                            information we collect directly.
                                        </p>
                                        <p>
                                            Specifically, we and our third party partners may use tracking
                                            technologies to automatically collect usage and device
                                            information, such as:
                                        </p>
                                        <ul>
                                            <li>
                                                <strong>Information about how you access the Service,</strong>{" "}
                                                such as the site from which you came and the site to which you
                                                are going when you leave our Sites, how frequently you access
                                                the Service, when and whether you open emails or click the
                                                links contained in emails, whether you access the Service from
                                                multiple devices, and other actions you take on the Service.
                                            </li>
                                            <li>
                                                <strong>Information about how you use the Service,</strong>{" "}
                                                such as the pages you visit, the links you click, the ads you
                                                view and click on, purchase information and your checkout
                                                process, your location when you access or interact with our
                                                Service, and other similar actions.
                                            </li>
                                            <li>
                                                <strong>
                                                    Information about the computer, tablet, smartphone or other
                                                    device you use,
                                                </strong>{" "}
                                                such as your IP address, browser type, Internet service
                                                provider, platform type, device type/model/manufacturer,
                                                operating system, date and time stamp, a unique ID that allows
                                                us to uniquely identify your browser, mobile device or your
                                                account (including, e.g., a persistent device identifier or an
                                                Ad ID), and other such information.
                                            </li>
                                            <li>
                                                <strong>Analytics information.</strong> We may collect
                                                analytics data, or use third party analytics tools, to help us
                                                measure traffic and usage trends for the Service and to
                                                understand more about the demographics and behaviors of our
                                                users.
                                            </li>
                                        </ul>
                                        <p>
                                            We, or our third party partners, may use the data collected
                                            through tracking technologies to: (a) remember information so
                                            that you will not have to re-enter it during your visit or the
                                            next time you visit the Sites; (b) provide custom, personalized
                                            content and information, including targeted content and
                                            advertising; (c) identify and contact you across multiple
                                            devices; (d) provide and monitor the effectiveness of our
                                            Service; (e) perform analytics and detect usage patterns on our
                                            Service; (f) diagnose or fix technology problems; (g) detect or
                                            prevent fraud or other harmful activities, and (h) otherwise to
                                            plan for and enhance our Service.
                                        </p>
                                        <p>
                                            <strong>Your choices to control tracking technologies.</strong>{" "}
                                            If you would prefer not to accept cookies, most browsers will
                                            allow you to: (i) change your browser settings to notify you
                                            when you receive a cookie, which lets you choose whether or not
                                            to accept it; (ii) disable existing cookies; or (iii) set your
                                            browser to automatically reject cookies. For more information on
                                            how to block, delete and/or disable tracking technologies, see
                                            “Choice and Control Over Your Information” below.&nbsp;
                                        </p>
                                        <p>
                                            <strong>Do Not Track</strong>. &nbsp;Although we do our best to
                                            honor the privacy preferences of our visitors, we are not able
                                            to respond to “Do Not Track” signals from your browser at this
                                            time.
                                        </p>
                                        <p>
                                            We and our third party partners may collect information through
                                            tracking technologies for personalized advertising purposes. See
                                            our Cookie and Ads Policy available on our Site to learn
                                            more.&nbsp;
                                        </p>
                                    </div>
                                </section>
                                <section
                                    className="otnotice-section"
                                    id="otnotice-section-6befa163-b56f-4bea-9ae0-d8c0191b93c4"
                                >
                                    <h2 className="otnotice-section-header">
                                        4. HOW WE SHARE YOUR INFORMATION
                                    </h2>
                                    <div className="otnotice-section-content">
                                        <p id="isPasted">
                                            We may share, sell or disclose your information in the instances
                                            described below. &nbsp;For further information on your choices
                                            regarding your information, see “Your Choices and Control Over
                                            Your Information” below.
                                        </p>
                                        <ul>
                                            <li>
                                                <strong>Other Brands:</strong>&nbsp; We may share personal
                                                information with our parent company Yum! Brands and other Yum!
                                                Brands companies and our affiliates, who may similarly use
                                                your information in the manner described in this&nbsp;Policy.
                                            </li>
                                            <li>
                                                <strong>Our franchisees:</strong>&nbsp; Many of our
                                                restaurants are independently owned and operated by
                                                franchisees. &nbsp;We may share information with our
                                                franchisees, particularly when we collaborate in providing the
                                                Service to you. For example, we may need to share certain
                                                personal information with a franchisee in order to provide
                                                delivery and carry-out services that you may request through
                                                the Service. We also may share information with our
                                                franchisees in order to provide local offers and promotions
                                                that might interest you.&nbsp;These franchisees may also be
                                                authorized to use your personal information in the manner
                                                described in this Policy. &nbsp;<em>Please note:&nbsp;</em>our
                                                franchisees independently may have their own data collection
                                                and use practices that are not governed by this Policy.
                                            </li>
                                            <li>
                                                <strong>Third parties at your request:</strong>&nbsp; You may
                                                choose to share your activities on the Service with your
                                                friends through email, text or on various social media
                                                networks.
                                            </li>
                                            <li>
                                                <strong>Promotional partners:</strong> We may share limited
                                                information with third parties with whom we partner to provide
                                                contests and sweepstakes, or other joint promotional
                                                activities. These partners will usually be clearly identified
                                                in the contest rules or promotional materials.
                                            </li>
                                            <li>
                                                <strong>
                                                    Select marketing and strategic business partners:
                                                </strong>
                                                &nbsp; We may share limited data with our preferred marketing
                                                and strategic business partners so that they can provide you
                                                with information and marketing messages about products or
                                                services that may interest you. These parties may use your
                                                information in accordance with their own privacy policies.
                                            </li>
                                            <li>
                                                <strong>Online advertising partners:&nbsp;</strong>We may
                                                share information with third party online advertising partners
                                                or permit these partners to collect information from you
                                                directly on our Sites to facilitate online advertising. To
                                                learn more, see our Cookie and Ads Policy, available on our
                                                Site.
                                            </li>
                                            <li>
                                                <strong>The public</strong>: &nbsp;When you provide feedback
                                                or post user content on our Service (e.g., if you post a
                                                product review on our Sites or comment on our social media
                                                sites), your information (e.g., your first name, last initial,
                                                state of residence, and your comments) may be displayed on our
                                                Sites or on our social media pages. When you engage with us on
                                                social media, we may tag your social media account or the
                                                social media account of others (e.g., to give photo credit to
                                                another user).
                                            </li>
                                            <li>
                                                <strong>With your consent or at your direction</strong>:
                                                &nbsp; In addition to the sharing described in this Policy, we
                                                may share information about you with third parties whenever
                                                you consent to or direct such sharing.
                                            </li>
                                        </ul>
                                        <p>
                                            <strong>
                                                Other instances in which we may share your personal
                                                information:&nbsp;
                                            </strong>
                                        </p>
                                        <ul>
                                            <li>
                                                <strong>Service providers and advisors</strong>:
                                                &nbsp;Personal information may be shared with third party
                                                vendors and other service providers who perform services for
                                                us or on our behalf. This may include vendors and providers
                                                who engage in marketing or advertising activities or provide
                                                mailing or email services, tax and accounting services,
                                                product fulfillment, delivery services, payments processing,
                                                data enhancement services, fraud prevention, web hosting, or
                                                analytic services.
                                            </li>
                                            <li>
                                                <strong>
                                                    Purchasers and third parties in connection with a business
                                                    transaction
                                                </strong>
                                                : &nbsp;Personal information may be disclosed to third parties
                                                in connection with a corporate transaction, such as a merger,
                                                sale of any or all of our company assets or shares,
                                                reorganization, financing, change of control or acquisition of
                                                all or a portion of our business by an affiliate or third
                                                party, or in the event of a bankruptcy or related or similar
                                                proceedings.
                                            </li>
                                            <li>
                                                <strong>
                                                    Law enforcement, regulators and other parties for legal
                                                    reasons
                                                </strong>
                                                : &nbsp;Personal information may be disclosed to third
                                                parties, as required by law or subpoena, or if we reasonably
                                                believe that such action is necessary to (a) comply with the
                                                law and the reasonable requests of law enforcement; (b) to
                                                enforce our Terms of Use &nbsp;or to protect the security or
                                                integrity of our Service; and/or (c) to exercise or protect
                                                the rights, property, or personal safety of our organization,
                                                our visitors, or others.
                                            </li>
                                        </ul>
                                        <p>
                                            In connection with any of the above, we may share information
                                            with others in an aggregated or otherwise anonymized form that
                                            does not reasonably identify you.
                                        </p>
                                    </div>
                                </section>
                                <section
                                    className="otnotice-section"
                                    id="otnotice-section-70213f56-22bb-47cf-bdff-9bd91d4b03d3"
                                >
                                    <h2 className="otnotice-section-header">
                                        5. YOUR CHOICES AND CONTROL OVER YOUR INFORMATION
                                    </h2>
                                    <div className="otnotice-section-content">
                                        <p id="isPasted">
                                            <strong>Profile and settings: &nbsp;</strong>You may update your
                                            account information and adjust your account settings by logging
                                            into your account. Please note that changes to your settings may
                                            require some time to take effect.
                                        </p>
                                        <p>
                                            <strong>How to control your email preferences:</strong> You can
                                            stop receiving promotional email communications from us by
                                            clicking on the “unsubscribe link” provided in such
                                            communications. We make every effort to promptly process all
                                            unsubscribe requests. You may not opt out of service-related
                                            communications (e.g., account verification, transactional
                                            communications, changes/updates to features of the Service,
                                            technical and security notices). You may also be able to adjust
                                            certain communications preferences by logging into your account.
                                        </p>
                                        <p>
                                            <strong>Geolocation and device Information: &nbsp;</strong>You
                                            may control the Service’s access to your device information
                                            through your “Settings” app on your device. &nbsp;For instance,
                                            you can withdraw permission for the Service to access your
                                            address book, location, photo stream, and camera. &nbsp; You may
                                            also control precise location tracking by adjusting your
                                            location services settings on your mobile device. We may
                                            continue to approximate your location based on your IP address
                                            or through other means when you access the Service through a
                                            computer or device.
                                        </p>
                                        <p>
                                            <strong>Online advertising and tracking.</strong>&nbsp; Please
                                            refer to our Cookie and Ads Policy, available on our Site, to
                                            learn more about how we work with third party partners to
                                            collect data through tracking technologies for various purposes,
                                            including analytics and advertising, and to understand your
                                            choices.
                                        </p>
                                    </div>
                                </section>
                                <section
                                    className="otnotice-section"
                                    id="otnotice-section-4cdd74cf-eac0-4538-a803-b5a02b586837"
                                >
                                    <h2 className="otnotice-section-header">
                                        6. HOW WE STORE AND PROTECT YOUR INFORMATION
                                    </h2>
                                    <div className="otnotice-section-content">
                                        <p id="isPasted">
                                            <strong>Data storage and transfer</strong>: &nbsp; Your
                                            information collected through our Service may be stored and
                                            processed in the United States or any other country in which
                                            Yum! Brands, its affiliates or service providers maintain
                                            facilities. &nbsp;If you are located in regions with laws
                                            governing data collection and use that may differ from U.S. law,
                                            please note that we may transfer information, including personal
                                            information, to a country and jurisdiction that have data
                                            protection laws that may be more or less restrictive than those
                                            required under U.S. law. &nbsp;&nbsp;
                                        </p>
                                        <p>
                                            <strong>Data retention</strong>: &nbsp;We will retain your
                                            personal information as long as reasonably necessary to maintain
                                            the Service, to meet legal and accounting obligations, and for
                                            the other purposes described in this Policy, or as otherwise
                                            required or permitted by law. We may anonymize and/or aggregate
                                            personal information and store it in order to analyze aggregate
                                            metrics and trends.
                                        </p>
                                        <p>
                                            <strong>Keeping your information safe:</strong>&nbsp;
                                            &nbsp;Security of your information is very important to us, and
                                            we have put in place safeguards to preserve the integrity and
                                            security of information we collect and share with our service
                                            providers. However, no security system is impenetrable and we
                                            cannot guarantee the security of our systems at all times. In
                                            the event that any information under our control is compromised
                                            as a result of a breach of data security, we will take
                                            reasonable steps to investigate the situation and, where
                                            appropriate, notify those individuals whose information may have
                                            been compromised and take additional steps, in accordance with
                                            any applicable laws and regulations.
                                        </p>
                                    </div>
                                </section>
                                <section
                                    className="otnotice-section"
                                    id="otnotice-section-655e64db-4032-4790-a3c1-9dab6787d82b"
                                >
                                    <h2 className="otnotice-section-header">
                                        7. JURISDICTIONAL DISCLOSURES
                                    </h2>
                                    <div className="otnotice-section-content">
                                        <style
                                            type="text/css"
                                            id="isPasted"
                                            dangerouslySetInnerHTML={{
                                                __html:
                                                    "\np.p1 {margin: 0.0px 0.0px 8.0px 0.0px; font: 13.3px Times; color: #000000} p.p2 {margin: 0.0px 0.0px 8.0px 48.0px; text-indent: -24.0px; font: 13.3px Helvetica; color: #000000} p.p3 {margin: 0.0px 0.0px 8.0px 0.0px; font: 13.3px Helvetica; color: #000000} span.s1 {font-kerning: none} span.s2 {font: 9.3px 'Times New Roman'; font-kerning: none} span.s3 {font-kerning: none; background-color: #ffff0b} span.s4 {font: 13.3px Times; font-kerning: none}\n"
                                            }}
                                        />
                                        <style
                                            type="text/css"
                                            id="isPasted"
                                            dangerouslySetInnerHTML={{
                                                __html:
                                                    "\np.p1 {margin: 0.0px 0.0px 8.0px 0.0px; font: 13.3px Times; color: #000000} p.p2 {margin: 0.0px 0.0px 8.0px 48.0px; text-indent: -24.0px; font: 13.3px Helvetica; color: #000000} p.p3 {margin: 0.0px 0.0px 8.0px 0.0px; font: 13.3px Helvetica; color: #000000} span.s1 {font-kerning: none} span.s2 {font: 9.3px 'Times New Roman'; font-kerning: none} span.s3 {font-kerning: none; background-color: #ffff0b} span.s4 {font: 13.3px Times; font-kerning: none}\n"
                                            }}
                                        />
                                        <p>
                                            We may choose or be required by law to provide different or
                                            additional disclosures about our data privacy practices
                                            depending on your state or country of residence. &nbsp;Please
                                            review this section to understand how our practices or policies
                                            may differ in your jurisdiction:
                                        </p>
                                    </div>
                                </section>
                                <section
                                    className="otnotice-section"
                                    id="otnotice-section-83199b1f-6a9b-4130-85b7-a36f4b622336"
                                >
                                    <h2 className="otnotice-section-header">8. CHILDREN’S PRIVACY</h2>
                                    <div className="otnotice-section-content">
                                        <p>
                                            We do not knowingly collect or solicit any information from
                                            children under the age of 13 on the Service. &nbsp;In the event
                                            we learn that we have inadvertently collected personal
                                            information from a child under age 13, we will delete that
                                            information as quickly as possible. &nbsp;If you believe that we
                                            may have collected information from a child under 13, please
                                            contact us using the contact information below.&nbsp;
                                        </p>
                                        <p>
                                            <br />
                                        </p>
                                    </div>
                                </section>
                                <section
                                    className="otnotice-section"
                                    id="otnotice-section-ea5c03f3-a9b3-4209-9c35-012d281ea62f"
                                >
                                    <h2 className="otnotice-section-header">
                                        9. LINKS TO OTHER WEB SITES AND SERVICES
                                    </h2>
                                    <div className="otnotice-section-content">
                                        <p>
                                            The Service may contain links to and from third party websites
                                            of our business partners, advertisers, and social media sites
                                            and our users may post links to third party websites. &nbsp;If
                                            you follow a link to any of these websites, please note that
                                            these websites have their own privacy policies and that we do
                                            not accept any responsibility or liability resulting from you
                                            following a link these websites. &nbsp; Additionally, other
                                            privacy policies may apply when you engage with us through a
                                            co-branded or co-sponsored promotional or marketing activity. We
                                            strongly recommend that you read the privacy policies and terms
                                            and conditions of use of any third party website or service to
                                            understand how your information will be collected, used and
                                            shared. We are not responsible for the privacy practices or the
                                            content on the websites of third-party sites
                                        </p>
                                        <p>
                                            <br />
                                        </p>
                                    </div>
                                </section>
                                <section
                                    className="otnotice-section"
                                    id="otnotice-section-14c547d4-cdf2-4073-9720-8b6c0f914004"
                                >
                                    <h2 className="otnotice-section-header">
                                        11. CHANGES TO OUR PRIVACY POLICY
                                    </h2>
                                    <div className="otnotice-section-content">
                                        <p>
                                            We may modify or update this Policy from time to time to reflect
                                            the changes in our business and practices, so you should review
                                            this Policy periodically. &nbsp;When we change this Policy in a
                                            material manner, we will so indicate by updating the “last
                                            modified” date in the heading of this Policy. &nbsp;If you
                                            object to any changes in this Policy, you may close your account
                                            associated with the Service.&nbsp;
                                        </p>
                                        <p>
                                            <br />
                                        </p>
                                    </div>
                                </section>
                            </div>
                            <div className="otnotice-version" />
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default Privacy;