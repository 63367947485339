import React, { useEffect, useState } from 'react';
import { Table, Button, notification, Tag, Progress } from 'antd';
import { Link } from 'react-router-dom';
import ChecklistService from '../../../../http/ChecklistService';
import SkeletonWrapper from '../../../../components/skeletons/SkeletonWrapper';
import GenericSkeleton from '../../../../components/skeletons/GenericSkeleton';
import DefaultContainer from '../../../../components/DefaultContainer';

const TeamLeadChecklists = () => {
    const [loading, setLoading] = useState(true);
    const [checklists, setChecklists] = useState([]);

    useEffect(() => {
        fetchChecklists();
    }, []);

    const fetchChecklists = () => {
        setLoading(true);
        ChecklistService.indexChecklists(
            (response) => {
                setLoading(false);
                setChecklists(response.data.data);
                // This is the transformed array from the controller
                // with progress, status, etc.
            },
            (error) => {
                setLoading(false);
                notification.error({
                    message: 'Error',
                    description: 'Failed to load checklists'
                });
            }
        );
    };

    // Decide color for Tag based on status
    const statusColorMap = {
        'Not Started': 'red',
        'In Progress': 'gold',
        'Finished': 'green',
        'No Items': 'blue'
    };

    const columns = [
        {
            title: 'Checklist Name',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Cycle Time',
            dataIndex: 'cycle_time',
            key: 'cycle_time',
            render: (text) => text || 'N/A'
        },
        {
            title: 'Progress',
            key: 'progress',
            render: (text, record) => (
                <div style={{ minWidth: 100 }}>
                    <Progress
                        percent={record.progress}
                        size="small"
                        strokeWidth={10}
                    />
                </div>
            )
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (status) => {
                const color = statusColorMap[status] || 'default';
                return <Tag color={color}>{status}</Tag>;
            }
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (text, record) => (
                <Link to={`/dashboard/operations/ck/checklists/checklists/${record.id}/log`}>
                    <Button type="primary">Open</Button>
                </Link>
            )
        }
    ];

    return (
        <DefaultContainer>
            <h2 className="text-xl font-bold uppercase mb-4">Team Lead Checklists</h2>

            <SkeletonWrapper loading={loading} skeleton={GenericSkeleton}>
                <Table
                    dataSource={checklists}
                    columns={columns}
                    rowKey="id"
                    pagination={{ pageSize: 10 }}
                />
            </SkeletonWrapper>
        </DefaultContainer>
    );
};

export default TeamLeadChecklists;
