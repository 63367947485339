import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
    Table,
    Button,
    notification,
    InputNumber,
    Modal,
    Form,
    Checkbox,
    Space,
    DatePicker
} from 'antd';
import OrderService from '../../../../../http/OrderService';
import SkeletonWrapper from '../../../../../components/skeletons/SkeletonWrapper';
import GenericSkeleton from '../../../../../components/skeletons/GenericSkeleton';
import DefaultContainer from '../../../../../components/DefaultContainer';
import { ReloadOutlined, EditOutlined } from '@ant-design/icons';
import usePermission from '../../../../../layouts/usePermission';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faClock,
    faEye,
    faCheckCircle,
    faTasks,
    faTruck,
    faClipboardCheck
} from "@fortawesome/free-solid-svg-icons";
import moment from 'moment';

// We'll use RangePicker
const { RangePicker } = DatePicker;

const BranchOrders = () => {
    const { branchId } = useParams();
    const [loading, setLoading] = useState(true);
    const [orders, setOrders] = useState([]);
    const [branch, setBranch] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [selectedField, setSelectedField] = useState('');
    const [refreshing, setRefreshing] = useState(false);
    const [form] = Form.useForm();
    const inventory_update_branch_order_quantity = usePermission(
        ["inventory_update_branch_order_quantity"],
        null
    );

    // All possible statuses
    const allStatuses = [
        'preorder',
        'pending',
        'seen',
        'preparing',
        'shipped',
        'received',
        'cancelled'
    ];

    // Default selected statuses
    const [selectedStatuses, setSelectedStatuses] = useState([
        'preorder',
        'pending',
        'seen',
        'preparing',
        'shipped'
    ]);

    // For table sorting
    const [sortInfo, setSortInfo] = useState({});

    // NEW: date range state
    const [dateRange, setDateRange] = useState([]);

    useEffect(() => {
        if (branchId) {
            // If we already have a date range in state, pass it. Otherwise pass null.
            fetchBranchOrders(branchId, dateRange);
        }
    }, [branchId]);

    // NEW: Modify to accept date range
    const fetchBranchOrders = (branchId, range = []) => {
        setRefreshing(true);
        if (!orders) setLoading(true);

        let params = {};
        if (range.length === 2) {
            // Format dates as YYYY-MM-DD or however the backend expects them
            params.from_date = moment(range[0]).format('YYYY-MM-DD');
            params.to_date   = moment(range[1]).format('YYYY-MM-DD');
        }

        OrderService.getBranchOrders(
            branchId,
            params, // pass date filters as query params
            (response) => {
                const data = response.data.data;
                setOrders(data.orders);
                setBranch(data.branch);
                setLoading(false);
                setRefreshing(false);
            },
            (error) => {
                notification.error({
                    message: 'Error',
                    description: 'Failed to load orders',
                });
                setLoading(false);
                setRefreshing(false);
            }
        );
    };

    const handleEditAmount = (order, field) => {
        setSelectedOrder(order);
        setSelectedField(field);
        form.setFieldsValue({ amount: order[field] || 0 });
        setIsModalVisible(true);
    };

    const handleModalOk = () => {
        form.validateFields().then((values) => {
            if (selectedField) {
                // Edit the order quantity
                const updatedData = {
                    [selectedField]: values.amount,
                };

                OrderService.updateOrder(
                    selectedOrder.id,
                    updatedData,
                    (response) => {
                        notification.success({
                            message: 'Success',
                            description: 'Order updated successfully',
                        });
                        // Refetch with current date range
                        fetchBranchOrders(branchId, dateRange);
                        setIsModalVisible(false);
                        form.resetFields();
                    },
                    (error) => {
                        notification.error({
                            message: 'Error',
                            description: 'Failed to update order',
                        });
                        setIsModalVisible(false);
                    }
                );
            } else {
                // Mark as received
                OrderService.updateOrderStatus(
                    selectedOrder.id,
                    { status: 'received', received_amount: values.amount },
                    (response) => {
                        notification.success({
                            message: 'Success',
                            description: 'Order status updated successfully',
                        });
                        fetchBranchOrders(branchId, dateRange);
                        setIsModalVisible(false);
                        form.resetFields();
                    },
                    (error) => {
                        notification.error({
                            message: 'Error',
                            description: 'Failed to update order status',
                        });
                        setIsModalVisible(false);
                    }
                );
            }
        });
    };

    const handleModalCancel = () => {
        setIsModalVisible(false);
        form.resetFields();
    };

    const handleStatusChange = (order) => {
        setSelectedOrder(order);
        setSelectedField('');
        form.setFieldsValue({ amount: order.sent_amount || order.ordered_amount });
        setIsModalVisible(true);
    };

    const statusColorMap = {
        pending: '#ececec',
        seen: '#e4f9ff',
        confirmed: '#90EE90',
        preparing: '#FFDAB9',
        shipped: '#fee0ff',
        received: 'rgba(174,255,171,0.28)',
        preorder: '#ffeb3b',
        cancelled: '#f7d7d7'
    };

    const statusIconMap = {
        pending: faClock,
        seen: faEye,
        confirmed: faCheckCircle,
        preparing: faTasks,
        shipped: faTruck,
        received: faClipboardCheck,
        preorder: faClock,
        cancelled: faCheckCircle
    };

    const columns = [
        {
            title: 'Inventory Group',
            dataIndex: ['branch_inventory_group', 'name'],
            key: 'group_name',
            sorter: (a, b) => {
                const nameA = a.branch_inventory_group.name.toLowerCase();
                const nameB = b.branch_inventory_group.name.toLowerCase();
                return nameA.localeCompare(nameB);
            },
            sortOrder: sortInfo.columnKey === 'group_name' && sortInfo.order,
            render: (text, record) => (
                <div className={`patty text-2xl ${record.status === 'cancelled' ? 'line-through' : ''}`}>
                    {text}
                </div>
            )
        },
        {
            title: 'Ordered Amount',
            dataIndex: 'ordered_amount',
            key: 'ordered_amount',
            render: (text, record) => (
                <div className={`font-bold ${record.status === 'cancelled' ? 'line-through' : ''}`}>
                    {text}
                    {record.branch_inventory_group.unit_type}
                    {inventory_update_branch_order_quantity && (
                        <Button
                            icon={<EditOutlined />}
                            size="small"
                            className="ml-2"
                            onClick={() => handleEditAmount(record, 'ordered_amount')}
                        />
                    )}
                </div>
            )
        },
        {
            title: 'Ordered At',
            dataIndex: 'ordered_at',
            key: 'ordered_at',
            sorter: (a, b) => {
                return new Date(a.ordered_at) - new Date(b.ordered_at);
            },
            sortOrder: sortInfo.columnKey === 'ordered_at' && sortInfo.order,
            render: (text, record) => (
                <div className={record.status === 'cancelled' ? 'line-through' : ''}>
                    {text}
                </div>
            )
        },
        {
            title: 'Vendor',
            dataIndex: ['inventory_vendor', 'name'],
            key: 'vendor_name',
            render: (text, record) => (
                <div className={record.status === 'cancelled' ? 'line-through' : ''}>
                    {text}
                </div>
            )
        },
        {
            title: 'Sent Amount',
            dataIndex: 'sent_amount',
            key: 'sent_amount',
            render: (text, record) =>
                text && (
                    <div className={`font-bold ${record.status === 'cancelled' ? 'line-through' : ''}`}>
                        {text}
                        {record.branch_inventory_group.unit_type}
                        {inventory_update_branch_order_quantity && (
                            <Button
                                icon={<EditOutlined />}
                                size="small"
                                className="ml-2"
                                onClick={() => handleEditAmount(record, 'sent_amount')}
                            />
                        )}
                    </div>
                )
        },
        {
            title: 'Received Amount',
            dataIndex: 'received_amount',
            key: 'received_amount',
            render: (text, record) =>
                text && (
                    <div className={`font-bold ${record.status === 'cancelled' ? 'line-through' : ''}`}>
                        {text}
                        {record.branch_inventory_group.unit_type}
                        {inventory_update_branch_order_quantity && (
                            <Button
                                icon={<EditOutlined />}
                                size="small"
                                className="ml-2"
                                onClick={() => handleEditAmount(record, 'received_amount')}
                            />
                        )}
                    </div>
                )
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => (
                <div className="flex flex-col items-start">
                    <div
                        style={{ backgroundColor: statusColorMap[text] }}
                        className={`w-[150px] uppercase text-sm font-bold py-1 text-left pl-3 flex flex-row items-center`}
                    >
                        <FontAwesomeIcon icon={statusIconMap[text]} className="mr-2" />
                        {text}
                    </div>
                </div>
            )
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (text, record) => (
                <div>
                    {record.status !== 'received' && record.status !== 'cancelled' && (
                        <Button size="small" onClick={() => handleStatusChange(record)}>
                            Mark as Received
                        </Button>
                    )}
                </div>
            )
        }
    ];

    // Filter the orders based on selected statuses
    const filteredOrders = orders.filter((order) =>
        selectedStatuses.includes(order.status)
    );

    const onTableChange = (pagination, filters, sorter) => {
        setSortInfo(sorter);
    };

    // NEW: Handler for date range changes
    const handleDateChange = (dates) => {
        setDateRange(dates || []);
    };

    // NEW: Trigger a fresh fetch when user hits "Filter"
    const handleFilter = () => {
        fetchBranchOrders(branchId, dateRange);
    };

    return (
        <DefaultContainer>
            <div className="patty text-2xl font-bold mb-4 flex justify-between items-center">
        <span>
          Branch Orders {branch && <span> - {branch.name}</span>}
        </span>
                <Button
                    icon={<ReloadOutlined />}
                    loading={refreshing}
                    onClick={() => fetchBranchOrders(branchId, dateRange)}
                >
                    Refresh
                </Button>
            </div>

            {/* NEW: Date Range Filter UI */}
            <div className="mb-4 flex flex-col space-y-2">
                <div className="flex flex-row items-center space-x-4">
                    <RangePicker
                        onChange={handleDateChange}
                        format="YYYY-MM-DD"
                        // Optional: if you want to show default values
                        // defaultValue={[moment().subtract(7, 'days'), moment()]}
                    />
                    <Button type="primary" onClick={handleFilter}>
                        Filter
                    </Button>
                </div>
            </div>

            {/* Status Filter */}
            <div className="mb-4 flex flex-col space-y-2">
                <div className="font-bold">Filter by Status:</div>
                <Checkbox.Group
                    value={selectedStatuses}
                    onChange={(checkedValues) => setSelectedStatuses(checkedValues)}
                >
                    <Space wrap>
                        {allStatuses.map((st) => (
                            <Checkbox
                                key={st}
                                value={st}
                                style={{
                                    border: '1px solid #ccc',
                                    padding: '5px 10px',
                                    borderRadius: '5px'
                                }}
                            >
                                {st.charAt(0).toUpperCase() + st.slice(1)}
                            </Checkbox>
                        ))}
                    </Space>
                </Checkbox.Group>
            </div>

            <SkeletonWrapper loading={loading} skeleton={GenericSkeleton}>
                <Table
                    columns={columns}
                    dataSource={filteredOrders}
                    pagination={false}
                    rowKey="id"
                    onChange={onTableChange}
                    sortDirections={['ascend', 'descend']}
                />
            </SkeletonWrapper>

            <Modal
                title={
                    selectedField
                        ? `Edit ${selectedField.replace('_', ' ').toUpperCase()}`
                        : 'Receive Order'
                }
                visible={isModalVisible}
                onOk={handleModalOk}
                onCancel={handleModalCancel}
            >
                <Form form={form}>
                    <Form.Item
                        name="amount"
                        label={
                            selectedField
                                ? `New ${selectedField.replace('_', ' ').toUpperCase()}`
                                : 'Received Amount'
                        }
                        rules={[
                            {
                                required: true,
                                message: `Please input the ${
                                    selectedField || 'received amount'
                                }!`
                            }
                        ]}
                    >
                        {selectedOrder && (
                            <InputNumber
                                min={0}
                                style={{ width: '130px' }}
                                addonAfter={selectedOrder.branch_inventory_group.unit_type}
                            />
                        )}
                    </Form.Item>
                </Form>
            </Modal>
        </DefaultContainer>
    );
};

export default BranchOrders;
