import React, { useEffect, useState } from 'react';
import { Table, Button, InputNumber, notification } from 'antd';
import CutsService from '../../../../http/CutsService'; // Adjust path if needed
import DefaultContainer from '../../../../components/DefaultContainer';

const DEFAULT_MIN_ROWS = 12;

function CentralKitchenCuts() {
    const [loading, setLoading] = useState(false);

    // Basic plan data
    const [workingDate, setWorkingDate] = useState('');
    const [predictedBurgers, setPredictedBurgers] = useState(0);
    const [totalNeededKg, setTotalNeededKg] = useState(0);

    // Meat types (array), and rows (pivoted)
    const [meatTypes, setMeatTypes] = useState([]);
    const [rows, setRows] = useState([]);

    // --------------------------------------------------
    // 1) Load data from server on mount
    // --------------------------------------------------
    useEffect(() => {
        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function loadData() {
        setLoading(true);
        CutsService.index(
            (resp) => {
                setLoading(false);
                const data = resp?.data?.data;
                if (!data) {
                    notification.error({
                        message: 'Error',
                        description: 'No data returned from server'
                    });
                    return;
                }

                setWorkingDate(data.working_date || '');
                setPredictedBurgers(data.predicted_burgers || 0);

                // If total_required_kg is present, use it; otherwise fallback to 0
                const parsedReqKg = parseFloat(data.total_required_kg || 0).toFixed(1);
                setTotalNeededKg(parseFloat(parsedReqKg));

                // Meat types
                const types = data.meat_types || [];
                setMeatTypes(types);

                // Pivot items => an array of row objects
                const planItems = data.cut_plan_items || [];
                const pivoted = pivotItemsByMeatType(planItems, types);

                // Ensure at least 12 rows
                const paddedRows = [...pivoted];
                while (paddedRows.length < DEFAULT_MIN_ROWS) {
                    paddedRows.push({ weights: types.map(() => '') });
                }

                setRows(paddedRows);
            },
            (err) => {
                setLoading(false);
                showError('Failed to load daily cuts', err);
            }
        );
    }

    // --------------------------------------------------
    // 2) Pivot items by meat type => each row is "nth item" per type
    // --------------------------------------------------
    function pivotItemsByMeatType(planItems, types) {
        // Group items by meat_type_id
        const grouped = {};
        types.forEach((mt) => {
            grouped[mt.id] = [];
        });

        planItems.forEach((item) => {
            if (grouped[item.meat_type_id]) {
                grouped[item.meat_type_id].push(item);
            }
        });

        // Figure out max # of items among all types
        let maxCount = 0;
        Object.values(grouped).forEach((arr) => {
            if (arr.length > maxCount) maxCount = arr.length;
        });

        // Build rows: row i => the i-th item of each type
        const result = [];
        for (let i = 0; i < maxCount; i++) {
            const weights = types.map((mt) => {
                const item = grouped[mt.id][i];
                return item ? parseFloat(item.weight_kg || 0) : '';
            });
            result.push({ weights });
        }
        return result;
    }

    // --------------------------------------------------
    // 3) Row manipulation
    // --------------------------------------------------
    function addRow() {
        setRows((prev) => [
            ...prev,
            { weights: meatTypes.map(() => '') }
        ]);
    }

    function removeRow(rowIdx) {
        setRows((prev) => prev.filter((_, i) => i !== rowIdx));
    }

    function handleWeightChange(rowIdx, colIdx, newVal) {
        setRows((prev) =>
            prev.map((rowObj, i) => {
                if (i !== rowIdx) return rowObj;
                const newWeights = [...rowObj.weights];
                newWeights[colIdx] = newVal;
                return { weights: newWeights };
            })
        );
    }

    // --------------------------------------------------
    // 4) Build columns
    // --------------------------------------------------
    const columns = [
        {
            title: 'Row #',
            render: (val, record, rowIdx) => `Row ${rowIdx + 1}`
        },
        ...meatTypes.map((mt, colIdx) => ({
            title: `${mt.name} (${mt.percentage}%)`,
            render: (val, record, rowIdx) => {
                const w = record.weights[colIdx];
                return (
                    <InputNumber
                        min={0}
                        step={0.1}
                        value={w === '' ? '' : parseFloat(w)}
                        onChange={(nv) => handleWeightChange(rowIdx, colIdx, nv)}
                    />
                );
            }
        })),
        {
            title: 'Actions',
            render: (val, record, rowIdx) => (
                <Button danger onClick={() => removeRow(rowIdx)}>
                    Remove
                </Button>
            )
        }
    ];

    // Table data
    const dataSource = rows.map((r, i) => ({ key: i, ...r }));

    // --------------------------------------------------
    // 5) Summaries across columns
    // --------------------------------------------------
    const sums = meatTypes.map((mt, colIndex) => {
        let total = 0;
        rows.forEach((r) => {
            const val = parseFloat(r.weights[colIndex]);
            if (!isNaN(val)) {
                total += val;
            }
        });
        return total;
    });

    const required = meatTypes.map((mt) => {
        const val = totalNeededKg * (parseFloat(mt.percentage) / 100);
        return parseFloat(val.toFixed(1));
    });

    const remain = required.map((reqVal, idx) => {
        const diff = reqVal - sums[idx];
        return parseFloat(diff.toFixed(1));
    });

    // --------------------------------------------------
    // 6) Save to server
    // --------------------------------------------------
    function handleSave() {
        setLoading(true);

        // Build items => only if weight > 0
        const itemsPayload = [];
        rows.forEach((row) => {
            row.weights.forEach((val, colIndex) => {
                const numVal = parseFloat(val);
                if (!isNaN(numVal) && numVal > 0) {
                    itemsPayload.push({
                        meat_type_id: meatTypes[colIndex].id,
                        weight_kg: numVal.toFixed(1)
                    });
                }
            });
        });

        const payload = {
            total_required_kg: parseFloat(totalNeededKg),
            items: itemsPayload
        };

        CutsService.store(
            payload,
            (resp) => {
                setLoading(false);
                notification.success({
                    message: 'Success',
                    description: resp.data.message
                });
                // Optionally reload
                // loadData();
            },
            (err) => {
                setLoading(false);
                showError('Failed to store daily cuts', err);
            }
        );
    }

    // --------------------------------------------------
    // 7) Helper for errors
    // --------------------------------------------------
    function showError(msg, err) {
        let desc = msg;
        if (err && err.response && err.response.data && err.response.data.message) {
            desc = err.response.data.message;
        }
        notification.error({
            message: 'Error',
            description: desc
        });
    }

    // --------------------------------------------------
    // 8) Render
    // --------------------------------------------------
    return (
        <DefaultContainer>
            <div style={{ padding: '1rem' }}>
                <h2>Central Kitchen Cuts</h2>
                <p>Working Date: <strong>{workingDate}</strong></p>
                <p>Predicted Burgers: <strong>{predictedBurgers}</strong></p>

                <div style={{ marginBottom: '1rem' }}>
                    <label style={{ marginRight: '8px' }}>Total KG Needed:</label>
                    <InputNumber
                        min={0}
                        step={0.1}
                        value={totalNeededKg}
                        onChange={(v) => setTotalNeededKg(v)}
                    />
                </div>

                <Button onClick={addRow} style={{ marginBottom: '1rem' }}>
                    Add Row
                </Button>

                <Table
                    columns={columns}
                    dataSource={dataSource}
                    pagination={false}
                    loading={loading}
                    summary={() => (
                        <Table.Summary.Row>
                            <Table.Summary.Cell index={0}>
                                <strong>Totals / Required / Remaining</strong>
                            </Table.Summary.Cell>
                            {meatTypes.map((mt, i) => {
                                const usedVal = sums[i].toFixed(1);
                                const reqVal = required[i].toFixed(1);
                                const remVal = remain[i].toFixed(1);
                                const colorStyle = {
                                    color: parseFloat(remVal) < 0 ? 'red' : 'inherit'
                                };

                                return (
                                    <Table.Summary.Cell key={mt.id} index={i + 1}>
                                        <div>
                                            <strong>Used:</strong> {usedVal}
                                        </div>
                                        <div>
                                            <strong>Req:</strong> {reqVal}
                                        </div>
                                        <div style={colorStyle}>
                                            <strong>Remain:</strong> {remVal}
                                        </div>
                                    </Table.Summary.Cell>
                                );
                            })}
                            {/* Extra cell for the Actions column */}
                            <Table.Summary.Cell index={meatTypes.length + 1} />
                        </Table.Summary.Row>
                    )}
                />

                <Button
                    type="primary"
                    onClick={handleSave}
                    loading={loading}
                    style={{ marginTop: '1rem' }}
                >
                    Save
                </Button>
            </div>
        </DefaultContainer>
    );
}

export default CentralKitchenCuts;
