import React, {useEffect, useState} from 'react';
import {motion} from "framer-motion";
import T from "../../../../components/T";
import {Button, Tag, Pagination, Select, Input, notification} from 'antd';
import {useSelector} from "react-redux";
import Employees from "../../../../http/Employees";
import {Link, useHistory} from "react-router-dom";

// Import Remix Icons
import {RiUserAddLine, RiUserFollowLine, RiUserSettingsLine} from '@remixicon/react';
import SkeletonWrapper from "../../../../components/skeletons/SkeletonWrapper";
import GenericSkeleton from "../../../../components/skeletons/GenericSkeleton";

const { Option } = Select;

const ManageEmployees = () => {
    const branch = useSelector(state => state.dashboard.branch);

    // Loading states
    const [loading, setLoading] = useState(true);
    const [reinviting, setReinviting] = useState(false);

    // Data states
    const [employees, setEmployees] = useState([]);
    const [filteredEmployees, setFilteredEmployees] = useState([]);

    // Pagination
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);

    // Lookup data
    const [branches, setBranches] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [roles, setRoles] = useState([]);
    const [allRoles, setAllRoles] = useState([]);

    // Filters (default to "active" for profileStatus)
    const [filters, setFilters] = useState({
        location: 'all',
        department: 'all',
        role: 'all',
        employeeType: 'all',
        employmentType: 'all',
        profileStatus: 'active', // default to "active"
        search: ''
    });
    const [searchInput, setSearchInput] = useState('');

    const history = useHistory();

    // Fetch employees and lookup data
    const reload = (forceShowLoading = false) => {
        if (forceShowLoading) setLoading(true);
        Employees.Index(
            (response) => {
                setEmployees(response.data.data.employees);
                setBranches(response.data.data.branches);
                setDepartments(response.data.data.departments);
                setAllRoles(response.data.data.roles);
                setLoading(false);
            },
            () => setLoading(false)
        );
    };

    // Load data initially
    useEffect(() => {
        reload();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Re-filter whenever employees or filters change
    useEffect(() => {
        applyFilters();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [employees, filters]);

    // When department changes, update roles list
    useEffect(() => {
        if (filters.department !== 'all') {
            const selectedDepartment = departments.find(dept => dept.id === filters.department);
            setRoles(selectedDepartment ? selectedDepartment.roles : []);
        } else {
            setRoles([]);
        }
    }, [filters.department, departments]);

    // Helper: figure out "invited", "active", "terminated"
    const getEmployeeProfileStatus = (employee) => {
        if (employee.invited_at && !employee.invitation_accepted_at) {
            return 'invited';
        }
        return employee.status === 1 ? 'active' : 'terminated';
    };

    // Apply all filters
    const applyFilters = () => {
        let filtered = [...employees];

        // Filter by location
        if (filters.location !== 'all') {
            filtered = filtered.filter(emp =>
                emp.branches.some(branch => branch.id === filters.location)
            );
        }

        // Filter by department
        if (filters.department !== 'all') {
            filtered = filtered.filter(emp =>
                emp.departments.some(dept => dept.id === filters.department)
            );
        }

        // Filter by role
        if (filters.role !== 'all') {
            filtered = filtered.filter(emp =>
                emp.departments.some(dept =>
                    dept.roles.some(role => role.id === filters.role)
                )
            );
        }

        // Filter by employee type
        if (filters.employeeType !== 'all') {
            filtered = filtered.filter(emp =>
                emp.employee_type === filters.employeeType
            );
        }

        // Filter by employment type
        if (filters.employmentType !== 'all') {
            filtered = filtered.filter(emp =>
                emp.employment_type === filters.employmentType ||
                emp.employment_type === null
            );
        } else {
            // If "all", also include null
            filtered = filtered.filter(emp =>
                emp.employment_type === 'fulltime' ||
                emp.employment_type === 'parttime' ||
                emp.employment_type === null
            );
        }

        // Filter by new "profileStatus"
        if (filters.profileStatus !== 'all') {
            filtered = filtered.filter(emp =>
                getEmployeeProfileStatus(emp) === filters.profileStatus
            );
        }

        // Filter by search
        if (filters.search) {
            const searchTerm = filters.search.toLowerCase();
            filtered = filtered.filter(emp =>
                emp.name.toLowerCase().includes(searchTerm) ||
                emp.email.toLowerCase().includes(searchTerm)
            );
        }

        setFilteredEmployees(filtered);
        setCurrentPage(1);
    };

    const handleFilterChange = (value, filterType) => {
        setFilters(prev => ({ ...prev, [filterType]: value }));
    };

    const handleSearch = (e) => {
        const value = e.target.value;
        setSearchInput(value);
        handleFilterChange(value, 'search');
    };

    const resetFilters = () => {
        setFilters({
            location: 'all',
            department: 'all',
            role: 'all',
            employeeType: 'all',
            employmentType: 'all',
            profileStatus: 'active', // revert to default "active"
            search: ''
        });
        setSearchInput('');
    };

    const handlePageChange = (page, size) => {
        setCurrentPage(page);
        setPageSize(size);
    };

    // Re-invite action
    const onReinvite = () => {
        setReinviting(true);
        Employees.Reinvite(
            (response) => {
                setReinviting(false);
                notification['success']({
                    message: 'Success!',
                    description: response.data.message,
                });
            },
            (err) => {
                setReinviting(false);
                notification['error']({
                    message: 'Error!',
                    description: err.response?.data.message,
                });
            }
        );
    };

    // Navigate to create
    const onUserCreate = () => {
        history.push("/dashboard/hr/employees/new");
    };

    // Pagination slice
    const paginatedEmployees = filteredEmployees.slice(
        (currentPage - 1) * pageSize,
        currentPage * pageSize
    );

    return (
        <motion.div
            initial={{ opacity: 0, scale: 1.1, y: 0 }}
            animate={{ opacity: 1, scale: 1, y: 0 }}
            className="w-full mx-auto max-w-8xl mt-2"
        >
            <div className="flex flex-col mx-auto w-full">
                <div className="text-xl text-gray-700 md:pl-1 flex flex-row mb-4 justify-between w-full">
                    <div className="flex flex-row items-center justify-between w-full">
                        <span className="text-xl text-gray-900 font-bold">
                            <T>Manage Team Members</T>
                        </span>
                        <div className="flex flex-col items-end md:flex-row md:items-center md:justify-center">
                            <div className="mb-2 md:mb-0 md:mr-2">
                                <Button
                                    onClick={onReinvite}
                                    loading={reinviting}
                                    type="primary"
                                    style={{ height: "auto" }}
                                    className="secondary-button"
                                >
                                    <div className="flex flex-row items-center">
                                        <RiUserFollowLine
                                            size={20}
                                            className="mr-2 cursor-pointer"
                                            aria-hidden="true"
                                        />
                                        Re-invite Expired Invitations
                                    </div>
                                </Button>
                            </div>

                            <Button
                                onClick={onUserCreate}
                                type="primary"
                                style={{ height: "auto" }}
                                className="primary-button"
                            >
                                <div className="flex flex-row items-center">
                                    <RiUserAddLine
                                        size={20}
                                        className="mr-2 cursor-pointer"
                                        aria-hidden="true"
                                    />
                                    Add New Team Member
                                </div>
                            </Button>
                        </div>
                    </div>
                </div>

                {/* Filters row */}
                <div className="flex flex-row p-2 items-center bg-white shadow-sm rounded-lg mb-4">
                    <div className="mr-2 flex-grow">
                        <Input.Search
                            placeholder="Search for a team member"
                            value={searchInput}
                            onChange={handleSearch}
                            className="w-full rounded-lg"
                        />
                    </div>
                    <div className="mr-2 w-48">
                        <Select
                            placeholder="All locations"
                            onChange={(val) => handleFilterChange(val, 'location')}
                            className="w-full rounded-lg"
                            value={filters.location}
                        >
                            <Option value="all">All locations</Option>
                            {branches.map(branch => (
                                <Option key={branch.id} value={branch.id}>
                                    {branch.name}
                                </Option>
                            ))}
                        </Select>
                    </div>
                    <div className="mr-2 w-48">
                        <Select
                            placeholder="All departments"
                            onChange={(val) => handleFilterChange(val, 'department')}
                            className="w-full rounded-lg"
                            value={filters.department}
                        >
                            <Option value="all">All departments</Option>
                            {departments.map(dept => (
                                <Option key={dept.id} value={dept.id}>
                                    {dept.name}
                                </Option>
                            ))}
                        </Select>
                    </div>
                    <div className="mr-2 w-48">
                        <Select
                            placeholder="All roles"
                            onChange={(val) => handleFilterChange(val, 'role')}
                            disabled={filters.department === 'all'}
                            className="w-full rounded-lg"
                            value={filters.role}
                        >
                            <Option value="all">All roles</Option>
                            {roles.map(role => (
                                <Option key={role.id} value={role.id}>
                                    {role.name}
                                </Option>
                            ))}
                        </Select>
                    </div>
                    <div className="mr-2 w-48">
                        <Select
                            placeholder="All employee types"
                            onChange={(val) => handleFilterChange(val, 'employeeType')}
                            className="w-full rounded-lg"
                            value={filters.employeeType}
                        >
                            <Option value="all">All employee types</Option>
                            <Option value="employee">Employee</Option>
                            <Option value="manager">Manager</Option>
                            <Option value="admin">Admin</Option>
                        </Select>
                    </div>
                    <div className="w-48 mr-2">
                        <Select
                            placeholder="All statuses"
                            onChange={(val) => handleFilterChange(val, 'employmentType')}
                            className="w-full rounded-lg"
                            value={filters.employmentType}
                        >
                            <Option value="all">All statuses</Option>
                            <Option value="fulltime">Full-time</Option>
                            <Option value="parttime">Part-time</Option>
                        </Select>
                    </div>
                    <div className="w-48 mr-2">
                        <Select
                            placeholder="Profile Status"
                            onChange={(val) => handleFilterChange(val, 'profileStatus')}
                            className="w-full rounded-lg"
                            value={filters.profileStatus}
                        >
                            <Option value="all">All profile statuses</Option>
                            <Option value="invited">Invited</Option>
                            <Option value="active">Active</Option>
                            <Option value="terminated">Terminated</Option>
                        </Select>
                    </div>
                    <div>
                        <Button
                            type="link"
                            className="text-sm text-blue-500"
                            onClick={resetFilters}
                        >
                            Reset Filters
                        </Button>
                    </div>
                </div>

                {/* Employees table/list */}
                <div className="w-full flex flex-col lg:flex-row">
                    <div
                        className="rounded-lg flex-1 border shadow-xl bg-white p-4 flex flex-col items-center justify-center w-full"
                    >
                        <SkeletonWrapper loading={loading} skeleton={GenericSkeleton}>
                            {paginatedEmployees && paginatedEmployees.length > 0 ? (
                                <div className="w-full">
                                    {paginatedEmployees.map(employee => {
                                        return (
                                            <div
                                                key={employee.id}
                                                className="mb-2 border border-gray-200 w-full rounded-lg px-4 py-3 bg-gray-50 flex flex-col items-center md:flex-row"
                                            >
                                                <div className="flex flex-col md:flex-row items-center w-full">
                                                    <span className="font-semibold whitespace-nowrap mr-2 text-base">
                                                        <Link
                                                            to={`/dashboard/hr/employees/${employee.id}/edit`}
                                                            className="text-gray-800"
                                                        >
                                                            {employee.name}
                                                        </Link>
                                                    </span>
                                                    <span className="text-gray-500">
                                                        {employee.email}
                                                    </span>
                                                    {employee.employee_type !== "employee" && (
                                                        <div className="flex flex-row ml-2">
                                                            <div
                                                                className="uppercase px-2 py-1 font-bold bg-white border border-gray-200 rounded-md text-xs text-blue-600"
                                                            >
                                                                {employee.employee_type}
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="w-full flex flex-row items-center md:justify-end justify-center mt-2 md:mt-0">
                                                    {employee.invited_at && !employee.invitation_accepted_at ? (
                                                        <span className="pl-1">
                                                            <Tag
                                                                color="blue"
                                                                style={{ borderRadius: "5px" }}
                                                            >
                                                                INVITED
                                                            </Tag>
                                                        </span>
                                                    ) : (
                                                        <span className="pl-1">
                                                            {employee.status === 1 ? (
                                                                <Tag
                                                                    color="green"
                                                                    style={{ borderRadius: "5px" }}
                                                                >
                                                                    ACTIVE
                                                                </Tag>
                                                            ) : (
                                                                <Tag
                                                                    color="red"
                                                                    style={{ borderRadius: "5px" }}
                                                                >
                                                                    TERMINATED
                                                                </Tag>
                                                            )}
                                                        </span>
                                                    )}
                                                    <Link
                                                        to={`/dashboard/hr/employees/${employee.id}/edit`}
                                                        className="text-gray-800"
                                                    >
                                                        <RiUserSettingsLine
                                                            className="block text-lg mr-2 cursor-pointer"
                                                            aria-hidden="true"
                                                        />
                                                    </Link>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            ) : (
                                <div className="w-full text-center text-gray-400 pb-1">
                                    There are no team members on this branch.
                                </div>
                            )}
                        </SkeletonWrapper>
                    </div>
                </div>

                {/* Pagination */}
                <div className="mt-4 flex justify-center">
                    <Pagination
                        current={currentPage}
                        pageSize={pageSize}
                        total={filteredEmployees.length}
                        onChange={handlePageChange}
                        className="rounded-lg"
                    />
                </div>
            </div>
        </motion.div>
    );
};

export default ManageEmployees;
