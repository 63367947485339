import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {Button, Table, Pagination, notification, Modal} from 'antd';
import {RiEditLine, RiDeleteBinLine, RiAddLine} from '@remixicon/react';
import Device from '../../../../../http/Device';
import DefaultContainer from '../../../../../components/DefaultContainer';
import Profile from "../../../../../http/Profile";
import MenuSkeleton from "../../../../../components/skeletons/MenuSkeleton";
import SkeletonWrapper from "../../../../../components/skeletons/SkeletonWrapper";
import EndOfShiftReportShow from "../../Inventory/EndOfShiftReports/EndOfShiftReportShow";

const UnacknowledgedUserNotices = () => {

    const [notices, setNotices] = useState([]);

    useEffect(() => {
        loadNotices()
    }, []);

    const loadNotices = () => {
        Profile.UnacknowledgedNotices((response) => {
            setNotices(response.data.data);
        }, (err) => {
            notification.error({
                message: 'Error',
                description: 'Failed to load notices.',
            });
        });
    };

    return (
        notices && notices.length > 0 ?
            <DefaultContainer>
                <div className={"text-xl font-bold pb-2"}>My Notices & Warnings</div>
                {notices.map(notice => {
                    return (<div className={"mb-3"}><EndOfShiftReportShow _report={notice} acknowledgable={true} /></div>)
                })}
            </DefaultContainer>
        :
            null
    )
};

export default UnacknowledgedUserNotices;
