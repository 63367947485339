import React, { useEffect, useState } from 'react';
import { Table, Button, notification, DatePicker } from 'antd';
import moment from 'moment';
import CutsService from '../../../../http/CutsService'; // adjust path

const { RangePicker } = DatePicker;

function showError(msg, err) {
    let desc = msg;
    if (err && err.response && err.response.data && err.response.data.message) {
        desc = err.response.data.message;
    }
    notification.error({ message: 'Error', description: desc });
}

function CutPlanHistory() {
    const [loading, setLoading] = useState(false);

    // Pagination
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [totalItems, setTotalItems] = useState(0);

    // Date range
    const [dateRange, setDateRange] = useState([]);

    // Data
    const [dataSource, setDataSource] = useState([]);  // array of cutPlans
    const [meatTypes, setMeatTypes] = useState([]);    // from server

    useEffect(() => {
        loadHistory();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, perPage, dateRange]);

    function loadHistory() {
        setLoading(true);

        const params = { page: currentPage, per_page: perPage };
        if (dateRange.length === 2) {
            params.start_date = moment(dateRange[0]).format('YYYY-MM-DD');
            params.end_date   = moment(dateRange[1]).format('YYYY-MM-DD');
        }

        CutsService.getHistory(
            params,
            (resp) => {
                setLoading(false);
                const responseData = resp?.data;
                if (!responseData?.success) {
                    notification.error({
                        message: 'Error loading history',
                        description: responseData?.message || 'Unknown error'
                    });
                    return;
                }

                // The server now returns:
                // {
                //   "success": true,
                //   "message": "Cut plan history list",
                //   "data": {
                //       "pagination": { ...paginated cutPlans... },
                //       "meat_types": [...],
                //   }
                // }

                const pagination = responseData.data.pagination;
                const mt = responseData.data.meat_types || [];

                setDataSource(pagination.data || []); // array of cutPlans
                setTotalItems(pagination.total || 0);
                setMeatTypes(mt);
            },
            (err) => {
                setLoading(false);
                showError('Failed to load cut plan history', err);
            }
        );
    }

    // Main columns for the top-level table
    const columns = [
        {
            title: 'Working Date',
            dataIndex: 'working_date'
        },
        {
            title: 'Predicted Burgers',
            dataIndex: 'predicted_burgers',
            render: (val) => val || 0
        },
        {
            title: 'Total Required (kg)',
            dataIndex: 'total_required_kg',
            render: (val) => (val ? parseFloat(val).toFixed(2) : '0.00')
        },
        {
            title: 'Used Weight (kg)',
            dataIndex: 'total_cuts_weight',
            render: (val) => (val ? parseFloat(val).toFixed(2) : '0.00')
        }
    ];

    // Expanded row => pivoted sub-table
    function expandedRowRender(record) {
        // record has:
        //   predicted_burgers,
        //   final_required_kg,
        //   pivotted_items => [ { weights: [ w0, w1, w2...] }, ... ]
        //   sum_used => [ sumForType0, sumForType1, ... ]

        const pivotRows = record.pivotted_items || [];
        const usedArray = record.sum_used || [];

        // We'll also compute "required" and "remain" per meat type
        // If the plan doesn't have a custom total_required_kg, fallback to predicted * 0.2
        const totalReqKg = record.final_required_kg || 0;
        const requiredArray = meatTypes.map(mt => {
            const pct = parseFloat(mt.percentage) || 0;
            return parseFloat((totalReqKg * (pct / 100)).toFixed(1));
        });
        const remainArray = requiredArray.map((req, i) => parseFloat((req - (usedArray[i] || 0)).toFixed(1)));

        // Build columns
        const pivotColumns = [
            {
                title: 'Row #',
                key: 'rowIndex',
                render: (unused, row, rowIndex) => `Row ${rowIndex + 1}`
            },
            ...meatTypes.map((mt, colIdx) => ({
                title: `${mt.name} (${mt.percentage}%)`,
                key: `col_${mt.id}`,
                render: (unused, row) => {
                    const val = row.weights[colIdx];
                    return val && !isNaN(val) ? val.toFixed(1) : '';
                }
            }))
        ];

        // Data source for pivot sub-table
        const pivotData = pivotRows.map((rowObj, i) => ({
            ...rowObj,
            key: i
        }));

        // Build a summary row for "Used/Req/Remain"
        // We'll do it by using the Table `summary` prop
        const pivotSummary = () => {
            return (
                <Table.Summary.Row>
                    <Table.Summary.Cell index={0}>
                        <strong>Used / Required / Remain</strong>
                    </Table.Summary.Cell>
                    {meatTypes.map((mt, i) => {
                        const usedVal = (usedArray[i] || 0).toFixed(1);
                        const reqVal  = requiredArray[i].toFixed(1);
                        const remVal  = remainArray[i].toFixed(1);
                        const color   = parseFloat(remVal) < 0 ? 'red' : 'inherit';

                        return (
                            <Table.Summary.Cell key={mt.id} index={i + 1}>
                                <div><strong>Used:</strong> {usedVal}</div>
                                <div><strong>Req:</strong> {reqVal}</div>
                                <div style={{ color }}>
                                    <strong>Remain:</strong> {remVal}
                                </div>
                            </Table.Summary.Cell>
                        );
                    })}
                </Table.Summary.Row>
            );
        };

        // We'll show a small "header" block above the pivot table
        // for total burgers, total KG needed, etc.
        // Then the pivot table with summary
        return (
            <div style={{ padding: '.5rem', border: '1px solid #eee' }} className={"bg-gray-100"}>
                <div style={{ marginBottom: '1rem' }}>
                    <p><strong>Total Burgers:</strong> {record.predicted_burgers || 0}</p>
                    <p><strong>Total KG Needed:</strong> {totalReqKg.toFixed(1)}</p>
                </div>

                <Table
                    columns={pivotColumns}
                    dataSource={pivotData}
                    pagination={false}
                    size="small"
                    summary={pivotSummary}
                />
            </div>
        );
    }


    function handleTableChange(pagination) {
        setCurrentPage(pagination.current);
        setPerPage(pagination.pageSize);
    }

    return (
        <div style={{ padding: '1rem' }}>
            <h2>Cut Plan History</h2>

            <div style={{ marginBottom: '1rem' }}>
                <span style={{ marginRight: 8 }}>Date Range:</span>
                <RangePicker
                    value={dateRange}
                    onChange={(vals) => setDateRange(vals || [])}
                />
                <Button
                    style={{ marginLeft: '1rem' }}
                    onClick={() => {
                        setCurrentPage(1);
                        loadHistory();
                    }}
                >
                    Search
                </Button>
            </div>

            <Table
                columns={columns}
                dataSource={dataSource}
                loading={loading}
                rowKey="id"
                expandable={{ expandedRowRender }}
                pagination={{
                    current: currentPage,
                    pageSize: perPage,
                    total: totalItems,
                    showSizeChanger: true
                }}
                onChange={handleTableChange}
            />
        </div>
    );
}

export default CutPlanHistory;
