import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Card, notification, Tag, Button, Divider } from 'antd';
import { RiUserLine, RiFileTextLine, RiAlertLine, RiFlagLine } from '@remixicon/react';
import BranchInventory from '../../../../../http/BranchInventory';
import SkeletonWrapper from '../../../../../components/skeletons/SkeletonWrapper';
import GenericSkeleton from '../../../../../components/skeletons/GenericSkeleton';
import DefaultContainer from '../../../../../components/DefaultContainer';
import moment from 'moment';
import usePermission from '../../../../../layouts/usePermission';

const EndOfShiftReportShow = ({ _report, acknowledgable }) => {
    const { branchId, reportId } = useParams();
    const [loading, setLoading] = useState(true);
    const [acknowledging, setAcknowledging] = useState(false);
    const [report, setReport] = useState(null);

    const isManager = usePermission(null, [
        "inventory_assistant_general_manager",
        "inventory_general_manager"
    ]);

    useEffect(() => {
        // If we have a "reportId" from params, load it
        if (reportId) {
            BranchInventory.ShowEndOfShiftReport(
                reportId,
                (response) => {
                    setReport(response.data.data.report);
                    setLoading(false);
                },
                (err) => {
                    notification.error({
                        message: 'Error!',
                        description: 'Failed to load end of shift report.',
                    });
                    setLoading(false);
                }
            );
        }
    }, [branchId, reportId]);

    useEffect(() => {
        // If a _report prop is passed (optional usage), we can set that directly
        if (_report) {
            setReport(_report);
            setLoading(false);
        }
    }, [_report]);

    const handleAcknowledge = () => {
        if (!report) return;
        setAcknowledging(true);
        BranchInventory.AcknowledgeShowEndOfShiftReport(
            report.id,
            (response) => {
                notification.success({
                    message: 'Acknowledged',
                    description: 'You have successfully acknowledged the notice.',
                });
                setReport(response.data.data.report);
                setAcknowledging(false);
            },
            (err) => {
                notification.error({
                    message: 'Error!',
                    description: 'Failed to acknowledge the notice.',
                });
                setAcknowledging(false);
            }
        );
    };

    const renderStatusTag = (status) => {
        if (status === 'pending') return <Tag color="orange">Pending</Tag>;
        if (status === 'confirmed') return <Tag color="green">Confirmed</Tag>;
        if (status === 'rejected') return <Tag color="red">Rejected</Tag>;
        return <Tag color="default">{status}</Tag>;
    };

    return (
        <SkeletonWrapper loading={loading} skeleton={GenericSkeleton}>
            <DefaultContainer>
                {report && (
                    <Card
                        title={
                            <div className="flex items-center space-x-2">
                                <RiFileTextLine className="text-xl" />
                                <span className="text-lg font-semibold">
                                    End of Shift Report - {report.date} ({report.shift})
                                </span>
                            </div>
                        }
                        className="shadow-md rounded-lg border-t-4 border-blue-500"
                    >
                        <div className="flex items-center my-2">
                            <RiUserLine className="text-xl mr-2" />
                            <div className="font-medium text-gray-600">
                                <strong>Issuer:</strong> {report.user?.name}
                            </div>
                        </div>

                        <Divider dashed />

                        <div className="my-2">
                            <strong>Status: </strong>
                            {renderStatusTag(report.status)}
                            {/* Show "Edit" if manager */}
                            {isManager && (
                                <Link
                                    to={`/dashboard/operations/daily-logs/end-of-shift/${report.branch_id}/report/${report.id}/edit`}
                                >
                                    <Button type="link">Edit</Button>
                                </Link>
                            )}
                        </div>

                        <Divider dashed />

                        <div className="my-2 flex items-center">
                            <RiAlertLine className="text-xl mr-2" />
                            <div className="font-medium text-gray-600">
                                <strong>Summary of Operations:</strong> {report.summary}
                            </div>
                        </div>

                        <Divider dashed />

                        <div className="flex items-center my-2">
                            <RiFlagLine className="text-xl mr-2" />
                            <div className="font-medium text-gray-600">
                                <strong>Challenges Encountered:</strong> {report.challenges}
                            </div>
                        </div>

                        <Divider dashed />

                        <div className="my-2">
                            <div className="font-medium text-gray-600">
                                <strong>Materials Out of Stock:</strong>{' '}
                                {report.out_of_stock_materials}
                            </div>
                        </div>

                        <Divider dashed />

                        <div className="flex items-center my-2">
                            <div className="font-medium text-gray-600">
                                <strong>Unresolved Issues:</strong> {report.unresolved_issues}
                            </div>
                        </div>

                        <Divider dashed />

                        <div className="my-2">
                            <div className="font-medium text-gray-600">
                                <strong>Incidents:</strong>{' '}
                                {report.incidents ? <Tag color="red">Yes</Tag> : <Tag color="green">No</Tag>}
                            </div>
                            {report.incidents && (
                                <div className="font-medium text-gray-600 mt-4">
                                    <strong>Incident Description:</strong> {report.incident_description}
                                </div>
                            )}
                        </div>

                        <Divider dashed />

                        <div className="my-2">
                            <div className="font-medium text-gray-600">
                                <strong>Notices Given:</strong>{' '}
                                {report.notices_given ? <Tag color="orange">Yes</Tag> : <Tag color="green">No</Tag>}
                            </div>
                            {report.notices_given && (
                                <>
                                    <div className="font-medium text-gray-600 mt-4">
                                        <strong>Notice Type:</strong> {report.notice_type || 'N/A'}
                                    </div>
                                    <div className="font-medium text-gray-600 mt-4">
                                        <strong>Notice Recipient:</strong>{' '}
                                        {report.notice_user ? report.notice_user.name : 'N/A'}
                                    </div>
                                    <div className="font-medium text-gray-600 mt-4">
                                        <strong>Notice Description:</strong>{' '}
                                        {report.notice_description || 'N/A'}
                                    </div>
                                    <div className="font-medium text-gray-600 mt-4">
                                        <strong>Notice Acknowledged:</strong>{' '}
                                        {report.notice_acknowledged_at ? (
                                            <Tag color="green">
                                                Yes, on{' '}
                                                {moment(report.notice_acknowledged_at).format(
                                                    'DD/MM/YYYY HH:mm'
                                                )}
                                            </Tag>
                                        ) : (
                                            <Tag color="red">No</Tag>
                                        )}
                                    </div>
                                </>
                            )}
                        </div>

                        {/* Acknowledge button if (acknowledgable) and not yet acknowledged */}
                        {acknowledgable && report.notices_given && !report.notice_acknowledged_at && (
                            <div className="mt-4 flex justify-end">
                                <Button
                                    type="primary"
                                    loading={acknowledging}
                                    onClick={handleAcknowledge}
                                >
                                    Acknowledge Notice
                                </Button>
                            </div>
                        )}
                    </Card>
                )}
            </DefaultContainer>
        </SkeletonWrapper>
    );
};

export default EndOfShiftReportShow;
