import React, {useEffect, useState} from 'react';
import {Modal, Form, InputNumber, Select, Radio, Tooltip, Switch, Input, Checkbox, DatePicker} from 'antd';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons';
import FormItem from "../../../../../components/Form/FormItem";
import moment from "moment";
import usePermission from "../../../../../layouts/usePermission";
import StatusDropdown from "./StatusDropdown";

const {Option} = Select;

const GeneratePresetModal = ({
                                 visible,
                                 onSave,
                                 onCancel,
                                 editCouponGeneratePreset,
                                 discountPresets,
                                 validationFields,
                                 saving,
                                 form
                             }) => {
    const [isEdit, setIsEdit] = useState(false);
    const [amountTooltip, setAmountTooltip] = useState("Specify the discount amount.");
    const [showMaxAmount, setShowMaxAmount] = useState(false);
    const [showMaxPercentBalance, setShowMaxPercentBalance] = useState(false);
    const [showMaxTotalPrice, setShowMaxTotalPrice] = useState(false);
    const [showMaxCouponPrice, setShowMaxCouponPrice] = useState(false);
    const [showPercentBalance, setShowPercentBalance] = useState(false);

    // For controlling whether to show specific days selection
    const [specificDaysOnly, setSpecificDaysOnly] = useState(false);

    // NEW: For controlling whether to show specific hours selection
    const [specificHoursOnly, setSpecificHoursOnly] = useState(false);

    const [useExpirationType, setUseExpirationType] = useState('hours');
    const isDiscountRequester = usePermission(null, ["discount_requester"]);

    console.log("editCouponGeneratePreset", editCouponGeneratePreset);

    useEffect(() => {
        if (editCouponGeneratePreset) {
            setUseExpirationType(editCouponGeneratePreset.expiration_type);
            setSpecificDaysOnly(editCouponGeneratePreset.specific_days_only);

            // NEW: If editing, set the "specificHoursOnly" state from the preset’s data
            setSpecificHoursOnly(editCouponGeneratePreset.specific_hours_only);

            setIsEdit(true);

            // Prepare initial form values
            const initialValues = {
                ...editCouponGeneratePreset,
                beer: editCouponGeneratePreset.beer || 'all',
                expiration_date: editCouponGeneratePreset.expiration_date
                    ? moment(editCouponGeneratePreset.expiration_date, 'YYYY-MM-DD HH:mm:ss')
                    : null,
            };

            // Set form fields
            form.setFieldsValue(initialValues);

            // Adjust tooltip/visible logic based on `type`
            handleTypeChange(editCouponGeneratePreset.type);
        } else {
            setIsEdit(false);
            form.resetFields();

            // Default initial values for creation
            form.setFieldsValue({
                beer: 'all',
                type: 'percent',
                expiration_date: null // Set expiration_date to null initially
            });
            handleTypeChange('percent');
        }
    }, [editCouponGeneratePreset, form]);

    /**
     * Handle changes in the "type" (Percent vs. Balance).
     * Adjust the tooltip text and show/hide percent balance inputs.
     */
    const handleTypeChange = (value) => {
        if (value === 'percent') {
            setAmountTooltip("Choose the percentage discount to apply to orders.");
            setShowPercentBalance(true);
        } else {
            setAmountTooltip("Specify the dollar amount balance this coupon should have.");
            setShowPercentBalance(false);
        }
    };

    /**
     * Submit the form programmatically from the Modal's OK button
     */
    const onSubmit = () => {
        form.submit();
    };

    /**
     * Finalize form submission. Format date if necessary, then pass
     * the values to onSave callback.
     */
    const onFinish = (values) => {
        form.validateFields()
            .then(() => {
                if (values.expiration_date) {
                    values.expiration_date = moment(values.expiration_date).format('YYYY-MM-DD HH:mm:ss');
                }
                onSave(values, isEdit);
            })
            .catch(info => {
                console.log('Validate Failed:', info);
            });
    };

    /**
     * Toggle whether to show 'applicable_days'
     */
    const handleDaysSelectionChange = (e) => {
        setSpecificDaysOnly(e.target.checked);
    };

    /**
     * Toggle whether to show from/to hour fields
     */
    const handleHoursSelectionChange = (e) => {
        setSpecificHoursOnly(e.target.checked);
    };

    /**
     * Switch Expiration Type between "hours" and "date"
     */
    const handleExpirationTypeChange = (value) => {
        setUseExpirationType(value);
    };

    return (
        <Modal
            visible={visible}
            title={isEdit ? "Edit Coupon Generate Preset" : "Create Coupon Generate Preset"}
            okText={isEdit ? "Save" : "Create"}
            cancelText="Cancel"
            onCancel={onCancel}
            onOk={onSubmit}
            confirmLoading={saving}
            key={editCouponGeneratePreset ? `edit-${editCouponGeneratePreset.id}` : 'create'}
            width={600}
        >
            <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
                key={editCouponGeneratePreset ? `form-edit-${editCouponGeneratePreset.id}` : 'form-create'}
            >
                {/* Name */}
                <div className="flex flex-row items-start">
                    <label htmlFor="name" className="mr-2 pt-1 whitespace-nowrap w-64 text-right">
                        Name
                        <Tooltip title="Enter the name of the coupon preset.">
                            <FontAwesomeIcon icon={faInfoCircle} className="ml-1 text-gray-500" />
                        </Tooltip>
                    </label>
                    <FormItem
                        name="name"
                        errors={validationFields}
                        rules={[{ required: true, message: 'Please enter a name!' }]}
                        className="w-full"
                    >
                        <Input placeholder="Enter name" />
                    </FormItem>
                </div>

                {/* Discount Preset */}
                <div className="flex flex-row items-start">
                    <label htmlFor="discount_preset_id" className="mr-2 pt-1 whitespace-nowrap w-64 text-right">
                        Discount Preset
                        <Tooltip title="Select a discount preset for the coupon.">
                            <FontAwesomeIcon icon={faInfoCircle} className="ml-1 text-gray-500" />
                        </Tooltip>
                    </label>
                    <FormItem name="discount_preset_id" errors={validationFields} className="w-full">
                        <Select optionFilterProp="children">
                            <Option key={0} value={null}>Any Discount Preset</Option>
                            {discountPresets.map(preset => (
                                <Option key={preset.id} value={preset.id}>
                                    {preset.name}
                                </Option>
                            ))}
                        </Select>
                    </FormItem>
                </div>

                {/* Type */}
                <div className="flex flex-row items-start">
                    <label htmlFor="type" className="mr-2 pt-1 whitespace-nowrap w-64 text-right">
                        Type
                        <Tooltip title="Select whether you want to generate a percent discount or a balance discount.">
                            <FontAwesomeIcon icon={faInfoCircle} className="ml-1 text-gray-500" />
                        </Tooltip>
                    </label>
                    <FormItem
                        name="type"
                        errors={validationFields}
                        rules={[{ required: true, message: 'Please select a type!' }]}
                        className="w-full"
                    >
                        <Select placeholder="Select a type" onChange={handleTypeChange}>
                            <Option value="percent">Percent</Option>
                            <Option value="balance">Balance</Option>
                        </Select>
                    </FormItem>
                </div>

                <hr className="mb-2 mt-1" />

                {/* Amount */}
                <div className="flex flex-row items-center justify-start w-full">
                    <label htmlFor="min_amount" className="mr-2 whitespace-nowrap w-48 text-right">
                        Amount
                        <Tooltip title={amountTooltip}>
                            <FontAwesomeIcon icon={faInfoCircle} className="ml-1 text-gray-500" />
                        </Tooltip>
                    </label>
                    <FormItem
                        name="min_amount"
                        errors={validationFields}
                        className="flex-grow"
                    >
                        <InputNumber min={0} style={{ width: '100%' }} />
                    </FormItem>
                    {/* Hidden portion for max_amount, etc. (if needed) */}
                    <div className={"hidden"}>
                        <label htmlFor="max_amount" className="mx-2 whitespace-nowrap text-right">To:</label>
                        <Switch
                            style={{ marginRight: "5px", marginBottom: "3px" }}
                            onChange={setShowMaxAmount}
                        />
                        {showMaxAmount && (
                            <FormItem
                                name="max_amount"
                                errors={validationFields}
                                className="flex-grow"
                            >
                                <InputNumber min={0} style={{ width: '100%' }} />
                            </FormItem>
                        )}
                    </div>
                </div>

                {/* Percent Balance (if type=percent) */}
                {showPercentBalance && (
                    <div className="flex flex-row items-center justify-center">
                        <label htmlFor="min_percent_balance" className="mr-2 pb-1 whitespace-nowrap text-right w-48">
                            Percent Balance
                            <Tooltip title="Define how many times the generated coupon can be used. For example, setting this to 3 means the coupon can be applied to 3 different orders. For unlimited usage enter 0.">
                                <FontAwesomeIcon icon={faInfoCircle} className="ml-1 text-gray-500" />
                            </Tooltip>
                        </label>
                        <FormItem
                            name="min_percent_balance"
                            errors={validationFields}
                            className="flex-grow"
                        >
                            <InputNumber min={0} style={{ width: '100%' }} />
                        </FormItem>
                        <div className={"hidden"}>
                            <label htmlFor="max_percent_balance" className="mx-2 whitespace-nowrap text-right">To:</label>
                            <Switch
                                style={{ marginRight: "5px", marginBottom: "3px" }}
                                onChange={setShowMaxPercentBalance}
                            />
                            {showMaxPercentBalance && (
                                <FormItem
                                    name="max_percent_balance"
                                    errors={validationFields}
                                    className="flex-grow"
                                    initialValue={editCouponGeneratePreset?.max_percent_balance}
                                >
                                    <InputNumber min={0} style={{ width: '100%' }} />
                                </FormItem>
                            )}
                        </div>
                    </div>
                )}

                {/* Total Price */}
                <div className="flex flex-row items-center justify-center">
                    <label htmlFor="min_total_price" className="mr-2 pb-1 whitespace-nowrap text-right w-48">
                        Total Price
                        <Tooltip title="Set the maximum order price that the generated coupon can be applied to. If set to $20, users cannot apply this coupon to orders exceeding $20. Set to 0 for no limit.">
                            <FontAwesomeIcon icon={faInfoCircle} className="ml-1 text-gray-500" />
                        </Tooltip>
                    </label>
                    <FormItem
                        name="min_total_price"
                        errors={validationFields}
                        className="flex-grow"
                    >
                        <InputNumber min={0} style={{ width: '100%' }} />
                    </FormItem>
                    <div className={"hidden"}>
                        <label htmlFor="max_total_price" className="mx-2 whitespace-nowrap text-right">To:</label>
                        <Switch
                            style={{ marginRight: "5px", marginBottom: "3px" }}
                            onChange={setShowMaxTotalPrice}
                        />
                        {showMaxTotalPrice && (
                            <FormItem
                                name="max_total_price"
                                errors={validationFields}
                                className="flex-grow"
                            >
                                <InputNumber min={0} style={{ width: '100%' }} />
                            </FormItem>
                        )}
                    </div>
                </div>

                {/* Coupon Price */}
                <div className="flex flex-row items-center justify-center">
                    <label htmlFor="min_coupon_price" className="mr-2 pb-1 whitespace-nowrap w-48 text-right">
                        Coupon Price
                        <Tooltip title="Determine the maximum total discount this coupon can provide per order. For instance, if set to $25, the coupon will not discount more than $25 regardless of the order's total.">
                            <FontAwesomeIcon icon={faInfoCircle} className="ml-1 text-gray-500" />
                        </Tooltip>
                    </label>
                    <FormItem
                        name="min_coupon_price"
                        errors={validationFields}
                        className="flex-grow"
                    >
                        <InputNumber min={0} style={{ width: '100%' }} />
                    </FormItem>
                    <div className={"hidden"}>
                        <label htmlFor="max_coupon_price" className="mx-2 whitespace-nowrap text-right">To:</label>
                        <Switch
                            style={{ marginRight: "5px", marginBottom: "3px" }}
                            onChange={setShowMaxCouponPrice}
                        />
                        {showMaxCouponPrice && (
                            <FormItem
                                name="max_coupon_price"
                                errors={validationFields}
                                className="flex-grow"
                            >
                                <InputNumber min={0} style={{ width: '100%' }} />
                            </FormItem>
                        )}
                    </div>
                </div>

                <hr className="mb-2 mt-1" />

                {/* Expiration Type Selector */}
                <div className="flex flex-row items-center justify-start">
                    <label htmlFor="expiration_type" className="mr-2 whitespace-nowrap w-48 text-right">
                        Expiration Type
                        <Tooltip title="Choose how the coupon should expire.">
                            <FontAwesomeIcon icon={faInfoCircle} className="ml-1 text-gray-500" />
                        </Tooltip>
                    </label>
                    <FormItem
                        name="expiration_type"
                        initialValue={useExpirationType}
                    >
                        <Select onChange={handleExpirationTypeChange}>
                            <Option value="hours">Expiration Hours</Option>
                            <Option value="date">Expiration Date</Option>
                        </Select>
                    </FormItem>
                </div>

                {/* Conditional Expiration Fields */}
                {useExpirationType === 'hours' && (
                    <div className="flex flex-row items-center justify-center">
                        <label htmlFor="min_expire_hours" className="mr-2 pb-1 whitespace-nowrap w-48 text-right">
                            Expire Hours
                            <Tooltip title="Specify the number of hours after which the coupon will expire once generated. Set to 0 for the coupon to never expire.">
                                <FontAwesomeIcon icon={faInfoCircle} className="ml-1 text-gray-500" />
                            </Tooltip>
                        </label>
                        <FormItem
                            name="min_expire_hours"
                            errors={validationFields}
                            className="flex-grow"
                        >
                            <InputNumber min={0} style={{ width: '100%' }} />
                        </FormItem>
                    </div>
                )}

                {useExpirationType === 'date' && (
                    <div className="flex flex-row items-center justify-center">
                        <label htmlFor="expiration_date" className="mr-2 pb-1 whitespace-nowrap w-48 text-right">
                            Expiration Date
                            <Tooltip title="Choose the exact date and time when the coupon will expire.">
                                <FontAwesomeIcon icon={faInfoCircle} className="ml-1 text-gray-500" />
                            </Tooltip>
                        </label>
                        <FormItem
                            name="expiration_date"
                            errors={validationFields}
                            className="flex-grow"
                        >
                            <DatePicker showTime style={{ width: '100%' }} />
                        </FormItem>
                    </div>
                )}

                <hr className="mb-2 mt-1" />

                {/* Specific Days Only */}
                <div className="flex flex-row items-center justify-start">
                    <label htmlFor="specific_days_only" className="mr-2 whitespace-nowrap w-48 text-right">
                        Specific Days Only
                        <Tooltip title="Check this box if you want this coupon to be applicable only on specific days of the week.">
                            <FontAwesomeIcon icon={faInfoCircle} className="ml-1 text-gray-500" />
                        </Tooltip>
                    </label>
                    <FormItem
                        name="specific_days_only"
                        valuePropName="checked"
                        className="flex-grow"
                    >
                        <Checkbox onChange={handleDaysSelectionChange}>
                            Applicable on Specific Days
                        </Checkbox>
                    </FormItem>
                </div>

                {specificDaysOnly ? (
                    <div className="flex flex-row items-center justify-start">
                        <label htmlFor="applicable_days" className="mr-2 pt-1 whitespace-nowrap w-64 text-right">
                            Applicable Days
                            <Tooltip title="Select the specific days on which this coupon can be applied.">
                                <FontAwesomeIcon icon={faInfoCircle} className="ml-1 text-gray-500" />
                            </Tooltip>
                        </label>
                        <FormItem
                            name="applicable_days"
                            className="flex-grow"
                        >
                            <Checkbox.Group style={{ width: '100%' }}>
                                <Checkbox value="Monday">Monday</Checkbox>
                                <Checkbox value="Tuesday">Tuesday</Checkbox>
                                <Checkbox value="Wednesday">Wednesday</Checkbox>
                                <Checkbox value="Thursday">Thursday</Checkbox>
                                <Checkbox value="Friday">Friday</Checkbox>
                                <Checkbox value="Saturday">Saturday</Checkbox>
                                <Checkbox value="Sunday">Sunday</Checkbox>
                            </Checkbox.Group>
                        </FormItem>
                    </div>
                ) : null}

                <hr className="mb-2 mt-1" />

                {/* Specific Hours Only */}
                <div className="flex flex-row items-center justify-start">
                    <label htmlFor="specific_hours_only" className="mr-2 whitespace-nowrap w-48 text-right">
                        Specific Hours Only
                        <Tooltip title="Check this box if you want this coupon to be applicable only during certain hours of the day.">
                            <FontAwesomeIcon icon={faInfoCircle} className="ml-1 text-gray-500" />
                        </Tooltip>
                    </label>
                    <FormItem
                        name="specific_hours_only"
                        valuePropName="checked"
                        className="flex-grow"
                    >
                        <Checkbox onChange={handleHoursSelectionChange}>
                            Applicable Only Between Certain Hours
                        </Checkbox>
                    </FormItem>
                </div>

                {/* Hour Range Fields */}
                {specificHoursOnly && (
                    <div className="flex flex-row items-center justify-center mt-2">
                        <label htmlFor="applicable_from_hour" className="mr-2 pt-1 whitespace-nowrap w-48 text-right">
                            From Hour
                            <Tooltip title="Hour of the day (0-23) from which the discount can start being applied.">
                                <FontAwesomeIcon icon={faInfoCircle} className="ml-1 text-gray-500" />
                            </Tooltip>
                        </label>
                        <FormItem
                            name="applicable_from_hour"
                            errors={validationFields}
                            className="mr-4"
                        >
                            <InputNumber min={0} max={23} style={{ width: '100%' }} />
                        </FormItem>

                        <label htmlFor="applicable_to_hour" className="mr-2 pt-1 whitespace-nowrap text-right">
                            To Hour
                            <Tooltip title="Hour of the day (0-23) until which the discount is applied. Must be >= From Hour.">
                                <FontAwesomeIcon icon={faInfoCircle} className="ml-1 text-gray-500" />
                            </Tooltip>
                        </label>
                        <FormItem
                            name="applicable_to_hour"
                            errors={validationFields}
                            className=""
                        >
                            <InputNumber min={0} max={23} style={{ width: '100%' }} />
                        </FormItem>
                    </div>
                )}

                <hr className="mb-2 mt-1" />

                {/* Items Limit */}
                <div className="flex flex-row items-start">
                    <label htmlFor="one_item" className="mr-2 pt-1 whitespace-nowrap w-64 text-right">
                        Items Limit
                        <Tooltip title="Select the limit for items in each group.">
                            <FontAwesomeIcon icon={faInfoCircle} className="ml-1 text-gray-500" />
                        </Tooltip>
                    </label>
                    <FormItem
                        name="one_item"
                        errors={validationFields}
                        initialValue="0"
                        className="w-full"
                    >
                        <Radio.Group defaultValue="0">
                            <Radio value={0}>No limit</Radio>
                            <Radio value={1}>One Item</Radio>
                            <Radio value={2}>Two Items</Radio>
                        </Radio.Group>
                    </FormItem>
                </div>

                {/* Allowed Beers */}
                <div className="flex flex-row items-start">
                    <label htmlFor="beer" className="mr-2 pt-1 whitespace-nowrap w-64 text-right">
                        Allowed Beers
                        <Tooltip title="Specify which beers are allowed for the discount.">
                            <FontAwesomeIcon icon={faInfoCircle} className="ml-1 text-gray-500" />
                        </Tooltip>
                    </label>
                    <FormItem
                        name="beer"
                        errors={validationFields}
                        initialValue="all"
                        className="w-full"
                    >
                        <Radio.Group defaultValue="all">
                            <Radio value="all">All</Radio>
                            <Radio value="none">None</Radio>
                            <Radio value="tap">Tap only</Radio>
                        </Radio.Group>
                    </FormItem>
                </div>

                <hr className="mb-2 mt-1" />

                {/* Times Per Day */}
                <div className="flex flex-row items-start justify-start w-full">
                    <label htmlFor="times_per_day" className="mr-2 pt-1 whitespace-nowrap w-72 text-right">
                        Times Per Day
                        <Tooltip title="How many times you want the user to be able to generate this discount per day. Set to 0 for unlimited usage per day.">
                            <FontAwesomeIcon icon={faInfoCircle} className="ml-1 text-gray-500" />
                        </Tooltip>
                    </label>
                    <FormItem
                        name="times_per_day"
                        errors={validationFields}
                        className=""
                    >
                        <InputNumber min={0} style={{ width: '100%' }} />
                    </FormItem>
                </div>

                {/* Times Per Week */}
                <div className="flex flex-row items-start justify-start w-full">
                    <label htmlFor="times_per_week" className="mr-2 pt-1 whitespace-nowrap w-72 text-right">
                        Times Per Week
                        <Tooltip title="How many times you want the user to be able to generate this discount per week. Set to 0 for unlimited usage per week.">
                            <FontAwesomeIcon icon={faInfoCircle} className="ml-1 text-gray-500" />
                        </Tooltip>
                    </label>
                    <FormItem
                        name="times_per_week"
                        errors={validationFields}
                        className=""
                    >
                        <InputNumber min={0} style={{ width: '100%' }} />
                    </FormItem>
                </div>

                {/* Employment Type */}
                <div className="flex flex-row items-start justify-start w-full">
                    <label htmlFor="employment_type" className="mr-2 pt-1 whitespace-nowrap w-72 text-right">
                        Employment Type
                        <Tooltip title="Select the employment type for the discount.">
                            <FontAwesomeIcon icon={faInfoCircle} className="ml-1 text-gray-500" />
                        </Tooltip>
                    </label>
                    <FormItem
                        name="employment_type"
                        errors={validationFields}
                        rules={[{ required: true, message: 'Please select the employment type' }]}
                        className=""
                    >
                        <Select placeholder="Select Employment Type" className={"min-w-48"}>
                            <Option value="n/a">N/A</Option>
                            <Option value="parttime">Part-time</Option>
                            <Option value="fulltime">Full-time</Option>
                        </Select>
                    </FormItem>
                </div>

                {/* Requires Confirmation */}
                <div className="flex flex-row items-start justify-start w-full">
                    <label htmlFor="requires_confirmation" className="mr-2 pt-1 whitespace-nowrap w-72 text-right">
                        Requires Confirmation
                        <Tooltip title="Enable if the discount requires confirmation.">
                            <FontAwesomeIcon icon={faInfoCircle} className="ml-1 text-gray-500" />
                        </Tooltip>
                    </label>
                    <FormItem
                        name="requires_confirmation"
                        valuePropName="checked"
                        className=""
                    >
                        <Switch
                            defaultChecked={editCouponGeneratePreset?.requires_confirmation ?? false}
                            onChange={(checked) => form.setFieldsValue({ requires_confirmation: checked })}
                        />
                    </FormItem>
                </div>

                {/* Requires Recipient Name */}
                <div className="flex flex-row items-start justify-start w-full">
                    <label htmlFor="requires_recipient_name" className="mr-2 pt-1 whitespace-nowrap w-72 text-right">
                        Requires Recipient Name
                        <Tooltip title="Enable if the discount requires confirmation.">
                            <FontAwesomeIcon icon={faInfoCircle} className="ml-1 text-gray-500" />
                        </Tooltip>
                    </label>
                    <FormItem
                        name="requires_recipient_name"
                        valuePropName="checked"
                        className=""
                    >
                        <Switch
                            defaultChecked={editCouponGeneratePreset?.requires_recipient_name ?? false}
                            onChange={(checked) => form.setFieldsValue({ requires_recipient_name: checked })}
                        />
                    </FormItem>
                </div>

                {/* Redeemable in user's branch only */}
                <div className="flex flex-row items-start border-t border-gray-200 pt-1 mt-1 justify-start w-full">
                    <label htmlFor="redeemable_in_user_branch_only" className="mr-2 pt-1 w-72 text-right">
                        Redeemable in team member's branch only
                        <Tooltip
                            title="Enable if the discount is redeemable only in the team member's branch."
                            placement="topRight"
                        >
                            <FontAwesomeIcon icon={faInfoCircle} className="ml-1 text-gray-500" />
                        </Tooltip>
                    </label>
                    <FormItem
                        name="redeemable_in_user_branch_only"
                        valuePropName="checked"
                        className=""
                    >
                        <Switch
                            defaultChecked={editCouponGeneratePreset?.redeemable_in_user_branch_only ?? false}
                            onChange={(checked) =>
                                form.setFieldsValue({ redeemable_in_user_branch_only: checked })
                            }
                        />
                    </FormItem>
                </div>

                {/* Redeemable on cashier iPad only */}
                <div className="flex flex-row items-start justify-start w-full">
                    <label htmlFor="redeemable_on_cashier_ipad_only" className="mr-2 pt-1 w-72 text-right">
                        Redeemable on cashier's iPad only
                        <Tooltip title="Enable if the discount is redeemable only on the cashier's iPad.">
                            <FontAwesomeIcon icon={faInfoCircle} className="ml-1 text-gray-500" />
                        </Tooltip>
                    </label>
                    <FormItem
                        name="redeemable_on_cashier_ipad_only"
                        valuePropName="checked"
                        className=""
                    >
                        <Switch
                            defaultChecked={editCouponGeneratePreset?.redeemable_on_cashier_ipad_only ?? false}
                            onChange={(checked) =>
                                form.setFieldsValue({ redeemable_on_cashier_ipad_only: checked })
                            }
                        />
                    </FormItem>
                </div>

                {/* Redeemable only in generating account */}
                <div className="flex flex-row items-start border-b pb-1 mb-1 justify-start w-full">
                    <label htmlFor="redeemable_only_in_generating_account" className="mr-2 pt-1 w-72 text-right">
                        Redeemable only in Generating Account
                        <Tooltip title="Enable this option to ensure that only the person who generated the coupon can use it in their own account.">
                            <FontAwesomeIcon icon={faInfoCircle} className="ml-1 text-gray-500" />
                        </Tooltip>
                    </label>
                    <FormItem
                        name="redeemable_only_in_generating_account"
                        valuePropName="checked"
                    >
                        <Switch
                            defaultChecked={editCouponGeneratePreset?.redeemable_only_in_generating_account ?? false}
                            onChange={(checked) =>
                                form.setFieldsValue({ redeemable_only_in_generating_account: checked })
                            }
                        />
                    </FormItem>
                </div>

                {/* Visible in checkout page */}
                <div className="flex flex-row items-start border-b pb-1 mb-1 justify-start w-full">
                    <label htmlFor="visible_in_checkout_page" className="mr-2 pt-1 w-72 text-right">
                        Visible in Checkout Page
                        <Tooltip title="Enable this option to ensure that this preset is going to show up in customer or team member checkout page.">
                            <FontAwesomeIcon icon={faInfoCircle} className="ml-1 text-gray-500" />
                        </Tooltip>
                    </label>
                    <FormItem
                        name="visible_in_checkout_page"
                        valuePropName="checked"
                    >
                        <Switch
                            defaultChecked={editCouponGeneratePreset?.visible_in_checkout_page ?? false}
                            onChange={(checked) =>
                                form.setFieldsValue({ visible_in_checkout_page: checked })
                            }
                        />
                    </FormItem>
                </div>

                {/* Status Dropdown */}
                <StatusDropdown
                    isDiscountRequester={isDiscountRequester}
                    editCouponGeneratePreset={editCouponGeneratePreset}
                    form={form}
                />
            </Form>
        </Modal>
    );
};

export default GeneratePresetModal;
