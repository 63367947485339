import axios from 'axios';
import { checkLoggedIn, getAuthenticationHeaders } from './Headers';

const CutsService = {
    // GET /cuts
    index: (successCb, failCb) => {
        axios.get('/cuts', getAuthenticationHeaders())
            .then(resp => successCb(resp))
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },

    // POST /cuts
    store: (payload, successCb, failCb) => {
        axios.post('/cuts', payload, getAuthenticationHeaders())
            .then(resp => successCb(resp))
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },

    // GET /cuts/history
    // Pass query params (e.g. { page: 2, per_page: 5, start_date: '2025-01-01', end_date: '2025-01-31' })
    getHistory: (params, successCb, failCb) => {
        // We'll merge our auth headers with the provided query params.
        const config = {
            ...getAuthenticationHeaders(),
            params
        };

        axios.get('/cuts/history', config)
            .then(resp => successCb(resp))
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    }
};

export default CutsService;
