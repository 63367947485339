// src/http/OrderService.js

import axios from 'axios';
import {checkLoggedIn, getAuthenticationHeaders} from "./Headers";

const OrderService = {
    getOrderPageData: (branchId, successCb, failCb) => {
        axios.get(`/orders/${branchId}`, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    placeOrder: (orderData, successCb, failCb) => {
        axios.post(`/orders/place-order`, orderData, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    cancelOrder: (orderId, successCb, failCb) => {
        axios.post(`/orders/${orderId}/cancel`, {}, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    getBranchOrders: (branchId, params = {}, successCb, failCb) => {
        axios.get(`/branch/${branchId}/orders`, {
            ...getAuthenticationHeaders(),  // includes your Authorization header, etc.
            params                          // pass the query params
        })
            .then(response => successCb(response))
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },

    updateOrderStatus: (orderId, data, successCb, failCb) => {
        axios.post(`/branch/orders/${orderId}/status`, data, getAuthenticationHeaders())
            .then(response => successCb(response))
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },

    updateOrder: (orderId, data, successCb, failCb) => {
        axios.post(`/branch/orders/${orderId}/update`, data, getAuthenticationHeaders())
            .then(response => successCb(response))
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },

    getVendorOrders: (params = {}, successCb, failCb) => {
        axios
            .get('/vendor/orders', {
                ...getAuthenticationHeaders(),
                params, // <= query params like {from_date, to_date, statuses: ['seen','preparing']}
            })
            .then((response) => successCb(response))
            .catch((err) => {
                checkLoggedIn(err);
                failCb(err);
            });
    },

    updateVendorOrderStatus: (orderId, data, successCb, failCb) => {
        axios.post(`/vendor/orders/${orderId}/status`, data, getAuthenticationHeaders())
            .then(response => successCb(response))
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },

    getVendorOrderDetails: (uuid, successCb, failCb) => {
        axios.get(`/vendor/orders/${uuid}/details`, getAuthenticationHeaders())
            .then(response => successCb(response))
            .catch(err => {
                failCb(err);
            });
    },

    confirmVendorOrder: (uuid, successCb, failCb) => {
        axios.post(`/vendor/orders/${uuid}/confirm`, {}, getAuthenticationHeaders())
            .then(response => successCb(response))
            .catch(err => {
                failCb(err);
            });
    },

};

export default OrderService;
