import axios from 'axios';
import { checkLoggedIn, getAuthenticationHeaders } from './Headers';

const ChecklistService = {
    // e.g. GET /branches/:branchId/checklists
    index: (branchId, params = {}, successCb, failCb) => {
        axios.get(`/branches/${branchId}/checklists`, {
            ...getAuthenticationHeaders(),
            params
        })
            .then(response => successCb(response))
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },

    // e.g. POST /branches/:branchId/checklists
    store: (branchId, data, successCb, failCb) => {
        axios.post(`/branches/${branchId}/checklists`, data, getAuthenticationHeaders())
            .then(response => successCb(response))
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },

    // e.g. GET /branches/:branchId/checklists/:checklistId
    show: (branchId, checklistId, successCb, failCb) => {
        axios.get(`/branches/${branchId}/checklists/${checklistId}`, getAuthenticationHeaders())
            .then(response => successCb(response))
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },

    // e.g. PUT /branches/:branchId/checklists/:checklistId
    update: (branchId, checklistId, data, successCb, failCb) => {
        axios.put(`/branches/${branchId}/checklists/${checklistId}`, data, getAuthenticationHeaders())
            .then(response => successCb(response))
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },

    // e.g. DELETE /branches/:branchId/checklists/:checklistId
    destroy: (branchId, checklistId, successCb, failCb) => {
        axios.delete(`/branches/${branchId}/checklists/${checklistId}`, getAuthenticationHeaders())
            .then(response => successCb(response))
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },

    // e.g. POST /branches/:branchId/checklists/:checklistId/items
    addItem: (branchId, checklistId, data, successCb, failCb) => {
        axios.post(`/branches/${branchId}/checklists/${checklistId}/items`, data, getAuthenticationHeaders())
            .then((response) => successCb(response))
            .catch((err) => {
                checkLoggedIn(err);
                failCb(err);
            });
    },

    // For reordering items
    reorderItems: (branchId, checklistId, payload, successCb, failCb) => {
        // e.g. POST /branches/:branchId/checklists/:checklistId/items/reorder
        // or PATCH, whatever your API is
        axios.post(`/branches/${branchId}/checklists/${checklistId}/items/reorder`, payload, getAuthenticationHeaders())
            .then(response => successCb(response))
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },

    // For updating an existing item
    updateItem: (branchId, checklistId, itemId, data, successCb, failCb) => {
        // e.g. PUT /branches/:branchId/checklists/:checklistId/items/:itemId
        axios.put(`/branches/${branchId}/checklists/${checklistId}/items/${itemId}`, data, getAuthenticationHeaders())
            .then(response => successCb(response))
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },

    // For removing an item
    removeItem: (branchId, checklistId, itemId, successCb, failCb) => {
        axios.delete(`/branches/${branchId}/checklists/${checklistId}/items/${itemId}`, getAuthenticationHeaders())
            .then(response => successCb(response))
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },

    indexChecklists: (successCb, failCb) => {
        axios.get('/teamlead-checklists', getAuthenticationHeaders())
            .then(resp => successCb(resp))
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },

    // get or create today's log
    showLog: (checklistId, successCb, failCb) => {
        axios.get(`/teamlead-checklists/${checklistId}/log`, getAuthenticationHeaders())
            .then(resp => successCb(resp))
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },

    // patch item status to "checked"
    updateLogItem: (checklistId, itemId, data, successCb, failCb) => {
        axios.patch(`/teamlead-checklists/${checklistId}/log/items/${itemId}`, data, getAuthenticationHeaders())
            .then(resp => successCb(resp))
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },

    managerChecklistsReports: (branchId, days = 7, successCb, failCb) => {
        axios.get(`/branches/${branchId}/checklists/logs/reports`, {
            ...getAuthenticationHeaders(),
            params: { days }
        })
            .then(resp => successCb(resp))
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },

};

export default ChecklistService;
