import React, { useState } from 'react';
import { Checkbox, Form, Input, Button, notification, Select } from "antd";
import Employees from "../../../../../http/Employees";
import FormContainer from "../../../../../components/FormContainer";
import FormItem from "../../../../../components/Form/FormItem";
import { useSelector } from "react-redux";

const { Option } = Select;

const EmployeeDepartments = ({ employee, departments, reload, discard }) => {
    const user = useSelector(state => state.auth.user);
    const [form] = Form.useForm();
    const [saving, setSaving] = useState(false);

    const [selectedBranches, setSelectedBranches] = useState(employee.branches.map(branch => branch.id) || []);
    const [selectedDepartments, setSelectedDepartments] = useState(employee.department_roles.map(role => ({
        departmentId: role.department_id,
        roleId: role.id
    })) || []);

    const onSubmit = (values) => {
        setSaving(true);
        const formattedDepartments = selectedDepartments.reduce((acc, { departmentId, roleId }) => {
            const department = acc.find(d => d.id === departmentId);
            if (department) {
                department.roles.push(roleId);
            } else {
                acc.push({ id: departmentId, roles: [roleId] });
            }
            return acc;
        }, []);

        Employees.UpdateDepartments(employee.id, {
            branches: selectedBranches,
            departments: formattedDepartments,
            employment_type: values.employment_type
        }, (response) => {
            notification.success({ message: 'Team member departments updated successfully!' });
            reload();
            setSaving(false);
        }, (err) => {
            notification.error({ message: 'Failed to update team member departments!' });
            setSaving(false);
        });
    };

    const handleBranchChange = (branchId) => {
        setSelectedBranches(prev => {
            if (prev.includes(branchId)) {
                return prev.filter(id => id !== branchId);
            } else {
                return [...prev, branchId];
            }
        });
    };

    const handleDepartmentRoleChange = (departmentId, roleId) => {
        setSelectedDepartments(prev => {
            const index = prev.findIndex(d => d.departmentId === departmentId && d.roleId === roleId);
            if (index !== -1) {
                return prev.filter(d => d.departmentId !== departmentId || d.roleId !== roleId);
            } else {
                return [...prev, { departmentId, roleId }];
            }
        });
    };

    const handleSelectAll = (departmentId) => {
        setSelectedDepartments(prev => {
            const department = departments.find(dept => dept.id === departmentId);
            const allRoles = department.roles.map(role => role.id);
            const updatedDepartments = prev.filter(d => d.departmentId !== departmentId);
            allRoles.forEach(roleId => updatedDepartments.push({ departmentId, roleId }));
            return updatedDepartments;
        });
    };

    const handleDeselectAll = (departmentId) => {
        setSelectedDepartments(prev => prev.filter(d => d.departmentId !== departmentId));
    };

    return (
        <div>
            <Form
                form={form}
                layout="vertical"
                onFinish={onSubmit}
                initialValues={{
                    status: true,
                    employment_type: employee.employment_type
                }}
            >

                <FormContainer>
                    <div className="">
                        <h2 className="text-xl uppercase font-bold">Branches</h2>
                        <ul>
                            {user.branches && user.branches.map(branch => (
                                <li key={branch.id}>
                                    <Checkbox
                                        checked={selectedBranches.includes(branch.id)}
                                        onChange={() => handleBranchChange(branch.id)}
                                    >
                                        {branch.name}
                                    </Checkbox>
                                </li>
                            ))}
                        </ul>
                    </div>
                </FormContainer>
                <FormContainer>
                    <div className="">
                        <h2 className="text-xl uppercase font-bold">Departments</h2>
                        <ul>
                            {departments.map(department => (
                                <li key={department.id}>
                                    <div className="font-bold mt-4 mb-0">
                                        {department.name}
                                        {department.roles && department.roles.length > 0 &&
                                            <>
                                                <Button type="link" onClick={() => handleSelectAll(department.id)} className={"uppercase"} style={{ fontSize: "10px", marginRight: "0px", paddingRight: "10px" }}>ALL</Button>
                                                <Button type="link" onClick={() => handleDeselectAll(department.id)} className={"uppercase"} style={{ fontSize: "10px", marginLeft: "0px", paddingLeft: "0px" }}>NONE</Button>
                                            </>
                                        }
                                    </div>
                                    {department.roles && department.roles.length > 0 ?
                                        <ul className="ml-4">
                                            {department.roles.map(role => (
                                                <li key={role.id}>
                                                    <Checkbox
                                                        checked={selectedDepartments.some(d => d.departmentId === department.id && d.roleId === role.id)}
                                                        onChange={() => handleDepartmentRoleChange(department.id, role.id)}
                                                    >
                                                        {role.name}
                                                    </Checkbox>
                                                </li>
                                            ))}
                                        </ul>
                                        :
                                        <div className={"text-xs text-gray-400 ml-4 pt-1"}>No Roles</div>
                                    }
                                </li>
                            ))}
                        </ul>
                    </div>
                </FormContainer>
                <FormContainer>
                    <Form.Item
                        name="employment_type"
                        label="Employment Type"
                        rules={[{ required: true, message: 'Please select the employment type' }]}
                    >
                        <Select>
                            <Option value="parttime">Part-time</Option>
                            <Option value="fulltime">Full-time</Option>
                        </Select>
                    </Form.Item>
                </FormContainer>
                <div className="w-full flex items-center">
                    <FormItem>
                        <Button type="primary" htmlType="submit" loading={saving} style={{ paddingLeft: "40px", paddingRight: "40px" }}>
                            Save
                        </Button>
                    </FormItem>

                    <FormItem style={{ marginLeft: "10px" }}>
                        <Button type="link" htmlType="button" disabled={saving} danger onClick={discard}>
                            Discard Changes
                        </Button>
                    </FormItem>
                </div>
            </Form>
        </div>
    );
};

export default EmployeeDepartments;
