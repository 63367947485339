import React, {useContext, useEffect, useState} from 'react';
import {motion} from "framer-motion";
import T from "../../../../components/T";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRightLong, faCheck, faExclamationTriangle, faExpand, faThermometer1} from "@fortawesome/free-solid-svg-icons";
import {notification} from "antd";
import KPI from "../../../../http/KPI";
import GenericSkeleton from "../../../../components/skeletons/GenericSkeleton";
import SkeletonWrapper from "../../../../components/skeletons/SkeletonWrapper";
import GaugeChart from 'react-responsive-gauge-chart'

let isKpiRunning = false;

const DashboardIndex = () => {

    const [fullscreen, setFullscreen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [KPIs, setKPIs] = useState(null);

    const reloadKPI = (force = false) => {
        setLoading(true);
        KPI.Index((response) => {
            setLoading(false);
            console.log("settings:", response);
            setKPIs(response.data.data);
            if (isKpiRunning || force) {
                setTimeout(reloadKPI, 5 * 60 * 1000);
            } else {
                console.log("reloadKPI didn't called for later!!");
            }
        }, (err) => {
            notification['error']({
                message: 'Error!',
                description: err.response?.data.message,
            });
            setLoading(false);
            setTimeout(reloadKPI, 5 * 60 * 1000);
        });
    }

    useEffect(() => {
        isKpiRunning = true;
        reloadKPI(true);

        return () => {
            isKpiRunning = false;
        }
    }, []);

    const handleFullscreen = () => {
        setFullscreen(!fullscreen);
    }

    return (
        <motion.div
            initial={{opacity: 0, scale: 1.1, y: 0}}
            animate={{opacity: 1, scale: 1, y: 0}}
            className={`w-full ${fullscreen ? 'fullscreen' : null}`}
            key={'kpi page'}
        >
            <div className="flex flex-col mx-auto w-full">
                {!fullscreen ?
                    <div className="text-xl font-bold text-black pl-1 flex flex-row mb-2 justify-between">
                        <T upper>Patty Slaps Performance</T>
                        <div>
                            <FontAwesomeIcon icon={faExpand} aria-hidden="true" className={"mr-2 cursor-pointer"} onClick={handleFullscreen}/>
                        </div>
                    </div>
                    :
                    <div className="text-lg font-bold text-black p-2 px-4 flex flex-row mb-2 bg-red-brand justify-between items-center">
                        <div className={"flex flex-row items-center uppercase"}>
                            <img src={process.env.PUBLIC_URL + '/images/logo-small.png'} className="h-10 mr-2"/>
                            <FontAwesomeIcon icon={faArrowRightLong} aria-hidden="true" className={"mr-1"}/>

                            <div className="px-1 py-0.5 text-base text-white text-with-border">
                                Performance
                            </div>
                        </div>
                        <div>
                            <FontAwesomeIcon icon={faExpand} aria-hidden="true" className={"mr cursor-pointer"} onClick={handleFullscreen}/>
                        </div>
                    </div>
                }
                <SkeletonWrapper loading={loading} skeleton={GenericSkeleton}>
                    <div className="w-full flex flex-col">

                        {KPIs &&
                            <div className="rounded-2xl shadow-xl  flex flex-col items-center justify-center  border border-black mb-3">
                                <div className="flex flex-col w-full items-center">
                                    <div className="text-2xl font-bold uppercase bg-black text-white w-full py-2 rounded-t-2xl text-center">
                                        Preparation Score
                                    </div>
                                    {KPIs.preparation_score ?
                                        <div className="flex flex-row justify-between p-2 ">
                                            <div className="flex items-center justify-center flex-1 ">
                                                {/*
                                                <ReactSpeedometer
                                                    maxValue={5}
                                                    ringWidth={20}
                                                    value={KPIs.preparation_score.score}
                                                    valueFormat={"d"}
                                                    segmentColors={[
                                                        "#9f0000",
                                                        "#9f0000",
                                                        "#ffee00",
                                                        "#219600",
                                                        "#3e6e00"
                                                    ]}
                                                    needleColor={"black"}
                                                    currentValueText={"${value} Star"}
                                                    textColor={"black"}
                                                    width={220}
                                                    height={135}
                                                />
                                                */}
                                                <GaugeChart id="gauge-chart2"
                                                            percent={KPIs.preparation_score.score*20/100}
                                                            nrOfLevels={10}
                                                            textColor={"#000"}
                                                            colors={[
                                                                "#9f0000",
                                                                "#ffee00",
                                                                "#2ac200",
                                                                "#81de00"
                                                            ]}
                                                />
                                            </div>
                                            <div className="flex flex-col items-center justify-center flex-1 text-base">
                                                <div className="font-bold flex flex-row items-center py-0.5">
                                                    Average Preparation Duration: {KPIs.preparation_score.duration.toFixed(2)}
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div className="py-5">
                                            No data available!
                                        </div>
                                    }
                                </div>
                            </div>
                        }


                        {KPIs &&
                            <div className="rounded-2xl shadow-xl  flex flex-col items-center justify-center  border border-black mb-3">
                                <div className="flex flex-col w-full items-center">
                                    <div className="text-2xl font-bold uppercase bg-black text-white w-full py-2 rounded-t-2xl text-center">
                                        Uber Score
                                    </div>
                                    {KPIs.uber_score ?
                                        <div className="flex flex-row justify-between w-full p-2 ">
                                            <div className="flex items-center justify-center flex-1 ">
                                                {/*
                                                <ReactSpeedometer
                                                    maxValue={5}
                                                    value={KPIs.uber_score.mean}
                                                    valueFormat={"d"}
                                                    segmentColors={["#dc4453", "#d08770", "#ebcb8b", "#a3be8c"]}
                                                    needleColor={"black"}
                                                    currentValueText={"${value} Star"}
                                                    textColor={"black"}
                                                    width={220}
                                                    height={135}
                                                />
                                                */}
                                                <GaugeChart id="gauge-chart2"
                                                            percent={KPIs.uber_score.mean*20/100}
                                                            nrOfLevels={10}
                                                            textColor={"#000"}
                                                            colors={[
                                                                "#9f0000",
                                                                "#ffee00",
                                                                "#2ac200",
                                                                "#81de00"
                                                            ]}
                                                />
                                            </div>
                                            <div className="flex flex-col items-center justify-center flex-1 text-base">
                                                <div className="font-bold flex flex-row items-center py-0.5">
                                                    1 Star Reviews: {KPIs.uber_score.ones}
                                                    {KPIs.uber_score.ones == 0 ?
                                                        <FontAwesomeIcon icon={faCheck} className="ml-2 text-lg text-green-600" aria-hidden="true"/>
                                                        :
                                                        <FontAwesomeIcon icon={faExclamationTriangle} className="ml-2 text-lg text-red-600" aria-hidden="true"/>
                                                    }
                                                </div>

                                                <div className="font-bold flex flex-row items-center py-0.5">
                                                    2 Star Reviews: {KPIs.uber_score.twos}
                                                    {KPIs.uber_score.twos == 0 ?
                                                        <FontAwesomeIcon icon={faCheck} className="ml-2 text-lg text-green-600" aria-hidden="true"/>
                                                        :
                                                        <FontAwesomeIcon icon={faExclamationTriangle} className="ml-2 text-lg text-red-600" aria-hidden="true"/>
                                                    }
                                                </div>

                                                <div className="font-bold flex flex-row items-center py-0.5">
                                                    3 Star Reviews: {KPIs.uber_score.threes}
                                                    {KPIs.uber_score.threes < 3 ?
                                                        <FontAwesomeIcon icon={faCheck} className="ml-2 text-lg text-green-600" aria-hidden="true"/>
                                                        :
                                                        <FontAwesomeIcon icon={faExclamationTriangle} className="ml-2 text-lg text-red-600" aria-hidden="true"/>
                                                    }
                                                </div>

                                                <div className="font-bold flex flex-row items-center py-0.5 font-bold">
                                                    Total Reviews: {KPIs.uber_score.total}
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div className="py-5">
                                            No data available!
                                        </div>
                                    }
                                </div>

                            </div>
                        }


                        {KPIs &&
                            <div className="rounded-2xl shadow-xl  flex flex-col items-center justify-center  border border-black mb-3">
                                <div className="flex flex-col w-full items-center">
                                    <div className="text-2xl font-bold uppercase bg-black text-white w-full py-2 rounded-t-2xl text-center">
                                        Google Score
                                    </div>
                                    {KPIs.google_score ?
                                        <div className="flex flex-row justify-between w-full p-2 ">
                                            <div className="flex items-center justify-center flex-1 ">
                                                {/*
                                                <ReactSpeedometer
                                                    maxValue={5}
                                                    value={KPIs.google_score.mean}
                                                    valueFormat={"d"}
                                                    segmentColors={["#dc4453", "#d08770", "#ebcb8b", "#a3be8c"]}
                                                    needleColor={"black"}
                                                    currentValueText={"${value} Star"}
                                                    textColor={"black"}
                                                    width={220}
                                                    height={135}
                                                />
                                                */}
                                                <GaugeChart id="gauge-chart2"
                                                            percent={KPIs.google_score.mean*20/100}
                                                            nrOfLevels={10}
                                                            textColor={"#000"}
                                                            colors={[
                                                                "#9f0000",
                                                                "#ffee00",
                                                                "#2ac200",
                                                                "#81de00"
                                                            ]}
                                                />
                                            </div>
                                            <div className="flex flex-col items-center justify-center flex-1 text-base">
                                                <div className="font-bold flex flex-row items-center py-0.5">
                                                    1 Star Reviews: {KPIs.google_score.ones}
                                                    {KPIs.google_score.ones == 0 ?
                                                        <FontAwesomeIcon icon={faCheck} className="ml-2 text-lg text-green-600" aria-hidden="true"/>
                                                        :
                                                        <FontAwesomeIcon icon={faExclamationTriangle} className="ml-2 text-lg text-red-600" aria-hidden="true"/>
                                                    }
                                                </div>

                                                <div className="font-bold flex flex-row items-center py-0.5">
                                                    2 Star Reviews: {KPIs.google_score.twos}
                                                    {KPIs.google_score.twos == 0 ?
                                                        <FontAwesomeIcon icon={faCheck} className="ml-2 text-lg text-green-600" aria-hidden="true"/>
                                                        :
                                                        <FontAwesomeIcon icon={faExclamationTriangle} className="ml-2 text-lg text-red-600" aria-hidden="true"/>
                                                    }
                                                </div>

                                                <div className="font-bold flex flex-row items-center py-0.5">
                                                    3 Star Reviews: {KPIs.google_score.threes}
                                                    {KPIs.google_score.threes < 3 ?
                                                        <FontAwesomeIcon icon={faCheck} className="ml-2 text-lg text-green-600" aria-hidden="true"/>
                                                        :
                                                        <FontAwesomeIcon icon={faExclamationTriangle} className="ml-2 text-lg text-red-600" aria-hidden="true"/>
                                                    }
                                                </div>

                                                <div className="font-bold flex flex-row items-center py-0.5 font-bold">
                                                    Total Reviews: {KPIs.google_score.total}
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div className="py-5">
                                            No data available!
                                        </div>
                                    }
                                </div>

                            </div>
                        }

                        {KPIs &&
                            <div className="rounded-2xl shadow-xl  flex flex-col items-center justify-center  border border-black mb-3">
                                <div className="flex flex-col w-full items-center">
                                    <div className="text-2xl font-bold uppercase bg-black text-white w-full py-2 rounded-t-2xl text-center">
                                        Survey Score
                                    </div>
                                    {KPIs.survey_score ?
                                        <div className="flex flex-row justify-between w-full p-2 ">
                                            <div className="flex items-center justify-center flex-1 ">
                                                {/*
                                                <ReactSpeedometer
                                                    maxValue={5}
                                                    value={KPIs.survey_score.mean}
                                                    valueFormat={"d"}
                                                    segmentColors={["#dc4453", "#d08770", "#ebcb8b", "#a3be8c"]}
                                                    needleColor={"black"}
                                                    currentValueText={"${value} Star"}
                                                    textColor={"black"}
                                                    width={220}
                                                    height={135}
                                                />
                                                */}
                                                <GaugeChart id="gauge-chart2"
                                                            percent={KPIs.survey_score.mean*20/100}
                                                            nrOfLevels={10}
                                                            textColor={"#000"}
                                                            colors={[
                                                                "#9f0000",
                                                                "#ffee00",
                                                                "#2ac200",
                                                                "#81de00"
                                                            ]}
                                                />
                                            </div>
                                            <div className="flex flex-col items-center justify-center flex-1 text-base">
                                                <div className="font-bold flex flex-row items-center py-0.5">
                                                    1 Star Reviews: {KPIs.survey_score.ones}
                                                    {KPIs.survey_score.ones == 0 ?
                                                        <FontAwesomeIcon icon={faCheck} className="ml-2 text-lg text-green-600" aria-hidden="true"/>
                                                        :
                                                        <FontAwesomeIcon icon={faExclamationTriangle} className="ml-2 text-lg text-red-600" aria-hidden="true"/>
                                                    }
                                                </div>

                                                <div className="font-bold flex flex-row items-center py-0.5">
                                                    2 Star Reviews: {KPIs.survey_score.twos}
                                                    {KPIs.survey_score.twos == 0 ?
                                                        <FontAwesomeIcon icon={faCheck} className="ml-2 text-lg text-green-600" aria-hidden="true"/>
                                                        :
                                                        <FontAwesomeIcon icon={faExclamationTriangle} className="ml-2 text-lg text-red-600" aria-hidden="true"/>
                                                    }
                                                </div>

                                                <div className="font-bold flex flex-row items-center py-0.5">
                                                    3 Star Reviews: {KPIs.survey_score.threes}
                                                    {KPIs.survey_score.threes < 3 ?
                                                        <FontAwesomeIcon icon={faCheck} className="ml-2 text-lg text-green-600" aria-hidden="true"/>
                                                        :
                                                        <FontAwesomeIcon icon={faExclamationTriangle} className="ml-2 text-lg text-red-600" aria-hidden="true"/>
                                                    }
                                                </div>

                                                <div className="font-bold flex flex-row items-center py-0.5 font-bold">
                                                    Total Reviews: {KPIs.survey_score.total}
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div className="py-5">
                                            No data available!
                                        </div>
                                    }
                                </div>

                            </div>
                        }

                    </div>
                </SkeletonWrapper>
            </div>
        </motion.div>

    );
};
export default DashboardIndex;
