import React, { useEffect, useState } from 'react';
import { Checkbox, Form, Input, Button, notification, DatePicker, Select } from "antd";
import Employees from "../../../../../http/Employees";
import FormContainer from "../../../../../components/FormContainer";
import FormItem from "../../../../../components/Form/FormItem";
import moment from "moment";
import { faCheckCircle, faWarning } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const { Option } = Select;

const EmployeeInformation = ({ employee, reload, discard, onEmployeeCreated }) => {
    const [form] = Form.useForm();
    const [validationFields, setValidationFields] = useState(null);
    const [saving, setSaving] = useState(false);
    const [isEdit, setIsEdit] = useState(!!employee);

    // For sending extra requests
    const [sendingPasswordReset, setSendingPasswordReset] = useState(false);
    const [sendingInvitation, setSendingInvitation] = useState(false);
    const [sendingVerification, setSendingVerification] = useState(false);

    // On mount or when `employee` changes, initialize form
    useEffect(() => {
        if (employee) {
            setIsEdit(true);
            form.setFieldsValue({
                ...employee,
                birthday: employee.birthday ? moment(employee.birthday) : null,
                status: employee.status === 1,
                // invitation_template won't matter for an existing employee by default,
                // but we can let them choose if they want to re-invite with a specific template.
                invitation_template: 'default',
            });
        } else {
            setIsEdit(false);
            form.resetFields();
            // For a new user, default the invitation template to "default"
            form.setFieldsValue({ invitation_template: 'default', status: true });
        }
    }, [employee, form]);

    // Handle form submission
    const onSubmit = () => {
        form.validateFields().then(values => {
            const payload = {
                ...values,
                birthday: values.birthday ? values.birthday.format('YYYY-MM-DD') : null,
                status: values.status ? 1 : -1
            };

            onSave(payload, isEdit);
        }).catch(info => {
            console.log('Validate Failed:', info);
        });
    };

    // Actually store/update the user
    const onSave = (values, isEdit) => {
        setSaving(true);

        if (isEdit) {
            // Updating existing employee
            Employees.UpdateInformation(
                employee.id,
                values,
                (response) => {
                    reload();
                    const user = response.data.data.employee;
                    notification.success({
                        message: 'Success!',
                        placement: "bottomRight",
                        description: `Employee ${user.email} has been updated!`,
                    });
                    setSaving(false);
                    setValidationFields(null);
                },
                (err) => {
                    setValidationFields(err.response?.data?.errors || null);
                    setSaving(false);
                }
            );
        } else {
            // Creating a new employee => then we'll auto-send invitation
            Employees.Store(
                values,
                (response) => {
                    const newEmployee = response.data.data.employee;
                    const newEmployeeId = newEmployee.id;

                    notification.success({
                        message: 'Success!',
                        placement: "bottomRight",
                        description: `Employee ${newEmployee.email} has been created!`,
                    });

                    setSaving(false);
                    setValidationFields(null);
                    form.resetFields();

                    // If the parent wants the ID, pass it up
                    if (onEmployeeCreated) {
                        onEmployeeCreated(newEmployeeId);
                    }

                    // Optionally reload the list of employees
                    reload();
                },
                (err) => {
                    setValidationFields(err.response?.data?.errors || null);
                    setSaving(false);
                }
            );
        }
    };

    // Private function to handle sending an invitation with the chosen template
    const sendInvitationInternal = (empId, templateSlug) => {
        setSendingInvitation(true);
        Employees.SendInvitation(
            empId,
            { template: templateSlug }, // pass the template in request body or params
            (response) => {
                notification.success({
                    message: 'Success!',
                    placement: "bottomRight",
                    description: response.data?.message
                });
                setSendingInvitation(false);
            },
            (err) => {
                notification.error({
                    message: 'Error!',
                    placement: "bottomRight",
                    description: err.response?.data?.message
                });
                setSendingInvitation(false);
            }
        );
    };

    // For existing employee: user can manually click "Send Invitation" again
    const sendInvitation = () => {
        if (!employee) return;
        const templateSlug = form.getFieldValue('invitation_template') || 'default';
        sendInvitationInternal(employee.id, templateSlug);
    };

    const sendPasswordReset = () => {
        setSendingPasswordReset(true);
        Employees.SendPasswordReset(
            employee.id,
            (response) => {
                notification.success({
                    message: 'Success!',
                    placement: "bottomRight",
                    description: response.data.message
                });
                setSendingPasswordReset(false);
            },
            (err) => {
                notification.error({
                    message: 'Error!',
                    placement: "bottomRight",
                    description: err.response?.data?.message
                });
                setSendingPasswordReset(false);
            }
        );
    };

    const sendVerification = () => {
        setSendingVerification(true);
        Employees.SendVerification(
            employee.id,
            (response) => {
                notification.success({
                    message: 'Success!',
                    placement: "bottomRight",
                    description: response.data.message
                });
                setSendingVerification(false);
            },
            (err) => {
                notification.error({
                    message: 'Error!',
                    placement: "bottomRight",
                    description: err.response?.data?.message
                });
                setSendingVerification(false);
            }
        );
    };

    const formatDate = (dateString) => {
        return moment(dateString).format('MMMM Do YYYY, h:mm:ss a');
    };

    return (
        <div>
            <Form
                form={form}
                layout="vertical"
                onFinish={onSubmit}
                initialValues={{
                    status: true,
                    invitation_template: 'default',
                }}
            >
                <FormContainer>
                    <FormItem
                        name="name"
                        label="Full Name"
                        rules={[{ required: true, message: 'Please input the full name!' }]}
                        errors={validationFields}
                        disabled={saving}
                    >
                        <Input />
                    </FormItem>

                    <FormItem
                        name="email"
                        label="Email"
                        rules={[{ required: true, message: 'Please input the email!' }]}
                        errors={validationFields}
                    >
                        {/* If editing, we disable email (unchangeable) */}
                        <Input type="email" disabled={!!employee} />
                    </FormItem>

                    <FormItem
                        name="birthday"
                        label="Birthday"
                        errors={validationFields}
                        disabled={saving}
                    >
                        <DatePicker format="YYYY-MM-DD" />
                    </FormItem>
                </FormContainer>

                {/* If user doesn't exist => we show password as required, else optional */}
                {employee && (
                    <>
                        <FormContainer>
                            <FormItem
                                name="password"
                                label="Password"
                                rules={[{ required: false }]} // for existing user, password is optional
                                errors={validationFields}
                                disabled={saving}
                            >
                                <Input.Password visibilityToggle={true} autoComplete="new-password" />
                            </FormItem>

                            <div className="flex flex-col w-full justify-end lg:flex-row">
                                {/* Manager wants a template dropdown (somewhere), let's put it outside?
                                    We can also put it next to "Send Invitation" button. */}
                                <FormItem style={{ marginRight: "12px" }}>
                                    <Button
                                        type="primary"
                                        loading={sendingInvitation}
                                        onClick={sendInvitation}
                                    >
                                        Send Invitation
                                    </Button>
                                </FormItem>

                                <FormItem>
                                    <Button
                                        type="primary"
                                        loading={sendingPasswordReset}
                                        onClick={sendPasswordReset}
                                    >
                                        Send Password Reset
                                    </Button>
                                </FormItem>
                            </div>
                        </FormContainer>

                        <FormContainer>
                            <div>
                                {employee.email_verified_at ? (
                                    <div className="text-green-700 pb-3 flex flex-row items-center">
                                        <FontAwesomeIcon
                                            icon={faCheckCircle}
                                            className="text-green-800 mr-2 text-lg"
                                        />
                                        Email has been verified at {formatDate(employee.email_verified_at)}
                                    </div>
                                ) : (
                                    <div className="font-bold text-red-800">
                                        <FontAwesomeIcon
                                            icon={faWarning}
                                            className=" text-red-800 mr-2 text-base"
                                        />
                                        Email is not verified.
                                    </div>
                                )}
                            </div>

                            {!employee.email_verified_at && (
                                <div className="flex flex-row w-full justify-end">
                                    <FormItem>
                                        <Button
                                            type="primary"
                                            loading={sendingVerification}
                                            onClick={sendVerification}
                                        >
                                            Resend Email Verification
                                        </Button>
                                    </FormItem>
                                </div>
                            )}
                        </FormContainer>

                        <FormContainer>
                            <FormItem
                                name="pin"
                                label="PIN"
                                errors={validationFields}
                                disabled={saving}
                            >
                                <Input.Password visibilityToggle={true} />
                            </FormItem>
                            <FormItem name="status" label="Status" valuePropName="checked">
                                <Checkbox>Enabled</Checkbox>
                            </FormItem>
                        </FormContainer>
                    </>
                )}

                {/* Show invitation template selection for BOTH new and existing user */}
                {!employee &&
                    <FormContainer>
                        <FormItem name="invitation_template" label="Invitation Template">
                            <Select disabled={saving}>
                                <Option value="default">Default</Option>
                                <Option value="invitation-pr">Invitation PR</Option>
                                <Option value="invitation-dashboard">Invitation Dashboard</Option>
                            </Select>
                        </FormItem>
                    </FormContainer>
                }

                <div className="flex flex-row p-1">
                    <FormItem>
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={saving}
                            style={{ paddingLeft: "40px", paddingRight: "40px" }}
                        >
                            {isEdit ? 'Save' : 'Invite'}
                        </Button>
                    </FormItem>

                    <FormItem style={{ marginLeft: "10px" }}>
                        <Button
                            type="link"
                            htmlType="button"
                            disabled={saving}
                            danger
                            onClick={discard}
                        >
                            Discard Changes
                        </Button>
                    </FormItem>
                </div>
            </Form>
        </div>
    );
};

export default EmployeeInformation;
