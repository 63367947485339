import React, { useEffect, useState } from 'react';
import { Table, notification, Switch, Spin } from 'antd';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import ChecklistService from '../../../../http/ChecklistService';
import SkeletonWrapper from '../../../../components/skeletons/SkeletonWrapper';
import GenericSkeleton from '../../../../components/skeletons/GenericSkeleton';
import DefaultContainer from '../../../../components/DefaultContainer';

const REFRESH_INTERVAL = 30000; // 30 seconds

const TeamLeadChecklistLog = () => {
    const { checklistId } = useParams();
    const [loading, setLoading] = useState(true);
    const [checklistLog, setChecklistLog] = useState(null);
    const [updatingItemId, setUpdatingItemId] = useState(null); // track which item is currently updating

    useEffect(() => {
        fetchLog();
        const interval = setInterval(fetchLog, REFRESH_INTERVAL);
        return () => clearInterval(interval);
    }, [checklistId]);

    const fetchLog = () => {
        setLoading(true);
        ChecklistService.showLog(
            checklistId,
            (resp) => {
                setLoading(false);
                setChecklistLog(resp.data.data); // e.g. {id, working_day, log_items: [...]}
            },
            (err) => {
                setLoading(false);
                notification.error({
                    message: 'Error',
                    description: 'Failed to load checklist log.'
                });
            }
        );
    };

    const handleToggle = (record) => {
        // If it's already checked, do nothing (read-only)
        if (record.status === 'checked') return;

        setUpdatingItemId(record.id); // show spinner for this row
        ChecklistService.updateLogItem(
            checklistId,
            record.checklist_item_id,
            { status: 'checked' },
            (resp) => {
                setUpdatingItemId(null);
                notification.success({
                    message: 'Success',
                    description: 'Item updated'
                });
                // The response likely has the updated item with checked_by, checked_at
                const updatedItem = resp.data.data;
                // We'll patch the local state
                if (!checklistLog) return;

                const newLogItems = checklistLog.log_items.map((item) => {
                    if (item.id === updatedItem.id) {
                        return {
                            ...item,
                            ...updatedItem // merge in updated fields (status, checked_by, checked_at, etc.)
                        };
                    }
                    return item;
                });

                setChecklistLog({
                    ...checklistLog,
                    log_items: newLogItems
                });
            },
            (err) => {
                setUpdatingItemId(null);
                notification.error({
                    message: 'Error',
                    description: 'Failed to update item.'
                });
            }
        );
    };

    if (!checklistLog) {
        return (
            <DefaultContainer>
                <SkeletonWrapper loading={loading} skeleton={GenericSkeleton}>
                    <p>No data yet...</p>
                </SkeletonWrapper>
            </DefaultContainer>
        );
    }

    const columns = [
        {
            title: 'Item Title',
            dataIndex: ['item', 'title'],
            key: 'title'
        },
        {
            title: 'Checked By',
            key: 'checked_by',
            render: (text, record) => {
                if (record.status === 'checked' && record.checker) {
                    return record.checker.name;
                    // or `checker.full_name`, depending on your user model
                }
                return '--';
            }
        },
        {
            title: 'Checked At',
            key: 'checked_at',
            render: (text, record) => {
                if (record.status === 'checked' && record.checked_at) {
                    // format time only, e.g. "3:09 PM"
                    return moment(record.checked_at).format('h:mm A');
                }
                return '--';
            }
        },
        {
            title: 'Checked?',
            key: 'status',
            render: (text, record) => {
                // if item is 'checked', disable switch
                const disabled = record.status === 'checked';
                // if we're updating this item right now, show a spinner
                const isUpdating = updatingItemId === record.id;

                return (
                    <div style={{ minWidth: '60px' }}>
                        {isUpdating ? (
                            <Spin />
                        ) : (
                            <Switch
                                checked={record.status === 'checked'}
                                disabled={disabled}
                                onChange={() => handleToggle(record)}
                            />
                        )}
                    </div>
                );
            }
        }
    ];

    return (
        <DefaultContainer>
            <h2 className="text-xl font-bold uppercase mb-2">CHECKLIST LOG</h2>
            <p className="mb-4">
                <strong>Working Day:</strong> {checklistLog.working_day}
            </p>

            <SkeletonWrapper loading={loading} skeleton={GenericSkeleton}>
                <Table
                    columns={columns}
                    dataSource={checklistLog.log_items}
                    rowKey="id"
                    pagination={false}
                    locale={{ emptyText: 'No items' }}
                />
            </SkeletonWrapper>
        </DefaultContainer>
    );
};

export default TeamLeadChecklistLog;
