import React, {useEffect, useState} from 'react';
import {HomeOutlined, MenuFoldOutlined, MenuUnfoldOutlined} from "@ant-design/icons";
import Can from "../../../layouts/Can";
import {Link, useLocation} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Badge, Menu} from 'antd';
import {
    RiUser3Line,
    RiUserHeartLine,
    RiUserAddLine,
    RiTimeLine,
    RiUserLine,
    RiSettings3Line,
    RiBarChartLine,
    RiOrganizationChart,
    RiFolderOpenLine,
    RiCheckboxLine,
    RiTimelineView,
    RiDashboardLine,
    RiBuilding3Line,
    RiShoppingBag3Line,
    RiCheckboxCircleLine,
    RiVipCrownLine,
    RiBookOpenLine,
    RiChat3Line,
    RiBuilding2Line,
    RiFolderLine,
    RiCheckboxBlankCircleLine,
    RiVipLine,
    RiBarChart2Line,
    RiLayoutGridLine,
    RiSettings2Line,
    RiPieChartLine,
    RiMapPinLine,
    RiEdit2Line,
    RiSurveyLine,
    RiFileTextLine,
    RiFileAddLine,
    RiFileListLine,
    RiTruckLine,
    RiCalendarLine,
    RiShoppingCartLine,
    RiShoppingBasketLine,
    RiBillLine,
    RiPercentLine,
    RiMapLine,
    RiTeamLine,
    RiCouponLine,
    RiSuitcaseLine,
    RiFileSearchLine,
    RiCheckLine,
    RiTerminalLine,
    RiBankCardLine,
    RiBox1Fill,
    RiBox1Line,
    RiStackLine,
    RiBox3Line,
    RiBox2Line,
    RiCalendar2Line,
    RiCalendarCloseLine,
    RiNotification2Line,
    RiStickyNote2Line,
    RiMailLine,
    RiHistoryLine,
    RiShieldLine,
    RiDeleteBinLine,
    RiStore3Line,
    RiFolderSettingsLine,
    RiRestaurant2Line,
    RiSecurePaymentLine,
    RiHourglass2Line,
    RiCalendarScheduleLine,
    RiMoneyCnyBoxLine,
    RiCheckboxIndeterminateLine,
    RiCheckboxBlankLine,
    RiKnifeBloodFill,
    RiKnifeBloodLine, RiKnifeLine
} from '@remixicon/react';

import {} from '@remixicon/react';

import {useDispatch, useSelector} from "react-redux";
import {AnimatePresence, motion} from "framer-motion";
import usePermission from "../../../layouts/usePermission";
import {HideDashboardMenu} from "../../../redux/settings/actionCreator";


const DashBoardMenu = ({collapsed, setCollapsed}) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const [openKeys, setOpenKeys] = useState([]);
    const [desktopMode, setDesktopMode] = useState(window.innerWidth >= 768); // 768px is the md breakpoint in Tailwind
    const dashboardMenuVisible = useSelector(state => state.settings.dashboard_menu_visible);

    // Permissions
    const canManageBloggers = usePermission(["manage_bloggers"]);
    const canManageKPI = usePermission(["manage_kpi"]);
    const isHQ = usePermission(null, ["hq"]);
    const isIraniManager = usePermission(null, ["irani_manager"]);
    const isReviewManager = usePermission(null, ["review_manager"]);
    const isBranchManager = usePermission(null, ["branch_manager"]);
    const isSupervisor = usePermission(null, ["branch_supervisor"]);
    const isDepartmentManager = usePermission(null, ["department_manager"]);
    const isBranchEmployeeManager = usePermission(null, ["branch_employee_manager"]);
    const isDiscountManager = usePermission(null, ["discount_manager"]);
    const isDiscountRequester = usePermission(null, ["discount_requester"]);
    const isDiscountAnalyst = usePermission(null, ["discount_analyst"]);
    const isSurveyManager = usePermission(null, ["survey_manager"]);
    const isInventoryManager = usePermission(null, ["inventory_manager"]);
    const isForecastManager = usePermission(null, ["forecast_manager"]);
    const isVendor = usePermission(null, ["vendor"]);
    const CanSeeInventoryMenu = usePermission(null, ["inventory_team_lead", "inventory_assistant_general_manager", "inventory_general_manager"]);
    const CanSeeForecastMenu = usePermission(null, ["inventory_general_manager"]);

    const is_team_lead = usePermission(null, ["inventory_team_lead"]);
    const is_general_manager = usePermission(null, ["inventory_assistant_general_manager", "inventory_general_manager"]);

    const inventory_manage_items = usePermission(["inventory_manage_items"], null);
    const inventory_manage_containers = usePermission(["inventory_manage_containers"], null);
    const inventory_manage_vendors = usePermission(["inventory_manage_vendors"], null);
    const inventory_manage_vendor_email_templates = usePermission(["inventory_manage_vendor_email_templates"], null);
    const inventory_manage_branch_items = usePermission(["inventory_manage_branch_items"], null);
    const inventory_manage_branch_containers = usePermission(["inventory_manage_branch_containers"], null);
    const inventory_view_end_of_day_start_of_day = usePermission(["inventory_view_end_of_day_start_of_day"], null);
    const inventory_view_end_of_day_report = usePermission(["inventory_view_end_of_day_report"], null);
    const inventory_place_orders = usePermission(["inventory_place_orders"], null);
    const inventory_view_orders_status_history = usePermission(["inventory_view_orders_status_history"], null);
    const inventory_manage_forecasts = usePermission(["inventory_manage_forecasts"], null);
    const inventory_modify_end_of_day_start_of_day = usePermission(["inventory_modify_end_of_day_start_of_day"], null);
    const inventory_modify_end_of_day_report = usePermission(["inventory_modify_end_of_day_report"], null);

    const inventory_view_end_of_shift_reports = usePermission(["inventory_view_end_of_shift_reports"], null);
    const inventory_store_end_of_shift_report = usePermission(["inventory_store_end_of_shift_report"], null);
    const inventory_manage_temperature_zones = usePermission(["inventory_manage_temperature_zones"], null);

    const inventory_view_temperature_reports = usePermission(["inventory_view_temperature_reports"], null);
    const inventory_store_temperature_report = usePermission(["inventory_store_temperature_report"], null);

    const settings_manage_branch_hours = usePermission(["settings_manage_branch_hours"], null);

    const storefront_manage_categories = usePermission(["storefront_manage_categories"], null);
    const storefront_manage_foods = usePermission(["storefront_manage_foods"], null);
    const inventory_manage_training_topics = usePermission(['inventory_manage_training_topics'], null);
    const hr_add_training_evaluation = usePermission(['hr_add_training_evaluation'], null);
    const hr_add_training_report = usePermission(['hr_add_training_report'], null);
    const quality_compliance_qc_reports = usePermission(['quality_compliance_qc_reports'], null);
    const payroll_management_manage_payrolls = usePermission(['payroll_management_manage_payrolls'], null);

    // Update desktopMode state on window resize
    useEffect(() => {
        const handleResize = () => setDesktopMode(window.innerWidth >= 768);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);


    useEffect(() => {
        if(!desktopMode) {
            dispatch(HideDashboardMenu());
        }

        if (collapsed) return;

        // Open all relevant paths when navigating based on current location
        const paths = location.pathname.split('/').filter(x => x);
        let openPaths = [];
        paths.reduce((prev, curr) => {
            const path = `${prev}/${curr}`;
            openPaths.push(path);
            return path;
        }, '');

        setOpenKeys(openPaths);


    }, [location]);

    const toggleCollapsed = () => {
        setCollapsed(!collapsed);
    };

    // Handle menu open/close changes
    const onOpenChange = keys => {
        setOpenKeys(keys);  // Allow multiple menus to remain open by setting all keys
    };

    let selectedKeys = [location.pathname];

    if (
        location.pathname.endsWith('/manage') ||
        location.pathname.endsWith('/branch') ||
        location.pathname.endsWith('/render') ||
        location.pathname.endsWith('/edit') ||
        location.pathname.endsWith('/submissions')
    ) {
        let basePath = location.pathname.replace(/\/(manage|branch|render|edit|submissions)$/, '');
        selectedKeys = [location.pathname, basePath];

        // Check if the path contains one or two IDs before the suffix
        const idMatch = basePath.match(/\/(\d+)(\/\d+)?$/);
        if (idMatch) {
            const pathWithoutIds = basePath.replace(/\/(\d+)(\/\d+)?$/, '');
            selectedKeys.push(pathWithoutIds);
        }
    }


    //console.log("selectedKeys", selectedKeys);

    return (
        <AnimatePresence>
            {(dashboardMenuVisible || desktopMode) &&
                <motion.div
                    initial={{x: '-100%'}}  // Start off-screen to the left
                    animate={{x: 0}}        // Slide in to the final position
                    exit={{x: '-100%'}}      // Slide out to the left
                    transition={{type: "tween", duration: .2}} // Smooth tweening with duration
                    className={`sidebar-container brand-h-screen overflow-x-hidden scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100 relative pt-2 ${desktopMode ? 'md:block' : 'md:hidden'}`}
                    style={{
                        width: 280,
                        position: desktopMode ? 'relative' : 'fixed',
                        zIndex: 1000,
                        height: '100%',
                        left: 0,
                        top: desktopMode ? 0 : 70,
                        marginTop: desktopMode ? 6 : 0,
                        backgroundColor: '#F4F5FA',
                        borderRight: desktopMode ? 'none' : '2px solid gray',
                        scrollbarWidth: 5
                    }}
                >

                    <Menu
                        mode="inline"
                        inlineCollapsed={false}
                        style={{width: 280}}
                        onOpenChange={onOpenChange}
                        openKeys={openKeys}
                        selectedKeys={selectedKeys}
                        theme="light"
                    >

                        <Menu.Item key="/dashboard" icon={<RiDashboardLine/>}>
                            <Link to="/dashboard">Dashboard</Link>
                        </Menu.Item>

                        <Menu.SubMenu
                            key="/dashboard/performance_metrics"
                            icon={<RiBarChartLine />}
                            title="Performance Metrics"
                        >
                            <Menu.Item
                                key="/dashboard/performance_metrics/notices"
                                icon={<RiNotification2Line />}
                            >
                                <Link to="/dashboard/performance_metrics/notices">Notices</Link>
                            </Menu.Item>
                        </Menu.SubMenu>


                        {(canManageBloggers || canManageKPI || isHQ || isIraniManager || isReviewManager || isBranchManager) && (
                            <Menu.SubMenu
                                key="/dashboard/research"
                                icon={<RiFileSearchLine/>}  // Icon for "Research" menu
                                title="Research"
                            >
                                {(canManageBloggers || isBranchManager) && (
                                    <Menu.SubMenu
                                        key="/dashboard/research/bloggers"
                                        icon={<RiUser3Line/>}
                                        title="Bloggers"
                                    >
                                        <Menu.Item key="/dashboard/research/bloggers/mark" icon={<RiUserHeartLine/>}>
                                            <Link to="/dashboard/research/bloggers/mark">Mark</Link>
                                        </Menu.Item>
                                        <Menu.Item key="/dashboard/research/bloggers/invite" icon={<RiUserAddLine/>}>
                                            <Link to="/dashboard/research/bloggers/invite">Invite</Link>
                                        </Menu.Item>
                                        <Menu.Item key="/dashboard/research/bloggers/invitees" icon={<RiTimeLine/>}>
                                            <Link to="/dashboard/research/bloggers/invitees">Invitees</Link>
                                        </Menu.Item>
                                        <Menu.Item key="/dashboard/research/bloggers/attends" icon={<RiUserLine/>}>
                                            <Link to="/dashboard/research/bloggers/attends">Attends</Link>
                                        </Menu.Item>
                                        <Menu.Item key="/dashboard/research/bloggers/settings" icon={<RiSettings3Line/>}>
                                            <Link to="/dashboard/research/bloggers/settings">Settings</Link>
                                        </Menu.Item>
                                    </Menu.SubMenu>
                                )}

                                {canManageKPI && (
                                    <Menu.Item key="/dashboard/research/KPI" icon={<RiBarChartLine/>}>
                                        <Link to="/dashboard/research/KPI">KPI Reports</Link>
                                    </Menu.Item>
                                )}

                                {isHQ && (
                                    <Menu.SubMenu
                                        key="/dashboard/research/divisions"
                                        icon={<RiOrganizationChart/>}
                                        title="Divisions"
                                    >
                                        <Menu.Item key="/dashboard/research/divisions/open_subjects" icon={<RiFolderOpenLine/>}>
                                            <Link to="/dashboard/research/divisions/open_subjects">Open Projects</Link>
                                        </Menu.Item>
                                        <Menu.Item key="/dashboard/research/divisions/my_tasks" icon={<RiCheckboxLine/>}>
                                            <Link to="/dashboard/research/divisions/my_tasks">My Tasks</Link>
                                        </Menu.Item>
                                        <Menu.Item key="/dashboard/research/divisions/timeline" icon={<RiTimelineView/>}>
                                            <Link to="/dashboard/research/divisions/timeline">Timeline</Link>
                                        </Menu.Item>
                                        <Menu.Item key="/dashboard/research/divisions/index" icon={<RiDashboardLine/>}>
                                            <Link to="/dashboard/research/divisions/index">All Divisions</Link>
                                        </Menu.Item>
                                        <Menu.Item key="/dashboard/research/divisions/tree" icon={<RiOrganizationChart/>}>
                                            <Link to="/dashboard/research/divisions/tree">Company Tree</Link>
                                        </Menu.Item>
                                    </Menu.SubMenu>
                                )}

                                {isIraniManager && (
                                    <Menu.SubMenu
                                        key="/dashboard/research/irani"
                                        icon={<RiBuilding3Line/>}
                                        title="Irani"
                                    >
                                        <Menu.Item key="/dashboard/research/irani/validate" icon={<RiFolderOpenLine/>}>
                                            <Link to="/dashboard/research/irani/validate">Validate</Link>
                                        </Menu.Item>
                                        <Menu.Item key="/dashboard/research/irani/khaz_brands" icon={<RiShoppingBag3Line/>}>
                                            <Link to="/dashboard/research/irani/khaz_brands">Khaz Brands</Link>
                                        </Menu.Item>
                                        <Menu.Item key="/dashboard/research/irani/standard_brands" icon={<RiCheckboxCircleLine/>}>
                                            <Link to="/dashboard/research/irani/standard_brands">Standard Brands</Link>
                                        </Menu.Item>
                                        <Menu.Item key="/dashboard/research/irani/fancy_brands" icon={<RiVipCrownLine/>}>
                                            <Link to="/dashboard/research/irani/fancy_brands">Fancy Brands</Link>
                                        </Menu.Item>
                                        <Menu.Item key="/dashboard/research/irani/reports" icon={<RiBarChartLine/>}>
                                            <Link to="/dashboard/research/irani/reports">Nationalities</Link>
                                        </Menu.Item>
                                        <Menu.Item key="/dashboard/research/irani/context/reports" icon={<RiBookOpenLine/>}>
                                            <Link to="/dashboard/research/irani/context/reports">Contexts</Link>
                                        </Menu.Item>
                                        <Menu.Item key="/dashboard/research/irani/review/reports" icon={<RiChat3Line/>}>
                                            <Link to="/dashboard/research/irani/review/reports">Reviews</Link>
                                        </Menu.Item>
                                    </Menu.SubMenu>
                                )}

                                {isIraniManager && (
                                    <Menu.SubMenu
                                        key="/dashboard/research/usa"
                                        icon={<RiBuilding3Line/>}
                                        title="USA"
                                    >
                                        <Menu.Item key="/dashboard/research/usa/validate" icon={<RiFolderOpenLine/>}>
                                            <Link to="/dashboard/research/usa/validate">Validate</Link>
                                        </Menu.Item>
                                        <Menu.Item key="/dashboard/research/usa/khaz_brands" icon={<RiShoppingBag3Line/>}>
                                            <Link to="/dashboard/research/usa/khaz_brands">Age Residim Brands</Link>
                                        </Menu.Item>
                                        <Menu.Item key="/dashboard/research/usa/standard_brands" icon={<RiCheckboxCircleLine/>}>
                                            <Link to="/dashboard/research/usa/standard_brands">Mohem Brands</Link>
                                        </Menu.Item>
                                        <Menu.Item key="/dashboard/research/usa/fancy_brands" icon={<RiVipCrownLine/>}>
                                            <Link to="/dashboard/research/usa/fancy_brands">Vajeb Brands</Link>
                                        </Menu.Item>
                                    </Menu.SubMenu>
                                )}

                                {isIraniManager && (
                                    <Menu.SubMenu
                                        key="/dashboard/research/dubai"
                                        icon={<RiBuilding3Line/>}
                                        title="Dubai"
                                    >
                                        <Menu.Item key="/dashboard/research/dubai/validate" icon={<RiFolderOpenLine/>}>
                                            <Link to="/dashboard/research/dubai/validate">Validate</Link>
                                        </Menu.Item>
                                        <Menu.Item key="/dashboard/research/dubai/khaz_brands" icon={<RiShoppingBag3Line/>}>
                                            <Link to="/dashboard/research/dubai/khaz_brands">Age Residim Brands</Link>
                                        </Menu.Item>
                                        <Menu.Item key="/dashboard/research/dubai/standard_brands" icon={<RiCheckboxCircleLine/>}>
                                            <Link to="/dashboard/research/dubai/standard_brands">Mohem Brands</Link>
                                        </Menu.Item>
                                        <Menu.Item key="/dashboard/research/dubai/fancy_brands" icon={<RiVipCrownLine/>}>
                                            <Link to="/dashboard/research/dubai/fancy_brands">Vajeb Brands</Link>
                                        </Menu.Item>
                                    </Menu.SubMenu>
                                )}

                                {isIraniManager && (
                                    <Menu.SubMenu
                                        key="/dashboard/research/london"
                                        icon={<RiBuilding3Line/>}
                                        title="London"
                                    >
                                        <Menu.Item key="/dashboard/research/london/validate" icon={<RiFolderOpenLine/>}>
                                            <Link to="/dashboard/research/london/validate">Validate</Link>
                                        </Menu.Item>
                                        <Menu.Item key="/dashboard/research/london/khaz_brands" icon={<RiShoppingBag3Line/>}>
                                            <Link to="/dashboard/research/london/khaz_brands">Age Residim Brands</Link>
                                        </Menu.Item>
                                        <Menu.Item key="/dashboard/research/london/standard_brands" icon={<RiCheckboxCircleLine/>}>
                                            <Link to="/dashboard/research/london/standard_brands">Mohem Brands</Link>
                                        </Menu.Item>
                                        <Menu.Item key="/dashboard/research/london/fancy_brands" icon={<RiVipCrownLine/>}>
                                            <Link to="/dashboard/research/london/fancy_brands">Vajeb Brands</Link>
                                        </Menu.Item>
                                    </Menu.SubMenu>
                                )}

                                {isIraniManager && (
                                    <Menu.SubMenu
                                        key="/dashboard/research/paris"
                                        icon={<RiBuilding3Line/>}
                                        title="Paris"
                                    >
                                        <Menu.Item key="/dashboard/research/paris/validate" icon={<RiFolderOpenLine/>}>
                                            <Link to="/dashboard/research/paris/validate">Validate</Link>
                                        </Menu.Item>
                                        <Menu.Item key="/dashboard/research/paris/khaz_brands" icon={<RiShoppingBag3Line/>}>
                                            <Link to="/dashboard/research/paris/khaz_brands">Age Residim Brands</Link>
                                        </Menu.Item>
                                        <Menu.Item key="/dashboard/research/paris/standard_brands" icon={<RiCheckboxCircleLine/>}>
                                            <Link to="/dashboard/research/paris/standard_brands">Mohem Brands</Link>
                                        </Menu.Item>
                                        <Menu.Item key="/dashboard/research/paris/fancy_brands" icon={<RiVipCrownLine/>}>
                                            <Link to="/dashboard/research/paris/fancy_brands">Vajeb Brands</Link>
                                        </Menu.Item>
                                    </Menu.SubMenu>
                                )}

                                {isIraniManager && (
                                    <Menu.SubMenu
                                        key="/dashboard/research/istanbul"
                                        icon={<RiBuilding2Line/>}
                                        title="Istanbul"
                                    >
                                        <Menu.Item key="/dashboard/research/istanbul/validate" icon={<RiFolderLine/>}>
                                            <Link to="/dashboard/research/istanbul/validate">Validate</Link>
                                        </Menu.Item>
                                        <Menu.Item key="/dashboard/research/istanbul/khaz_brands" icon={<RiShoppingBag3Line/>}>
                                            <Link to="/dashboard/research/istanbul/khaz_brands">Age Residim Brands</Link>
                                        </Menu.Item>
                                        <Menu.Item key="/dashboard/research/istanbul/standard_brands" icon={<RiCheckboxBlankCircleLine/>}>
                                            <Link to="/dashboard/research/istanbul/standard_brands">Mohem Brands</Link>
                                        </Menu.Item>
                                        <Menu.Item key="/dashboard/research/istanbul/fancy_brands" icon={<RiVipLine/>}>
                                            <Link to="/dashboard/research/istanbul/fancy_brands">Vajeb Brands</Link>
                                        </Menu.Item>
                                    </Menu.SubMenu>
                                )}

                                {isIraniManager && (
                                    <Menu.SubMenu
                                        key="/dashboard/research/canada"
                                        icon={<RiBuilding2Line/>}
                                        title="Canada"
                                    >
                                        <Menu.Item key="/dashboard/research/canada/validate" icon={<RiFolderLine/>}>
                                            <Link to="/dashboard/research/canada/validate">Validate</Link>
                                        </Menu.Item>
                                        <Menu.Item key="/dashboard/research/canada/khaz_brands" icon={<RiShoppingBag3Line/>}>
                                            <Link to="/dashboard/research/canada/khaz_brands">Age Residim Brands</Link>
                                        </Menu.Item>
                                        <Menu.Item key="/dashboard/research/canada/standard_brands" icon={<RiCheckboxBlankCircleLine/>}>
                                            <Link to="/dashboard/research/canada/standard_brands">Mohem Brands</Link>
                                        </Menu.Item>
                                        <Menu.Item key="/dashboard/research/canada/fancy_brands" icon={<RiVipLine/>}>
                                            <Link to="/dashboard/research/canada/fancy_brands">Vajeb Brands</Link>
                                        </Menu.Item>
                                    </Menu.SubMenu>
                                )}

                                {isIraniManager && (
                                    <Menu.SubMenu
                                        key="/dashboard/research/tehran"
                                        icon={<RiBuilding2Line/>}
                                        title="Tehran"
                                    >
                                        <Menu.Item key="/dashboard/research/tehran/validate" icon={<RiFolderLine/>}>
                                            <Link to="/dashboard/research/tehran/validate">Validate</Link>
                                        </Menu.Item>
                                        <Menu.Item key="/dashboard/research/tehran/khaz_brands" icon={<RiShoppingBag3Line/>}>
                                            <Link to="/dashboard/research/tehran/khaz_brands">Age Residim Brands</Link>
                                        </Menu.Item>
                                        <Menu.Item key="/dashboard/research/tehran/standard_brands" icon={<RiCheckboxBlankCircleLine/>}>
                                            <Link to="/dashboard/research/tehran/standard_brands">Mohem Brands</Link>
                                        </Menu.Item>
                                        <Menu.Item key="/dashboard/research/tehran/fancy_brands" icon={<RiVipLine/>}>
                                            <Link to="/dashboard/research/tehran/fancy_brands">Vajeb Brands</Link>
                                        </Menu.Item>
                                    </Menu.SubMenu>
                                )}

                                {isIraniManager && (
                                    <Menu.SubMenu
                                        key="/dashboard/research/kingcounty"
                                        icon={<RiBuilding2Line/>}
                                        title="King County"
                                    >
                                        <Menu.Item key="/dashboard/research/kingcounty/validate" icon={<RiFolderLine/>}>
                                            <Link to="/dashboard/research/kingcounty/validate">Validate</Link>
                                        </Menu.Item>
                                        <Menu.Item key="/dashboard/research/kingcounty/khaz_brands" icon={<RiShoppingBag3Line/>}>
                                            <Link to="/dashboard/research/kingcounty/khaz_brands">Age Residim Brands</Link>
                                        </Menu.Item>
                                        <Menu.Item key="/dashboard/research/kingcounty/standard_brands" icon={<RiCheckboxBlankCircleLine/>}>
                                            <Link to="/dashboard/research/kingcounty/standard_brands">Mohem Brands</Link>
                                        </Menu.Item>
                                        <Menu.Item key="/dashboard/research/kingcounty/fancy_brands" icon={<RiVipLine/>}>
                                            <Link to="/dashboard/research/kingcounty/fancy_brands">Vajeb Brands</Link>
                                        </Menu.Item>
                                    </Menu.SubMenu>
                                )}

                                {isIraniManager && (
                                    <Menu.SubMenu
                                        key="/dashboard/research/bigbang"
                                        icon={<RiMapLine/>}
                                        title="Big Bang"
                                    >
                                        <Menu.Item key="/dashboard/research/bigbang/reports" icon={<RiPieChartLine/>}>
                                            <Link to="/dashboard/research/bigbang/reports">Reports</Link>
                                        </Menu.Item>
                                    </Menu.SubMenu>
                                )}

                                {isReviewManager && (
                                    <Menu.SubMenu
                                        key="/dashboard/research/reviews"
                                        icon={<RiEdit2Line/>}
                                        title="Reviews"
                                    >
                                        <Menu.Item key="/dashboard/research/reviews/places" icon={<RiMapPinLine/>}>
                                            <Link to="/dashboard/research/reviews/places">Places</Link>
                                        </Menu.Item>
                                        <Menu.Item key="/dashboard/research/reviews/base_reviews" icon={<RiEdit2Line/>}>
                                            <Link to="/dashboard/research/reviews/base_reviews">Google Reviews</Link>
                                        </Menu.Item>
                                        <Menu.Item key="/dashboard/research/reviews/our_reviews" icon={<RiEdit2Line/>}>
                                            <Link to="/dashboard/research/reviews/our_reviews">Our Reviews</Link>
                                        </Menu.Item>
                                    </Menu.SubMenu>
                                )}
                            </Menu.SubMenu>
                        )}


                        {/* 1. Operations Management */}
                        {(isInventoryManager || isBranchManager || isVendor || CanSeeInventoryMenu || inventory_view_end_of_day_start_of_day || inventory_modify_end_of_day_start_of_day ||
                            inventory_store_end_of_shift_report || inventory_view_end_of_shift_reports || inventory_view_temperature_reports || inventory_store_temperature_report
                        ) && (
                            <Menu.SubMenu key="/dashboard/operations" icon={<RiSuitcaseLine/>} title="Operations Management">

                                {/* Daily Logs */}
                                {(inventory_view_end_of_day_start_of_day || inventory_modify_end_of_day_start_of_day || isInventoryManager) && (
                                    <Menu.SubMenu key="/dashboard/operations/daily-logs" icon={<RiCalendarLine/>} title="Daily Logs">
                                        {(isInventoryManager || inventory_view_end_of_day_start_of_day || inventory_modify_end_of_day_start_of_day) && (
                                            <Menu.Item key="/dashboard/operations/daily-logs/inventory/end-of-day" icon={<RiCalendarLine/>}>
                                                <Link to="/dashboard/operations/daily-logs/inventory/end-of-day/branch">End of Day</Link>
                                            </Menu.Item>
                                        )}

                                        {(isInventoryManager || inventory_view_end_of_day_start_of_day || inventory_modify_end_of_day_start_of_day) && (
                                            <Menu.Item key="/dashboard/operations/daily-logs/inventory/start-of-day" icon={<RiCalendar2Line/>}>
                                                <Link to="/dashboard/operations/daily-logs/inventory/start-of-day/branch">Start of Day</Link>
                                            </Menu.Item>
                                        )}

                                        {(isInventoryManager || inventory_store_end_of_shift_report) && (
                                            <Menu.Item key="/dashboard/operations/daily-logs/end-of-shift/submit-report" icon={<RiFileAddLine/>}>
                                                <Link to="/dashboard/operations/daily-logs/end-of-shift/submit-report/branch">Submit Report</Link>
                                            </Menu.Item>
                                        )}

                                        {(isInventoryManager || inventory_view_end_of_shift_reports) && (
                                            <Menu.Item key="/dashboard/operations/daily-logs/end-of-shift/reports" icon={<RiBarChart2Line/>}>
                                                <Link to="/dashboard/operations/daily-logs/end-of-shift/reports/branch">Reports</Link>
                                            </Menu.Item>
                                        )}

                                        {(isInventoryManager || inventory_store_temperature_report) && (
                                            <Menu.Item key="/dashboard/operations/daily-logs/end-of-shift/temperature-reports/create" icon={<RiFileAddLine/>}>
                                                <Link to="/dashboard/operations/daily-logs/end-of-shift/temperature-reports/create/branch">Submit Temperature Report</Link>
                                            </Menu.Item>
                                        )}

                                        {(isInventoryManager || inventory_view_temperature_reports) && (
                                            <Menu.Item key="/dashboard/operations/daily-logs/end-of-shift/temperature-reports" icon={<RiBarChart2Line/>}>
                                                <Link to="/dashboard/operations/daily-logs/end-of-shift/temperature-reports/branch">Temperature Reports</Link>
                                            </Menu.Item>
                                        )}

                                    </Menu.SubMenu>
                                )}

                                <Menu.SubMenu key="/dashboard/operations/ck" icon={<RiBuilding3Line/>} title="Central Kitchen">
                                    {/* Order Management */}
                                    {(is_team_lead || is_general_manager) && (
                                        <Menu.SubMenu key="/dashboard/operations/ck/checklists" icon={<RiCheckboxLine/>} title="Checklists">
                                            {(is_team_lead || is_general_manager) && (
                                                <Menu.Item key="/dashboard/operations/ck/checklists/checklists" icon={<RiCheckboxBlankLine/>}>
                                                    <Link to="/dashboard/operations/ck/checklists/checklists/branch">Checklists</Link>
                                                </Menu.Item>
                                            )}
                                            {is_general_manager && (
                                                <Menu.Item key="/dashboard/operations/ck/checklists/manage" icon={<RiEdit2Line/>}>
                                                    <Link to="/dashboard/operations/ck/checklists/manage/branch">Manage Checklists</Link>
                                                </Menu.Item>
                                            )}
                                            {(is_general_manager) && (
                                                <Menu.Item key="/dashboard/operations/ck/checklists/reports" icon={<RiOrganizationChart/>}>
                                                    <Link to="/dashboard/operations/ck/checklists/reports/branch">Reports</Link>
                                                </Menu.Item>
                                            )}
                                        </Menu.SubMenu>
                                    )}

                                    {(is_general_manager) && (
                                        <Menu.SubMenu key="/dashboard/operations/ck/beefcuts" icon={<RiKnifeBloodLine/>} title="Beef Cuts">
                                            {(is_general_manager) && (
                                                <Menu.Item key="/dashboard/operations/ck/beefcuts/cuts" icon={<RiKnifeLine/>}>
                                                    <Link to="/dashboard/operations/ck/beefcuts/cuts">Cuts</Link>
                                                </Menu.Item>
                                            )}
                                            {is_general_manager && (
                                                <Menu.Item key="/dashboard/operations/ck/beefcuts/history" icon={<RiHistoryLine/>}>
                                                    <Link to="/dashboard/operations/ck/beefcuts/history">History</Link>
                                                </Menu.Item>
                                            )}
                                            {(is_general_manager) && (
                                                <Menu.Item key="/dashboard/operations/ck/beefcuts/setup" icon={<RiOrganizationChart/>}>
                                                    <Link to="/dashboard/operations/ck/beefcuts/setup">Setup</Link>
                                                </Menu.Item>
                                            )}
                                        </Menu.SubMenu>
                                    )}

                                </Menu.SubMenu>

                                {/* Order Management */}
                                {(isInventoryManager || isVendor || inventory_place_orders || inventory_view_orders_status_history || inventory_manage_forecasts) && (
                                    <Menu.SubMenu key="/dashboard/operations/order-management" icon={<RiTruckLine/>} title="Order Management">
                                        {(isInventoryManager || inventory_place_orders) && (
                                            <Menu.Item key="/dashboard/operations/order-management/inventory/order" icon={<RiShoppingCartLine/>}>
                                                <Link to="/dashboard/operations/order-management/inventory/order/branch">Branch Orders</Link>
                                            </Menu.Item>
                                        )}
                                        {isVendor && (
                                            <Menu.Item key="/dashboard/operations/order-management/inventory/vendor-orders" icon={<RiBillLine/>}>
                                                <Link to="/dashboard/operations/order-management/inventory/vendor-orders">Vendor Orders</Link>
                                            </Menu.Item>
                                        )}
                                        {(isInventoryManager || inventory_view_orders_status_history) && (
                                            <Menu.Item key="/dashboard/operations/order-management/inventory/branch-orders" icon={<RiShoppingBasketLine/>}>
                                                <Link to="/dashboard/operations/order-management/inventory/branch-orders/branch">Branch Order Tracking</Link>
                                            </Menu.Item>
                                        )}
                                        {(isInventoryManager || inventory_manage_forecasts) && (
                                            <Menu.Item key="/dashboard/operations/order-management/forecast/forecasts" icon={<RiPercentLine/>}>
                                                <Link to="/dashboard/operations/order-management/forecast/forecasts/branch">Forecasting</Link>
                                            </Menu.Item>
                                        )}
                                    </Menu.SubMenu>
                                )}

                                {/* Waste Management */}
                                {(isInventoryManager || isBranchManager) && (
                                    <Menu.Item key="/dashboard/operations/waste-management" icon={<RiDeleteBinLine/>}>
                                        <Link to="/dashboard/operations/waste-management">Waste Management</Link>
                                    </Menu.Item>
                                )}

                                {/* Waste Management */}
                                {(isInventoryManager || isBranchManager) && (
                                    <Menu.Item key="/dashboard/operations/branch-checkouts" icon={<RiDeleteBinLine/>}>
                                        <Link to="/dashboard/operations/branch-checkouts/branch">Branch Checkouts</Link>
                                    </Menu.Item>
                                )}

                                {/* Reports & Analytics */}
                                {(isInventoryManager || isBranchManager || inventory_view_end_of_day_report || inventory_modify_end_of_day_report) && (
                                    <Menu.SubMenu key="/dashboard/operations/reports-analytics" icon={<RiBarChartLine/>} title="Reports & Analytics">
                                        {(isInventoryManager || inventory_view_end_of_day_report || inventory_modify_end_of_day_report) && (
                                            <Menu.Item key="/dashboard/operations/reports-analytics/inventory/reports" icon={<RiBarChart2Line/>}>
                                                <Link to="/dashboard/operations/reports-analytics/inventory/reports/branch">Inventory Reports</Link>
                                            </Menu.Item>
                                        )}


                                    </Menu.SubMenu>
                                )}

                                {/* Settings/Setup */}
                                {(isInventoryManager || inventory_manage_items || inventory_manage_containers || inventory_manage_vendors ||
                                    inventory_manage_branch_items || inventory_manage_branch_containers || inventory_manage_temperature_zones || inventory_manage_vendor_email_templates) && (
                                    <Menu.SubMenu key="/dashboard/operations/setup" icon={<RiSettings3Line/>} title="Setup">
                                        {(isInventoryManager || inventory_manage_items) && (
                                            <Menu.Item key="/dashboard/operations/setup/inventory/manage" icon={<RiEdit2Line/>}>
                                                <Link to="/dashboard/operations/setup/inventory/manage">Inventories</Link>
                                            </Menu.Item>
                                        )}
                                        {(isInventoryManager || inventory_manage_containers) && (
                                            <Menu.Item key="/dashboard/operations/setup/inventory/containers" icon={<RiBox1Line/>}>
                                                <Link to="/dashboard/operations/setup/inventory/containers/manage">Containers</Link>
                                            </Menu.Item>
                                        )}
                                        {(isInventoryManager || inventory_manage_vendors) && (
                                            <Menu.Item key="/dashboard/operations/setup/inventory/vendors" icon={<RiTruckLine/>}>
                                                <Link to="/dashboard/operations/setup/inventory/vendors/manage">Vendors</Link>
                                            </Menu.Item>
                                        )}
                                        {(isInventoryManager || inventory_manage_branch_items) && (
                                            <Menu.Item key="/dashboard/operations/setup/inventory/branch-group" icon={<RiStackLine/>}>
                                                <Link to="/dashboard/operations/setup/inventory/branch-group/manage">Branch Inventories</Link>
                                            </Menu.Item>
                                        )}
                                        {(isInventoryManager || inventory_manage_branch_containers) && (
                                            <Menu.Item key="/dashboard/operations/setup/inventory/branch-containers" icon={<RiBox2Line/>}>
                                                <Link to="/dashboard/operations/setup/inventory/branch-containers/manage">Branch Containers</Link>
                                            </Menu.Item>
                                        )}
                                        {(isInventoryManager || inventory_manage_temperature_zones) && (
                                            <Menu.Item key="/dashboard/operations/setup/inventory/temperature-zones" icon={<RiBox2Line/>}>
                                                <Link to="/dashboard/operations/setup/inventory/temperature-zones/branch">Temperature Zones</Link>
                                            </Menu.Item>
                                        )}
                                        {(isInventoryManager || inventory_manage_vendor_email_templates) && (
                                            <Menu.Item key="/dashboard/operations/setup/inventory/email-templates" icon={<RiMailLine/>}>
                                                <Link to="/dashboard/operations/setup/inventory/email-templates">Email Templates</Link>
                                            </Menu.Item>
                                        )}
                                    </Menu.SubMenu>
                                )}
                            </Menu.SubMenu>
                        )}

                        {/* 2. HR Management */}
                        {(isBranchManager || isBranchEmployeeManager || isDepartmentManager || inventory_manage_training_topics || hr_add_training_evaluation || hr_add_training_report) && (
                            <Menu.SubMenu key="/dashboard/hr" icon={<RiTeamLine/>} title="PCL Management">
                                {/* Employees */}
                                {(isBranchManager || isBranchEmployeeManager || isDepartmentManager) &&
                                    <Menu.SubMenu key="/dashboard/hr/employees" icon={<RiUser3Line/>} title="Team Members">
                                        <Menu.Item key="/dashboard/hr/employees/manage" icon={<RiUser3Line/>}>
                                            <Link to="/dashboard/hr/employees/manage">Manage</Link>
                                        </Menu.Item>
                                        <Menu.Item key="/dashboard/hr/employees/roles" icon={<RiSettings2Line/>}>
                                            <Link to="/dashboard/hr/employees/roles">Roles</Link>
                                        </Menu.Item>
                                        <Menu.Item key="/dashboard/hr/employees/abilities" icon={<RiTeamLine/>}>
                                            <Link to="/dashboard/hr/employees/abilities">Abilities</Link>
                                        </Menu.Item>
                                    </Menu.SubMenu>
                                }


                                {(inventory_manage_training_topics || hr_add_training_evaluation || hr_add_training_report) &&
                                    <Menu.Item key="/dashboard/hr/training-evaluation" icon={<RiFileTextLine/>}>
                                        <Link to="/dashboard/hr/training-evaluation/branch">Training Evaluation</Link>
                                    </Menu.Item>
                                }

                                {(isBranchManager || isBranchEmployeeManager || isDepartmentManager) &&
                                    <Menu.Item key="/dashboard/hr/hiring-requests" icon={<RiUser3Line/>}>
                                        <Link to="/dashboard/hr/hiring-requests">Hiring Requests</Link>
                                    </Menu.Item>
                                }

                                {(isBranchManager || isBranchEmployeeManager || isDepartmentManager) &&
                                    <Menu.Item key="/dashboard/hr/pnc-ticketing" icon={<RiCouponLine/>}>
                                        <Link to="/dashboard/hr/pnc-ticketing">P&C Ticketing</Link>
                                    </Menu.Item>
                                }

                                {(isBranchManager || isBranchEmployeeManager || isDepartmentManager || inventory_manage_training_topics) && (
                                    <Menu.SubMenu
                                        key="/dashboard/hr/setup"
                                        icon={<RiBuilding2Line/>}
                                        title="Setup"
                                    >
                                        {(isBranchEmployeeManager || isBranchManager) &&
                                            <Menu.Item key="/dashboard/hr/setup/7shifts" icon={<RiSettings2Line/>}>
                                                <Link to="/dashboard/hr/setup/7shifts/manage">7Shifts</Link>
                                            </Menu.Item>
                                        }

                                        {(isBranchManager || isDepartmentManager) &&
                                            <Menu.Item key="/dashboard/hr/setup/departments" icon={<RiSettings2Line/>}>
                                                <Link to="/dashboard/hr/setup/departments/manage">Departments</Link>
                                            </Menu.Item>
                                        }

                                        {(isBranchManager || isDepartmentManager || inventory_manage_training_topics) &&
                                            <Menu.Item key="/dashboard/hr/setup/training-topics" icon={<RiSettings2Line/>}>
                                                <Link to="/dashboard/hr/setup/training-topics/branch">Training Topics</Link>
                                            </Menu.Item>
                                        }

                                        {(isBranchEmployeeManager || isBranchManager || isDepartmentManager) && (
                                            <Menu.Item key="/dashboard/hr/setup/inventory/email-templates" icon={<RiMailLine/>}>
                                                <Link to="/dashboard/hr/setup/inventory/email-templates">Email Templates</Link>
                                            </Menu.Item>
                                        )}

                                    </Menu.SubMenu>
                                )}
                            </Menu.SubMenu>
                        )}

                        {/* 3. Quality & Compliance */}
                        {(isBranchManager || quality_compliance_qc_reports) && (
                            <Menu.SubMenu key="/dashboard/quality-compliance" icon={<RiShieldLine/>} title="Quality & Compliance">
                                {(isBranchManager || quality_compliance_qc_reports) &&
                                    <Menu.Item key="/dashboard/quality-compliance/qc-reports" icon={<RiHistoryLine/>}>
                                        <Link to="/dashboard/quality-compliance/qc-reports/branch">QC Reports</Link>
                                    </Menu.Item>
                                }
                            </Menu.SubMenu>
                        )}

                        {/* 4. Ticketing & Dashboard */}
                        {isBranchManager && (
                            <Menu.SubMenu key="/dashboard/ticketing" icon={<RiTerminalLine/>} title="Ticketing & Dashboard">
                                <Menu.Item key="/dashboard/ticketing/history" icon={<RiHistoryLine/>}>
                                    <Link to="/dashboard/ticketing/history">History</Link>
                                </Menu.Item>
                                <Menu.Item key="/dashboard/ticketing/submit-ticket" icon={<RiFileAddLine/>}>
                                    <Link to="/dashboard/ticketing/submit-ticket">Submit Ticket</Link>
                                </Menu.Item>
                            </Menu.SubMenu>
                        )}

                        {/* Financial & Discount */}
                        <Menu.SubMenu key="/dashboard/financial-discount" icon={<RiCouponLine/>} title="Financial & Discounts">
                            {/* Discount Submenu */}
                            <Menu.SubMenu key="/dashboard/financial-discount/coupons" icon={<RiCouponLine/>} title="Discounts">
                                {/* Discount Presets */}
                                {(isBranchManager || isDiscountManager || isDiscountRequester) && (
                                    <>
                                        {(isBranchManager || isDiscountManager) &&
                                            <Menu.Item key="/dashboard/financial-discount/coupons/discount_presets" icon={<RiBillLine/>}>
                                                <Link to="/dashboard/financial-discount/coupons/discount_presets">Discount Presets</Link>
                                            </Menu.Item>
                                        }
                                        <Menu.Item key="/dashboard/financial-discount/coupons/generate_presets" icon={<RiFileAddLine/>}>
                                            <Link to="/dashboard/financial-discount/coupons/generate_presets">Generate Presets</Link>
                                        </Menu.Item>
                                    </>
                                )}

                                {/* Generate Menu */}
                                <Menu.Item key="/dashboard/financial-discount/coupons/generate_index" icon={<RiSettings2Line/>}>
                                    <Link to="/dashboard/financial-discount/coupons/generate_index">Generate</Link>
                                </Menu.Item>

                                {/* Approve Coupons */}
                                {(isBranchManager || isSupervisor) && (
                                    <Menu.Item key="/dashboard/financial-discount/coupons/approve" icon={<RiCheckLine/>}>
                                        <Link to="/dashboard/financial-discount/coupons/approve">Approve</Link>
                                    </Menu.Item>
                                )}

                                {/* Reports */}
                                {(isBranchManager || isDiscountManager || isDiscountAnalyst) && (
                                    <Menu.Item key="/dashboard/financial-discount/coupons/reports" icon={<RiPieChartLine/>}>
                                        <Link to="/dashboard/financial-discount/coupons/reports/branch">Reports</Link>
                                    </Menu.Item>
                                )}
                            </Menu.SubMenu>
                        </Menu.SubMenu>

                        {/* 6. Reports & Analytics */}
                        {(isBranchManager || isDepartmentManager) && (
                            <Menu.SubMenu key="/dashboard/reports-analytics" icon={<RiBarChartLine/>} title="Reports & Analytics">
                                <Menu.Item key="/dashboard/reports-analytics/survey" icon={<RiSurveyLine/>}>
                                    <Link to="/dashboard/reports-analytics/survey">Survey Reports</Link>
                                </Menu.Item>
                                <Menu.Item key="/dashboard/reports-analytics/branch-reports" icon={<RiPieChartLine/>}>
                                    <Link to="/dashboard/reports-analytics/branch-reports">Branch Reports</Link>
                                </Menu.Item>

                                {isBranchManager && (
                                    <Menu.SubMenu key="/dashboard/reports-analytics/forms" icon={<RiFileListLine/>} title="Forms">
                                        <Menu.Item key="/dashboard/reports-analytics/forms/create" icon={<RiFileAddLine/>}>
                                            <Link to="/dashboard/reports-analytics/forms/create">Create</Link>
                                        </Menu.Item>
                                        <Menu.Item key="/dashboard/reports-analytics/forms/manage" icon={<RiFileTextLine/>}>
                                            <Link to="/dashboard/reports-analytics/forms/manage">Manage</Link>
                                        </Menu.Item>
                                    </Menu.SubMenu>
                                )}
                            </Menu.SubMenu>
                        )}

                        {(isBranchManager || payroll_management_manage_payrolls) && (
                            <Menu.SubMenu
                                key="/dashboard/payrolls"
                                icon={<RiSecurePaymentLine />} // Icon for Storefront main menu
                                title="Payrolls"
                            >
                                    <Menu.Item
                                        key="/dashboard/payrolls/manage"
                                        icon={<RiFolderSettingsLine />} // Icon for "Manage Categories"
                                    >
                                        <Link to="/dashboard/payrolls/pay_periods">Pay Periods</Link>
                                    </Menu.Item>
                                    <Menu.Item
                                        key="/dashboard/payrolls/7shifts"
                                        icon={<RiCalendarScheduleLine />} // Icon for "Manage Foods"
                                    >
                                        <Link to="/dashboard/payrolls/7shifts">7Shifts</Link>
                                    </Menu.Item>

                                <Menu.Item
                                    key="/dashboard/payrolls/hour_types"
                                    icon={<RiHourglass2Line />} // Icon for "Manage Foods"
                                >
                                    <Link to="/dashboard/payrolls/hour_types">Hour Types</Link>
                                </Menu.Item>

                                <Menu.Item
                                    key="/dashboard/payrolls/benefits"
                                    icon={<RiMoneyCnyBoxLine />} // Icon for "Manage Foods"
                                >
                                    <Link to="/dashboard/payrolls/benefits">Benefits</Link>
                                </Menu.Item>
                            </Menu.SubMenu>
                        )}


                        {(isBranchManager || storefront_manage_categories || storefront_manage_foods) && (
                            <Menu.SubMenu
                                key="/dashboard/storefront"
                                icon={<RiStore3Line />} // Icon for Storefront main menu
                                title="Storefront"
                            >
                                {(storefront_manage_categories || isBranchManager) && (
                                    <Menu.Item
                                        key="/dashboard/storefront/categories/manage"
                                        icon={<RiFolderSettingsLine />} // Icon for "Manage Categories"
                                    >
                                        <Link to="/dashboard/storefront/categories/manage">Manage Categories</Link>
                                    </Menu.Item>
                                )}

                                {(storefront_manage_foods || isBranchManager) && (
                                    <Menu.Item
                                        key="/dashboard/storefront/foods/manage"
                                        icon={<RiRestaurant2Line />} // Icon for "Manage Foods"
                                    >
                                        <Link to="/dashboard/storefront/foods/manage">Manage Foods</Link>
                                    </Menu.Item>
                                )}
                            </Menu.SubMenu>
                        )}


                        {/* Settings */}
                        {(isBranchManager || isBranchEmployeeManager || settings_manage_branch_hours) && (
                            <Menu.SubMenu key="/dashboard/settings" icon={<RiSettings2Line/>} title="Settings">
                                {/* Terminals */}
                                <Menu.SubMenu key="/dashboard/settings/terminals" icon={<RiTerminalLine/>} title="Terminals">
                                    <Menu.Item key="/dashboard/settings/terminals/devices" icon={<RiBankCardLine/>}>
                                        <Link to="/dashboard/settings/terminals/devices">Devices</Link>
                                    </Menu.Item>
                                </Menu.SubMenu>

                                {settings_manage_branch_hours &&
                                    <Menu.Item key="/dashboard/settings/hours/branch" icon={<RiBankCardLine/>}>
                                        <Link to="/dashboard/settings/hours/branch">Branch Hours</Link>
                                    </Menu.Item>
                                }

                                {/* Forecast */}
                                {(isBranchManager || isForecastManager || inventory_manage_forecasts) && (
                                    <Menu.SubMenu key="/dashboard/settings/forecast" icon={<RiPieChartLine/>} title="Forecast">
                                        {(isBranchManager || isForecastManager) && (
                                            <Menu.Item key="/dashboard/settings/forecast/settings" icon={<RiSettings2Line/>}>
                                                <Link to="/dashboard/settings/forecast/settings">Settings</Link>
                                            </Menu.Item>
                                        )}
                                        {(isBranchManager || isForecastManager) && (
                                            <Menu.Item key="/dashboard/settings/forecast/coefficients/branch" icon={<RiPercentLine/>}>
                                                <Link to="/dashboard/settings/forecast/coefficients/branch">Coefficients</Link>
                                            </Menu.Item>
                                        )}
                                        {(isBranchManager || isForecastManager) && (
                                            <Menu.Item key="/dashboard/settings/forecast/models" icon={<RiMapLine/>}>
                                                <Link to="/dashboard/settings/forecast/models">Models</Link>
                                            </Menu.Item>
                                        )}
                                    </Menu.SubMenu>
                                )}
                            </Menu.SubMenu>
                        )}


                        {isBranchManager && false && (
                            <Menu.SubMenu
                                key="/dashboard/training"
                                icon={<RiSurveyLine/>}
                                title="Training"
                            >
                                <Menu.Item key="/dashboard/training/manage" icon={<RiUser3Line/>}>
                                    <Link to="/dashboard/training/manage">Manage</Link>
                                </Menu.Item>
                                <Menu.Item key="/dashboard/training/checklists" icon={<RiCheckLine/>}>
                                    <Link to="/dashboard/training/checklists">Checklists</Link>
                                </Menu.Item>
                            </Menu.SubMenu>
                        )}

                    </Menu>
                    <div className={"h-16"}>

                    </div>
                </motion.div>
            }
        </AnimatePresence>
    );
};


export default DashBoardMenu;