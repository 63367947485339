import axios from 'axios';
import { checkLoggedIn, getAuthenticationHeaders } from './Headers';

const Coupon = {

    Generate: (data, successCb, failCb) => {
        axios.post(`/coupon/generate`, data, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    Approve: (coupon_id, successCb, failCb) => {
        axios.post(`/coupons/${coupon_id}/approve`, null, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    Reject: (coupon_id, successCb, failCb) => {
        axios.post(`/coupons/${coupon_id}/reject`, null, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    UpdateCode: (coupon_id, code, successCb, failCb) => {
        axios.post(`/coupons/${coupon_id}/update-code`,{ code },  getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    Report: (branch_id, data, successCb, failCb) => {
        axios.post(`/branch/${branch_id}/coupons/report`, data, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    ApprovalReport: (branch_id, data, successCb, failCb) => {
        axios.post(`/branch/${branch_id}/coupons/approval_report`, data, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    ExportReportToExcel: (branch_id, branch_export_name, data, successCb, failCb) => {
        axios({
            url: `/branch/${branch_id}/coupons/export-excel`,
            method: 'GET',
            params: data,
            headers: getAuthenticationHeaders().headers,
            responseType: 'blob',  // Important for downloading files
        }).then(response => {
            let filename = `${branch_export_name}-discounts-${data.start_date}-to-${data.end_date}.xlsx`;
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename); // Use extracted filename
            document.body.appendChild(link);
            link.click();

            // Cleanup
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);

            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

};

export default Coupon;
