import axios from 'axios';
import { checkLoggedIn, getAuthenticationHeaders } from './Headers';

const BranchInventory = {
    Index: (successCb, failCb) => {
        axios.get(`/branch_inventories`, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    Edit: (id, successCb, failCb) => {
        axios.get(`/branch_inventories/${id}/edit`, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    Store: (data, successCb, failCb) => {
        axios.post(`/branch_inventories/store`, data, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    Update: (id, data, successCb, failCb) => {
        axios.post(`/branch_inventories/${id}/update`, data, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    Delete: (id, successCb, failCb) => {
        axios.delete(`/branch_inventories/${id}`, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    FetchInitialData: (successCb, failCb) => {
        axios.get(`/branch_inventories/fetch_initial_data`, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    GetBranchInventories: (branchId, successCb, failCb) => {
        axios.get(`/branch_inventories/${branchId}/end_of_day`, getAuthenticationHeaders())
            .then(response => {
                successCb(response);
            })
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },

    SubmitEndOfDay: (branchId, data, successCb, failCb) => {
        axios.post(`/branch_inventories/${branchId}/end_of_day`, data, getAuthenticationHeaders())
            .then(response => {
                successCb(response);
            })
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },

    IndexEndOfShiftReports: (branchId, config, successCb, failCb) => {
        axios.get(`/branch/${branchId}/end-of-shift-reports`, { ...getAuthenticationHeaders(), ...config })
            .then(response => {
                successCb(response);
            })
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },

    IndexBranchEmployees: (branchId, successCb, failCb) => {
        axios.get(`/branch/${branchId}/end-of-shift-reports/employees`, { ...getAuthenticationHeaders() })
            .then(response => {
                successCb(response);
            })
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },


    StoreEndOfShiftReport: (branchId, data, successCb, failCb) => {
        axios.post(`/branch/${branchId}/end-of-shift-reports`, data, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    UpdateEndOfShiftReport: (branchId, reportId, payload, successCb, failCb) => {
        axios.post(`/branch/${branchId}/end-of-shift-reports/${reportId}`, payload, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },


    ShowEndOfShiftReport: (reportId, successCb, failCb) => {
        axios.get(`/end-of-shift-reports/${reportId}`, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    AcknowledgeShowEndOfShiftReport: (reportId, successCb, failCb) => {
        axios.post(`/end-of-shift-reports/${reportId}/acknowledge`, {}, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

};

export default BranchInventory;
