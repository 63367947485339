import React from 'react';
import {motion} from "framer-motion";
import T from "../../../../components/T";
import DefaultContainer from "../../../../components/DefaultContainer";
import UserNotices from "../PerformanceMetrics/UserNotices";
import UnacknowledgedUserNotices from "./Components/UnacknowledgedUserNotices";

const DashboardIndex = () => {
    return (
        <>
            <DefaultContainer>
                <motion.div
                    initial={{opacity: 0, scale: 1.1, y: 0}}
                    animate={{opacity: 1, scale: 1, y: 0}}
                    className="w-full"
                >
                    <div className="flex flex-col mx-auto w-full">
                        <div className="text-xl font-bold text-black pl-1 flex flex-row justify-between mb-2">
                            <T upper>Dashboard</T>
                        </div>
                        Welcome to your dashboard!
                    </div>
                </motion.div>
            </DefaultContainer>
            <UnacknowledgedUserNotices/>
        </>
    );
};
export default DashboardIndex;
