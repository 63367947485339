import React, { useEffect, useState } from 'react';
import {
    Table,
    Button,
    notification,
    InputNumber,
    Modal,
    Form,
    Checkbox,
    DatePicker
} from 'antd';
import OrderService from '../../../../../http/OrderService';
import SkeletonWrapper from '../../../../../components/skeletons/SkeletonWrapper';
import GenericSkeleton from '../../../../../components/skeletons/GenericSkeleton';
import DefaultContainer from '../../../../../components/DefaultContainer';
import { ReloadOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faClock,
    faEye,
    faCheckCircle,
    faTasks,
    faTruck,
    faClipboardCheck,
    faPlay
} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import _ from 'lodash';

const { RangePicker } = DatePicker;

const allPossibleStatuses = [
    'pending',
    'seen',
    'confirmed',
    'preparing',
    'shipped',
    'received',
    'cancelled'
];

const VendorOrders = () => {
    const [loading, setLoading] = useState(true);    // controls both Skeleton + Table spinner
    const [orders, setOrders] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [refreshing, setRefreshing] = useState(false);
    const [loadingOrders, setLoadingOrders] = useState([]);
    const [form] = Form.useForm();

    // Filter states
    const [dateRange, setDateRange] = useState([]);
    const [selectedStatuses, setSelectedStatuses] = useState([]);

    useEffect(() => {
        fetchVendorOrders();
    }, []);

    // Updated to accept optional query params
    const fetchVendorOrders = (filters = {}) => {
        // set the loading states
        setLoading(true);
        setRefreshing(true);

        OrderService.getVendorOrders(
            filters,
            (response) => {
                const data = response.data.data;
                setOrders(data.orders);
                setLoading(false);
                setRefreshing(false);
            },
            (error) => {
                notification.error({
                    message: 'Error',
                    description: 'Failed to load orders'
                });
                setLoading(false);
                setRefreshing(false);
            }
        );
    };

    // Called by the "Filter" button
    const handleFilter = () => {
        let params = {};

        if (dateRange.length === 2) {
            params.from_date = moment(dateRange[0]).format('YYYY-MM-DD');
            params.to_date   = moment(dateRange[1]).format('YYYY-MM-DD');
        }

        if (selectedStatuses.length > 0) {
            params.statuses = selectedStatuses;
        }

        // set loading to true before fetching
        setLoading(true);
        fetchVendorOrders(params);
    };

    const handleStatusChange = (order, status) => {
        if (status === 'preparing') {
            setLoadingOrders((prev) => [...prev, order.id]);

            OrderService.updateVendorOrderStatus(
                order.id,
                { status: 'preparing' },
                (response) => {
                    notification.success({
                        message: 'Success',
                        description: 'Order marked as preparing successfully'
                    });
                    handleFilter(); // re-filter so we see updated results
                    setLoadingOrders((prev) => prev.filter((id) => id !== order.id));
                },
                (error) => {
                    notification.error({
                        message: 'Error',
                        description: 'Failed to update order status'
                    });
                    setLoadingOrders((prev) => prev.filter((id) => id !== order.id));
                }
            );
        } else if (status === 'shipped') {
            setSelectedOrder(order);
            form.setFieldsValue({ sent_amount: order.ordered_amount });
            setIsModalVisible(true);
        }
    };

    const handleModalOk = () => {
        form
            .validateFields()
            .then((values) => {
                OrderService.updateVendorOrderStatus(
                    selectedOrder.id,
                    { status: 'shipped', sent_amount: values.sent_amount },
                    (response) => {
                        notification.success({
                            message: 'Success',
                            description: 'Order status updated successfully'
                        });
                        handleFilter(); // refresh to see updated data
                        setIsModalVisible(false);
                        form.resetFields();
                    },
                    (error) => {
                        notification.error({
                            message: 'Error',
                            description: 'Failed to update order status'
                        });
                    }
                );
            })
            .catch((info) => {
                console.log('Validate Failed:', info);
            });
    };

    const handleModalCancel = () => {
        setIsModalVisible(false);
        form.resetFields();
    };

    const statusColorMap = {
        pending: '#ececec',
        seen: '#e4f9ff',
        confirmed: '#90EE90',
        preparing: '#FFDAB9',
        shipped: '#fee0ff',
        received: 'rgba(174,255,171,0.28)',
        cancelled: '#f7d7d7'
    };

    const statusIconMap = {
        pending: faClock,
        seen: faEye,
        confirmed: faCheckCircle,
        preparing: faTasks,
        shipped: faTruck,
        received: faClipboardCheck,
        cancelled: faCheckCircle
    };

    const convertGrToKgIfNeeded = (amount, unitType) => {
        if (unitType === 'gr') {
            const kg = (parseFloat(amount) / 1000).toFixed(1);
            return { amount: kg, unitType: 'kg' };
        }
        return { amount, unitType };
    };

    const columns = [
        {
            title: 'Inventory Group',
            dataIndex: ['branch_inventory_group', 'name'],
            key: 'group_name',
            render: (text, record) => (
                <div className={`patty text-2xl ${record.status === 'cancelled' ? 'line-through' : ''}`}>
                    {text}
                </div>
            )
        },
        {
            title: 'Ordered Amount',
            dataIndex: 'ordered_amount',
            key: 'ordered_amount',
            render: (text, record) => {
                const { unit_type: originalUnit } = record.branch_inventory_group;
                const { amount, unitType } = convertGrToKgIfNeeded(text, originalUnit);

                return (
                    <div
                        className={`font-bold ${
                            record.status === 'cancelled' ? 'line-through' : ''
                        } ${record.status === 'preparing' ? 'text-green-700' : ''}`}
                    >
                        {record.status === 'preparing' && (
                            <FontAwesomeIcon icon={faPlay} className="text-sm cursor-pointer mr-2" />
                        )}
                        {amount}
                        {unitType}
                    </div>
                );
            }
        },
        {
            title: 'Ordered At',
            dataIndex: 'ordered_at',
            key: 'ordered_at',
            render: (text, record) => (
                <div className={record.status === 'cancelled' ? 'line-through' : ''}>
                    {text}
                </div>
            )
        },
        {
            title: 'Branch',
            dataIndex: ['branch_inventory_group', 'branch', 'name'],
            key: 'branch_name',
            render: (text, record) => (
                <div className={record.status === 'cancelled' ? 'line-through' : ''}>
                    {text}
                </div>
            )
        },
        {
            title: 'Sent At',
            dataIndex: 'sent_at',
            key: 'sent_at',
            render: (text, record) => (
                <div className={record.status === 'cancelled' ? 'line-through' : ''}>
                    {text}
                </div>
            )
        },
        {
            title: 'Sent Amount',
            dataIndex: 'sent_amount',
            key: 'sent_amount',
            render: (text, record) =>
                text && (
                    <div className={`font-bold ${record.status === 'cancelled' ? 'line-through' : ''}`}>
                        {text}
                        {record.branch_inventory_group.unit_type}
                    </div>
                )
        },
        {
            title: 'Received At',
            dataIndex: 'received_at',
            key: 'received_at',
            render: (text, record) => (
                <div className={record.status === 'cancelled' ? 'line-through' : ''}>
                    {text}
                </div>
            )
        },
        {
            title: 'Received Amount',
            dataIndex: 'received_amount',
            key: 'received_amount',
            render: (text, record) =>
                text && (
                    <div className={`font-bold ${record.status === 'cancelled' ? 'line-through' : ''}`}>
                        {text}
                        {record.branch_inventory_group.unit_type}
                    </div>
                )
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => (
                <div className="flex flex-col items-start">
                    <div
                        style={{ backgroundColor: statusColorMap[text] }}
                        className="w-[150px] uppercase text-sm font-bold py-1 text-left pl-3 flex flex-row items-center"
                    >
                        <FontAwesomeIcon icon={statusIconMap[text]} className="mr-2" />
                        {text === 'received' ? 'done' : text}
                    </div>
                </div>
            )
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (text, record) => (
                <div>
                    {record.status === 'seen' && (
                        <Button
                            style={{ width: '170px', backgroundColor: '#002569', color: 'white' }}
                            onClick={() => handleStatusChange(record, 'preparing')}
                            loading={loadingOrders.includes(record.id)}
                        >
                            {loadingOrders.includes(record.id) ? 'Processing...' : 'Mark as Preparing'}
                        </Button>
                    )}
                    {record.status === 'preparing' && (
                        <Button
                            style={{ width: '150px', backgroundColor: '#383838', color: 'white' }}
                            onClick={() => handleStatusChange(record, 'shipped')}
                        >
                            Mark as Sent
                        </Button>
                    )}
                </div>
            )
        }
    ];

    // Group orders by date only
    const groupedOrders = _.groupBy(orders, (o) =>
        o.ordered_for
    );

    const groupedData = Object.entries(groupedOrders).map(([date, ords]) => ({
        date,
        orders: ords
    }));

    return (
        <DefaultContainer>
            <div className="patty text-2xl font-bold mb-4 flex justify-between items-center">
                <span>Vendor Orders</span>
                <Button
                    icon={<ReloadOutlined />}
                    loading={refreshing}
                    onClick={() => handleFilter()}
                >
                    Refresh
                </Button>
            </div>

            {/* DATE RANGE + STATUS FILTERS */}
            <div>
                <div className="mb-4 flex flex-col space-y-3">
                    {/* Date Range Picker */}
                    <RangePicker
                        onChange={(dates) => setDateRange(dates || [])}
                        format="YYYY-MM-DD"
                    />

                    {/* Status Filter */}
                    <div className="flex flex-row items-center space-x-2">
                        <div>Select Statuses:</div>
                        <Checkbox.Group
                            value={selectedStatuses}
                            onChange={(vals) => setSelectedStatuses(vals)}
                        >
                            {allPossibleStatuses.map((st) => (
                                <Checkbox
                                    className="uppercase"
                                    key={st}
                                    value={st}
                                    style={{ marginRight: '10px' }}
                                >
                                    {st}
                                </Checkbox>
                            ))}
                        </Checkbox.Group>
                    </div>

                    {/* "Filter" button */}
                    <Button type="primary" onClick={handleFilter}>
                        Filter
                    </Button>
                </div>
            </div>

            {/* Render your skeleton or spinner while loading */}
            <SkeletonWrapper loading={loading} skeleton={GenericSkeleton}>
                {groupedData.map(({ date, orders: dateOrders }) => (
                    <div key={date} className="mb-8">
                        <h2 className="font-bold text-xl mb-2">
                            Orders for: {date}
                        </h2>

                        {/* Show the spinner in the Table if you want: loading={loading} */}
                        <Table
                            columns={columns}
                            dataSource={dateOrders}
                            pagination={false}
                            rowKey="id"
                            loading={loading}
                            rowClassName={(record) =>
                                record.status === 'cancelled' ? 'line-through' : ''
                            }
                        />
                    </div>
                ))}
            </SkeletonWrapper>

            <Modal
                title="Send Order"
                visible={isModalVisible}
                onOk={handleModalOk}
                onCancel={handleModalCancel}
            >
                <Form form={form}>
                    <Form.Item
                        name="sent_amount"
                        label="Sent Amount"
                        rules={[{ required: true, message: 'Please input the sent amount!' }]}
                    >
                        {selectedOrder && (
                            <InputNumber
                                min={0}
                                style={{ width: '130px' }}
                                addonAfter={selectedOrder.branch_inventory_group.unit_type}
                            />
                        )}
                    </Form.Item>
                </Form>
            </Modal>
        </DefaultContainer>
    );
};

export default VendorOrders;
