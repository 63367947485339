import React, { useState } from 'react';
import { Button, Card, notification, Input } from "antd";
import {
    RiCouponLine, RiUserLine, RiCalendarEventLine, RiShoppingCartLine,
    RiMoneyDollarCircleLine, RiPercentLine, RiStopCircleLine, RiCheckboxCircleLine,
    RiCloseCircleLine, RiTimeLine, RiErrorWarningLine, RiCheckLine,
    RiEdit2Line, RiEditBoxFill, RiCloseLine
} from "@remixicon/react";
import Clipboard from "../../../../../components/Clipboard";
import moment from "moment";
import Coupon from "../../../../../http/Coupon";
import usePermission from "../../../../../layouts/usePermission";

const CouponComponent = ({
                             coupon,
                             header_color = "bg-green-200",
                             validate = false,
                             customSetUnApprovedCoupons,
                             paste
                         }) => {

    const [approving, setApproving] = useState(false);
    const [rejecting, setRejecting] = useState(false);

    // For inline-edit
    const [editing, setEditing] = useState(false);
    const [newCode, setNewCode] = useState(coupon.code);
    const [updatingCode, setUpdatingCode] = useState(false);

    const isBranchManager = usePermission(null, ["branch_manager"]);
    const isDiscountManager = usePermission(null, ["discount_manager"]);
    const isDiscountRequester = usePermission(null, ["discount_requester"]);

    const approve = () => {
        setApproving(true);
        Coupon.Approve(
            coupon.id,
            (response) => {
                customSetUnApprovedCoupons(response.data.data.unapproved_coupons);
                setApproving(false);
                notification['success']({
                    description: `Coupon for ${coupon.issuer.name} has been approved.`,
                });
            },
            (err) => {
                setApproving(false);
                notification['error']({
                    description: `Failed to approve coupon.`
                });
            }
        );
    }

    const reject = () => {
        setRejecting(true);
        Coupon.Reject(
            coupon.id,
            (response) => {
                customSetUnApprovedCoupons(response.data.data.unapproved_coupons);
                setRejecting(false);
                notification['success']({
                    description: `Coupon for ${coupon.issuer.name} has been rejected.`,
                });
            },
            (err) => {
                setRejecting(false);
                notification['error']({
                    description: `Failed to reject coupon.`
                });
            }
        );
    }

    // Inline edit handlers
    const handleEditClick = () => {
        setEditing(true);
    };

    const handleCodeSave = () => {
        // Make API call to update the code
        setUpdatingCode(true);
        Coupon.UpdateCode(
            coupon.id,
            newCode,
            (response) => {
                notification['success']({
                    description: `Code updated to '${newCode}'.`
                });
                // Optionally you could also update 'coupon.code' in your local state or parent
                coupon.code = newCode;
                setUpdatingCode(false);
                setEditing(false);
            },
            (err) => {
                notification['error']({
                    message: 'Error!',
                    description: err.response?.data.message,
                });
                setUpdatingCode(false);
            }
        );
    };

    const handleCodeChange = (e) => {
        setNewCode(e.target.value);
    };

    return (
        <div
            className={`m-2 border-gray-500 ${
                !validate && (coupon.balance === 0 || coupon.status !== 'active')
                    ? 'opacity-30'
                    : ''
            }`}
        >
            <div
                className={`w-full patty text-4xl flex justify-between px-3 pt-1 
                    border-gray-600 rounded-t-lg border-2 border-b-0 ${header_color}`}
            >
                <div className="flex items-center">

                    <RiCouponLine className="mr-2" />

                    {editing ? (
                        <div className="flex items-center">
                            <Input
                                value={newCode}
                                onChange={handleCodeChange}
                                style={{ width: 150, marginRight: 8, fontSize: 20 }}
                                disabled={updatingCode}
                            />
                            <Button
                                type="primary"
                                icon={<RiCheckLine />}
                                loading={updatingCode}
                                onClick={handleCodeSave}
                            />
                            <Button
                                icon={<RiCloseCircleLine />}
                                onClick={() => {
                                    setEditing(false);
                                    setNewCode(coupon.code);
                                }}
                            />
                        </div>
                    ) : (
                        <>
                            {coupon.code}
                            {/* Show edit icon if permission is allowed */}
                            {(isBranchManager || isDiscountManager || isDiscountRequester) && (
                                <RiEdit2Line
                                    className="ml-2 cursor-pointer"
                                    onClick={handleEditClick}
                                />
                            )}
                            <Clipboard text={coupon.code} paste={paste} />
                        </>
                    )}
                </div>

                <div className="flex items-center text-4xl pl-4">
                    {coupon.type === "percent" ? (
                        <RiPercentLine className="mr-2 hidden" />
                    ) : (
                        <RiMoneyDollarCircleLine className="mr-2" />
                    )}
                    {coupon.type === "percent" ? `%${coupon.percent}` : `$${coupon.amount}`}
                </div>
            </div>

            <hr className="border-gray-800" />

            <div className="px-3 py-3 bg-gray-200 border-gray-600 border-2 border-t-0 rounded-b-lg">
                <div className="flex items-center text-lg patty">
                    <RiUserLine className="mr-2" />
                    <b>By:</b> &nbsp;{coupon?.issuer?.name}
                </div>

                {coupon?.coupon_generate_preset?.discount_preset?.name && (
                    <div className="flex items-center text-lg patty">
                        <RiShoppingCartLine className="mr-2" />
                        <b>FROM:</b> &nbsp;{coupon?.coupon_generate_preset?.discount_preset?.name}
                    </div>
                )}

                {coupon?.full_name && (
                    <div className="flex items-center text-lg patty">
                        <RiUserLine className="mr-2" />
                        <b>FOR:</b> &nbsp;{coupon?.full_name}
                    </div>
                )}

                <div className="flex items-center text-lg patty">
                    <RiCheckboxCircleLine className="mr-2" />
                    <b>Balance:</b> &nbsp;
                    {coupon.type === "percent" ? (
                        coupon.balance === -1
                            ? "UNLIMITED"
                            : `${coupon.balance} Time${coupon.balance > 1 ? 's' : ''} Remaining`
                    ) : (
                        `$${coupon.balance}`
                    )}
                </div>

                <div className="flex items-center text-lg patty">
                    <RiCalendarEventLine className="mr-2" />
                    <b>Expiration:</b> &nbsp;
                    {coupon.expires_at
                        ? moment(coupon.expires_at).format('MM/DD/YYYY')
                        : 'Never Expires'}
                </div>

                {coupon.one_item === 1 && (
                    <div className="flex items-center text-lg patty">
                        <RiCheckboxCircleLine className="mr-2" />
                        <b>single item limit</b>
                    </div>
                )}

                {coupon.one_item === 2 && (
                    <div className="flex items-center text-lg patty">
                        <RiCheckboxCircleLine className="mr-2" />
                        <b>two items limit</b>
                    </div>
                )}

                {coupon.beer === "none" && (
                    <div className="flex items-center text-lg patty">
                        <RiStopCircleLine className="mr-2 text-red-700" />
                        <b>no beers are allowed</b>
                    </div>
                )}

                {coupon.allow_can_beer && (
                    <div className="flex items-center text-lg patty">
                        <RiCheckboxCircleLine className="mr-2" />
                        <b>can beers are allowed</b>
                    </div>
                )}

                {coupon.max_total_price > 0 && (
                    <div className="flex items-center text-lg patty">
                        <RiMoneyDollarCircleLine className="mr-2" />
                        CAN BE APPLIED TO ORDERS LESS THAN
                        <b className="pl-1">${coupon.max_total_price}</b>
                    </div>
                )}

                {coupon.max_coupon_price > 0 && coupon.type === 'percent' && (
                    <div className="flex items-center text-lg patty">
                        <RiMoneyDollarCircleLine className="mr-2" />
                        MAX DISCOUNT AMOUNT IS
                        <b className="pl-1">${coupon.max_coupon_price}</b>
                    </div>
                )}

                {(coupon.status === "disabled" || coupon.status === 'requires_confirmation') && (
                    <div className="flex items-center text-lg patty">
                        <RiErrorWarningLine className="mr-2" />
                        STATUS:
                        <b className="pl-1">
                            {coupon.status === "disabled" ? 'DISABLED' : 'REQUIRES CONFIRMATION'}
                        </b>
                    </div>
                )}

                {validate && (
                    <div className="w-full flex justify-between border-t pt-2 mt-2 border-gray-800">
                        <Button
                            type="primary"
                            icon={<RiCheckLine />}
                            loading={approving}
                            disabled={rejecting}
                            onClick={approve}
                        >
                            Accept
                        </Button>
                        <Button
                            type="primary"
                            icon={<RiCloseLine />}
                            danger
                            loading={rejecting}
                            disabled={approving}
                            onClick={reject}
                        >
                            Reject
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default CouponComponent;
