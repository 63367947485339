import React, { useEffect, useState } from 'react';
import { Table, notification, Tag, Progress } from 'antd';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import SkeletonWrapper from '../../../../components/skeletons/SkeletonWrapper';
import GenericSkeleton from '../../../../components/skeletons/GenericSkeleton';
import DefaultContainer from '../../../../components/DefaultContainer';
import ChecklistService from "../../../../http/ChecklistService";
import {RiCheckLine} from "@remixicon/react";

// Import the check icon from 'react-icons/ri'

const ManagerChecklistsReports = () => {
    const { branchId } = useParams(); // e.g. /dashboard/operations/ck/checklists/reports/:branchId
    const [loading, setLoading] = useState(true);
    const [reports, setReports] = useState([]); // array of { day, logs: [ { ... } ] }

    useEffect(() => {
        fetchReports();
    }, [branchId]);

    const fetchReports = () => {
        setLoading(true);
        ChecklistService.managerChecklistsReports(
            branchId,
            7, // last 7 days
            (resp) => {
                setLoading(false);
                setReports(resp.data.data);
            },
            (err) => {
                setLoading(false);
                notification.error({
                    message: 'Error',
                    description: 'Failed to load manager checklist reports.'
                });
            }
        );
    };

    const statusColorMap = {
        'Not Started': 'red',
        'In Progress': 'gold',
        'Finished': 'green',
        'No Items': 'blue'
    };

    // columns for the main table of logs
    const logsColumns = [
        {
            title: 'Checklist Name',
            dataIndex: 'checklist_name',
            key: 'checklist_name'
        },
        {
            title: 'Cycle Time',
            dataIndex: 'cycle_time',
            key: 'cycle_time',
            render: (val) => val || 'N/A'
        },
        {
            title: 'Progress',
            key: 'progress',
            render: (text, record) => (
                <div style={{ minWidth: 90 }}>
                    <Progress percent={record.progress} size="small" strokeWidth={10} />
                </div>
            )
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (status) => {
                const color = statusColorMap[status] || 'default';
                return <Tag color={color}>{status}</Tag>;
            }
        },
    ];

    // expanded table for items
    const expandedRowRender = (record) => {
        // record.items => array
        const itemColumns = [
            {
                title: 'Item Title',
                dataIndex: 'title',
                key: 'title'
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                // NEW: if item is checked, show green check icon; else show status text
                render: (val) => {
                    if (val === 'checked') {
                        return <RiCheckLine style={{ color: 'green', fontSize: '18px' }} />;
                    }
                    return val;
                }
            },
            {
                title: 'Checked By',
                dataIndex: 'checked_by',
                key: 'checked_by',
                render: (val) => val || '--'
            },
            {
                title: 'Checked At',
                dataIndex: 'checked_at',
                key: 'checked_at',
                render: (val) => val ? moment(val).format('h:mm A') : '--'
            }
        ];
        return (
            <div className="p-1 bg-gray-600">
                <Table
                    columns={itemColumns}
                    dataSource={record.items}
                    rowKey="id"
                    pagination={false}
                />
            </div>
        );
    };

    return (
        <DefaultContainer>
            <h2 className="text-xl font-bold uppercase mb-4">
                Manager Checklist Reports (Last 7 Days)
            </h2>

            <SkeletonWrapper loading={loading} skeleton={GenericSkeleton}>
                {reports.length === 0 ? (
                    <p>No data found.</p>
                ) : (
                    reports.map((dayData) => (
                        <div key={dayData.day} className="mb-8">
                            <h3 className="text-lg font-bold mb-2">Day: {dayData.day}</h3>
                            <Table
                                columns={logsColumns}
                                dataSource={dayData.logs}
                                rowKey="log_id"
                                pagination={false}
                                expandable={{
                                    expandedRowRender,
                                    rowExpandable: (record) => record.items && record.items.length > 0
                                }}
                            />
                        </div>
                    ))
                )}
            </SkeletonWrapper>
        </DefaultContainer>
    );
};

export default ManagerChecklistsReports;
