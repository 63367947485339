import React, { useEffect, useState } from 'react';
import { Button, Table, notification, Popconfirm } from 'antd';
import { Link, useHistory, useParams } from 'react-router-dom';
import { EditOutlined, DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons';
import ChecklistService from '../../../../http/ChecklistService';
import SkeletonWrapper from '../../../../components/skeletons/SkeletonWrapper';
import GenericSkeleton from '../../../../components/skeletons/GenericSkeleton';
import DefaultContainer from '../../../../components/DefaultContainer';

const ManageChecklists = () => {
    const { branchId } = useParams(); // e.g. /dashboard/operations/ck/checklists/manage/:branchId/manage
    const [loading, setLoading] = useState(true);
    const [checklists, setChecklists] = useState([]);
    const history = useHistory();

    useEffect(() => {
        if (!branchId) return;
        fetchChecklists();
    }, [branchId]);

    const fetchChecklists = () => {
        setLoading(true);
        ChecklistService.index(
            branchId,
            {}, // pass query params if needed, e.g. { pagination: "True" }
            (response) => {
                setChecklists(response.data.data);
                setLoading(false);
            },
            (error) => {
                setLoading(false);
                notification.error({
                    message: 'Error',
                    description: 'Failed to load checklists for this branch',
                });
            }
        );
    };

    const handleDelete = (checklistId) => {
        setLoading(true);
        ChecklistService.destroy(
            branchId,
            checklistId,
            (response) => {
                notification.success({
                    message: 'Success!',
                    description: response.data.message,
                });
                fetchChecklists();
            },
            (error) => {
                setLoading(false);
                notification.error({
                    message: 'Error!',
                    description: 'Failed to delete checklist',
                });
            }
        );
    };

    const onCreateChecklist = () => {
        // e.g. go to /dashboard/operations/ck/checklists/manage/:branchId/manage/new
        history.push(`/dashboard/operations/ck/checklists/manage/${branchId}/manage/new`);
    };

    const columns = [
        {
            title: 'Checklist Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Cycle Time',
            dataIndex: 'cycle_time',
            key: 'cycle_time',
            render: (val) => val || 'N/A',
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (text, record) => (
                <span>
                    <Link to={`/dashboard/operations/ck/checklists/manage/${branchId}/manage/${record.id}/edit`}>
                        <Button icon={<EditOutlined />} />
                    </Link>
                    <Popconfirm
                        title="Are you sure to delete this checklist?"
                        onConfirm={() => handleDelete(record.id)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button icon={<DeleteOutlined />} className="ml-2" />
                    </Popconfirm>
                </span>
            ),
        },
    ];

    return (
        <DefaultContainer>
            <div className="flex flex-col pt-3 w-full">
                <div className="text-xl font-bold text-black md:pl-1 flex flex-row mb-2 justify-between w-full">
                    <span className="uppercase">Manage Checklists</span>
                    <Button onClick={onCreateChecklist} style={{ borderRadius: '5px' }} type="primary">
                        <PlusCircleOutlined className="mr-2" />
                        Add Checklist
                    </Button>
                </div>

                <SkeletonWrapper loading={loading} skeleton={GenericSkeleton}>
                    <Table
                        dataSource={checklists}
                        columns={columns}
                        rowKey="id"
                        pagination={{ pageSize: 10 }}
                    />
                </SkeletonWrapper>
            </div>
        </DefaultContainer>
    );
};

export default ManageChecklists;
