import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
    Table,
    Button,
    notification,
    Input,
    Space,
    Tag
} from 'antd';
import { SearchOutlined, ReloadOutlined } from '@ant-design/icons';
import moment from 'moment';

import Checkout from "../../../../http/Checkout";
import MenuSkeleton from "../../../../components/skeletons/MenuSkeleton";
import SkeletonWrapper from "../../../../components/skeletons/SkeletonWrapper";
import DefaultContainer from "../../../../components/DefaultContainer";

const BranchCheckouts = () => {
    const { branchId } = useParams();

    // State
    const [checkouts, setCheckouts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [refreshing, setRefreshing] = useState(false);
    const [branch, setBranch] = useState(null);

    // For search
    const [searchTerm, setSearchTerm] = useState('');

    // For pagination
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10, // or 50 if you want the table to show 50 by default
        total: 0,
    });

    // Fetch data whenever branchId, pagination, or search changes
    useEffect(() => {
        if (branchId) {
            fetchCheckouts();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [branchId, pagination.current, pagination.pageSize, searchTerm]);

    // --------------------------------------------------
    // Fetch checkouts from the server
    // --------------------------------------------------
    const fetchCheckouts = () => {
        setLoading(true);
        setRefreshing(true);

        const params = {
            branch_id: branchId,
            search: searchTerm,
            page: pagination.current,
            page_size: searchTerm ? pagination.pageSize : 50, // If no search, get last 50
        };

        Checkout.BranchCheckouts(
            branchId,
            params,
            (response) => {
                // Adjust this according to your actual API structure
                const data = response.data.data.data;
                const meta = response.data.data.meta;
                setBranch(response.data.data.branch);

                setCheckouts(data);
                setPagination({
                    ...pagination,
                    total: meta.total,
                });
                setLoading(false);
                setRefreshing(false);
            },
            (error) => {
                notification.error({
                    message: 'Error',
                    description: 'Failed to load checkouts',
                });
                setLoading(false);
                setRefreshing(false);
            }
        );
    };

    // --------------------------------------------------
    // Handle search
    // --------------------------------------------------
    const handleSearch = (value) => {
        setPagination({ ...pagination, current: 1 });
        setSearchTerm(value);
    };

    // --------------------------------------------------
    // Handle table change (pagination, filters, etc.)
    // --------------------------------------------------
    const handleTableChange = (newPagination, filters, sorter) => {
        setPagination({
            ...pagination,
            current: newPagination.current,
            pageSize: newPagination.pageSize,
        });
    };

    // --------------------------------------------------
    // Fulfillment Status Tag Colors
    // --------------------------------------------------
    const renderFulfillmentTag = (status) => {
        // We have: 'created','proposed','reserved','prepared','completed','canceled','failed'
        let color;
        switch (status) {
            case 'created':
                color = 'geekblue';
                break;
            case 'proposed':
                color = 'purple';
                break;
            case 'reserved':
                color = 'cyan';
                break;
            case 'prepared':
                color = 'blue';
                break;
            case 'completed':
                color = 'green';
                break;
            case 'canceled':
                color = 'red';
                break;
            case 'failed':
                color = 'volcano';
                break;
            default:
                color = 'default';
                break;
        }
        return <Tag color={color}>{status}</Tag>;
    };

    // --------------------------------------------------
    // Payment Status Tag (optional)
    // --------------------------------------------------
    const renderPaymentTag = (status) => {
        // Example statuses: 'success', 'refunded', etc.
        let color;
        switch (status) {
            case 'success':
                color = 'green';
                break;
            case 'refunded':
                color = 'gold';
                break;
            default:
                color = 'magenta';
                break;
        }
        return <Tag color={color}>{status.toUpperCase()}</Tag>;
    };

    // --------------------------------------------------
    // Table columns
    // --------------------------------------------------
    const columns = [
        {
            title: 'Customer Name',
            dataIndex: 'first_name',
            key: 'first_name',
            render: (text, record) => (
                <span className="font-bold text-base">
          {text || record?.customer_name || 'N/A'}
        </span>
            ),
        },
        {
            title: 'Paid At',
            dataIndex: 'payed_at',
            key: 'payed_at',
            render: (value) => value || '—',
        },
        {
            title: 'Payment Status',
            dataIndex: 'payment_status',
            key: 'payment_status',
            render: (status) => renderPaymentTag(status),
        },
        {
            title: 'Fulfillment',
            dataIndex: 'fulfillment_status',
            key: 'fulfillment_status',
            render: (text) => (text ? renderFulfillmentTag(text) : <Tag>—</Tag>),
        },
        {
            title: 'Total',
            dataIndex: 'total',
            key: 'total',
            render: (value) => (
                <span className="font-bold text-base">
          ${Number(value || 0).toFixed(2)}
        </span>
            ),
        },
        /*
        {
            title: 'Actions',
            key: 'actions',
            render: (text, record) => (
                <Space>
                    <Button
                        size="small"
                        type="primary"
                        onClick={() => {
                            notification.info({
                                message: `Checkout #${record.id}`,
                                description: `You clicked an action for ${record.first_name}`,
                            });
                        }}
                    >
                        Action
                    </Button>
                </Space>
            ),
        },
         */
    ];

    // --------------------------------------------------
    // Render expanded row (like "Order" component)
    // --------------------------------------------------
    const expandedRowRender = (record) => {
        return (
            <div style={{ background: '#fafafa', margin: '0 16px', padding: '16px' }}>
                {record.items && record.items.length > 0 ? (
                    record.items.map((item, idx) => (
                        <div
                            key={idx}
                            className="flex flex-col items-start justify-center mb-2 bg-gray-100 p-2"
                        >
                            <div className="flex flex-row space-x-3">
                                <Tag color="blue">{item.quantity}x</Tag>
                                <div>{item.name}</div>
                                <div>${item.price}</div>
                            </div>
                            {item.formatted && (
                                <div>
                                    <div className="bg-blue-50 px-3 py-1 mt-2 rounded-lg">
                                        <div
                                            dangerouslySetInnerHTML={{ __html: item.formatted }}
                                            className="pl-2 border-l border-gray-100"
                                        ></div>
                                    </div>
                                </div>
                            )}
                        </div>
                    ))
                ) : (
                    <p>No items found.</p>
                )}

                <div className="mt-4 pb-1">
                    <strong>Transactions:</strong>
                </div>
                {record.transactions && record.transactions.length > 0 ? (
                    record.transactions.map((txn, idx) => (
                        <div key={idx} style={{ marginBottom: 8 }}>
              <span style={{ marginRight: 8 }} className="uppercase">
                {txn.payment_method}
              </span>
                            <strong>${txn.total}</strong> (
                            {txn.type === 'payment' ? 'Incoming' : 'Outgoing'})
                        </div>
                    ))
                ) : (
                    <p>No transactions found.</p>
                )}
            </div>
        );
    };

    return (
        <SkeletonWrapper loading={!branch} skeleton={MenuSkeleton}>
            {branch && (
                <DefaultContainer className="mx-auto max-w-8xl" append>
                    <div className="text-2xl font-bold mb-4 flex justify-between items-center">
                        <span>Branch Checkouts - {branch.name}</span>
                        <Button
                            icon={<ReloadOutlined />}
                            loading={refreshing}
                            onClick={() => fetchCheckouts()}
                        >
                            Refresh
                        </Button>
                    </div>

                    {/* SEARCH FIELD */}
                    <div style={{ marginBottom: 16, maxWidth: 300 }}>
                        <Input.Search
                            placeholder="Search by customer name..."
                            enterButton={<SearchOutlined />}
                            onSearch={(value) => handleSearch(value)}
                            allowClear
                        />
                    </div>

                    {/* TABLE */}
                    <Table
                        rowKey="id"
                        columns={columns}
                        dataSource={checkouts}
                        loading={loading}
                        pagination={{
                            current: pagination.current,
                            pageSize: pagination.pageSize,
                            total: pagination.total,
                            showSizeChanger: true,
                        }}
                        onChange={handleTableChange}
                        expandable={{
                            expandedRowRender: (record) => expandedRowRender(record),
                            rowExpandable: () => true,
                        }}
                    />
                </DefaultContainer>
            )}
        </SkeletonWrapper>
    );
};

export default BranchCheckouts;
