import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {Button, Table, Pagination, notification, Modal} from 'antd';
import {RiEditLine, RiDeleteBinLine, RiAddLine} from '@remixicon/react';
import Device from '../../../../http/Device';
import DefaultContainer from '../../../../components/DefaultContainer';
import Profile from "../../../../http/Profile";
import MenuSkeleton from "../../../../components/skeletons/MenuSkeleton";
import SkeletonWrapper from "../../../../components/skeletons/SkeletonWrapper";
import EndOfShiftReportShow from "../Inventory/EndOfShiftReports/EndOfShiftReportShow";

const UserNotices = () => {

    const [notices, setNotices] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        loadNotices()
    }, []);

    const loadNotices = () => {
        setLoading(true);
        Profile.Notices((response) => {
            setNotices(response.data.data);
            setLoading(false);
        }, (err) => {
            notification.error({
                message: 'Error',
                description: 'Failed to load notices.',
            });
            setLoading(false);
        });
    };

    return (
        <DefaultContainer className={"mx-auto max-w-8xl py-2 mt-4"} append>
            <div className={"text-xl font-bold pb-2"}>Notices & Warnings</div>
            <SkeletonWrapper loading={loading} skeleton={MenuSkeleton}>
                {notices && notices.length > 0 ?
                    notices.map(notice => {
                        return (<div className={"mb-3"}><EndOfShiftReportShow _report={notice} /></div>)
                    })
                :
                    <div>You have not received any notices.</div>
                }
            </SkeletonWrapper>
        </DefaultContainer>
    )
};

export default UserNotices;
