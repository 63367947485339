import React, {useEffect, useRef, useState} from 'react';
import {Switch, notification, Modal, Select, Input, Button, Form, List} from "antd";
import Division from "../../../../../http/Division";
import SkeletonWrapper from "../../../../../components/skeletons/SkeletonWrapper";
import GenericSkeleton from "../../../../../components/skeletons/GenericSkeleton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBatteryEmpty, faBeerMugEmpty, faCirclePlus, faCrown, faEdit, faHandPaper, faHistory, faMartiniGlassEmpty, faTimes, faTriangleExclamation} from "@fortawesome/free-solid-svg-icons";
import {useSelector} from "react-redux";
import {Link, useParams} from "react-router-dom";
import {motion} from 'framer-motion';
import Avatar from "../../../../../components/Avatar";
import Table from "ant-responsive-table";
import {SpinnerCircular} from "spinners-react";
import PriorityTag from "../../../../../components/PriorityTag";
import StatusTag from "../../../../../components/StatusTag";
import {Translate} from "../../../../../helpers/Translate";
import FormItem from "../../../../../components/Form/FormItem";
import UserWithAvatar from "../../../../../UserWithAvatar";
import Task from "../../../../../http/Task";
import Deliverables from "../TaskGroup/Tasks/Deliverables/Deliverables";
import TypeTag from "../../../../../components/TypeTag";
import TaskLogItem from "../../../../../components/log/TaskLogItem";

const MyTasks = () => {
    const user = useSelector(state => state.auth.user);
    const [tasks, setTasks] = useState(null);
    const [dataSource, setDataSource] = useState([]);
    const [completed, setCompleted] = useState(true);
    const [loading, setLoading] = useState(false);
    const [columns, setColumns] = useState([
        {
            title: 'ACCOUNTABLE',
            dataIndex: 'accountable',
            key: 'accountable',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'PROJECT',
            dataIndex: 'task_group',
            key: 'task_group',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'RESPONSIBLE',
            dataIndex: 'responsible',
            key: 'responsible',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'TEAM MEMBERS',
            dataIndex: 'employees',
            key: 'employees',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'DESCRIPTION',
            dataIndex: 'description',
            key: 'description',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'MORE INFO',
            dataIndex: 'more_info',
            key: 'more_info',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'TYPE',
            dataIndex: 'type',
            key: 'type',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'PRIORITY',
            dataIndex: 'priority',
            key: 'priority',
            sorter: (a, b) => a.priority.props.priority.localeCompare(b.priority.props.priority),
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'STATUS',
            dataIndex: 'status',
            key: 'status',
            sorter: (a, b) => a.status.props.status.localeCompare(b.status.props.status),
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'DELIVERABLES / KEY RESULT',
            dataIndex: 'deliverables',
            key: 'deliverables',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: '',
            dataIndex: 'actions',
            key: 'actions',
            showOnResponse: true,
            showOnDesktop: true
        },

    ]);
    const [isLoading, setIsLoading] = useState(false);
    const [editingTask, setEditingTask] = useState(null);
    const [creating, setCreating] = useState(false);
    const [editing, setEditing] = useState(false);
    const [form] = Form.useForm();
    const [validationFields, setValidateFields] = useState(null);
    const [saving, setSaving] = useState(false);
    const [logTask, setLogTask] = useState(null);
    const [showLog, setShowLog] = useState(false);

    const load = (data) => {
        const d = data.tasks;
        setCompleted(data.completed);
        setTasks(d);

        const TasksDataSource = [];
        d.map(task => {
            const canEdit = (d.accountable_division?.users && d.accountable_division?.users.find(u => u.id == user.id)) || (task.responsible_division?.users && task.responsible_division?.users.find(u => u.id == user.id));

            return TasksDataSource.push({
                key: task.id,
                accountable: <Link to={`/dashboard/division/${task.task_group.accountable_division.id}`}><div className={"font-bold"}>{task.task_group.accountable_division.name}</div></Link>,
                task_group: <Link to={`/dashboard/task_group/${task.task_group.id}`}><div className={"font-bold"}>{task.task_group.description}</div></Link>,
                responsible: <div className={"font-bold"}>{task.responsible_division.name}</div>,
                employees: <div>{task.responsible_division.users.map(user => {
                    return (<UserWithAvatar role={user.pivot.role} avatar={user.avatar.url} name={user.name}/>)
                })}</div>,
                type: <TypeTag type={task.type}/>,
                description: task.description,
                more_info: task.more_info,
                priority: <PriorityTag priority={task.priority}/>,
                status: <StatusTag status={task.status}/>,
                deliverables: <Deliverables canEdit={canEdit} task_id={task.id} deliverables={task.deliverables} reload={reload}/>,

                actions: (
                    <div className='table-actions flex flex-row'>
                        <div>
                            <div className={"xl:hidden"}>
                                <Button onClick={() => onEdit(task)} className={"lg:hidden"}>
                                    <div className="flex flex-row items-center justify-start xl:justify-center">
                                        <FontAwesomeIcon icon={faEdit} className="text-sm"/>
                                        <div className={"ml-1"}>Edit</div>
                                    </div>
                                </Button>
                            </div>
                            <div className="flex-row items-center justify-start xl:justify-center hidden xl:flex mx-1 cursor-pointer" onClick={() => onEdit(task)}>
                                <FontAwesomeIcon icon={faEdit} className="text-sm"/>
                                <div className={"lg:hidden ml-1"}>Edit</div>
                            </div>
                        </div>

                        <div className={"ml-2"}>
                            <div className={"xl:hidden"}>
                                <Button onClick={() => onLog(task)} className={"lg:hidden"}>
                                    <div className="flex flex-row items-center justify-start xl:justify-center">
                                        <FontAwesomeIcon icon={faHistory} className="text-sm"/>
                                        <div className={"ml-1"}>View Logs</div>
                                    </div>
                                </Button>
                            </div>
                            <div className="flex-row items-center justify-start xl:justify-center hidden xl:flex mx-1 cursor-pointer" onClick={() => onLog(task)}>
                                <FontAwesomeIcon icon={faHistory} className="text-sm"/>
                                <div className={"lg:hidden ml-1"}>View Logs</div>
                            </div>
                        </div>
                    </div>
                ),
            });
        });

        setDataSource(TasksDataSource);
    }

    const reload = () => {
        setLoading(true);
        Division.MyTasks(completed, (response) => {
            load(response.data.data);
            setLoading(false);
        }, (err) => {
            notification['error']({
                message: 'Error!',
                description: err.response?.data.message,
            });
            setLoading(false);
        });
    }

    useEffect(() => {
        if (completed != null) {
            reload();
        }
    }, [completed]);

    useEffect(() => {
        reload();
    }, []);

    const onCompleteChange = (c) => {
        if (loading) return;
        setCompleted(c);
        console.log("completed", c);
    }

    const onCancel = () => {
        if (saving) return;
        setCreating(false);
        setEditing(false);
        setEditingTask(null);
        setValidateFields(null);
    }

    const onCreateTask = () => {
        form.resetFields();
        setEditingTask(null);
        setCreating(true);
    }

    const onEdit = (task) => {
        form.resetFields();
        setValidateFields(null);
        setEditing(true);
        setEditingTask(task);
    }

    const handleSubmit = () => {
        setSaving(true);
        const data = {'task_group_id': tasks.id, ...form.getFieldsValue()};
        if (creating) {
            // there are no creating tasks here
        } else {
            Task.Update(editingTask.id, data, (response) => {
                reload();
                setLoading(false);
                setSaving(false);
                setEditing(false);
                notification['success']({
                    description: 'Task updated successfully.'
                });

            }, (err) => {

                if (!err.response?.data?.errors) {
                    notification['error']({
                        message: 'Error!',
                        description: err.response?.data.message,
                    });
                }

                setValidateFields(err.response.data.errors)
                setSaving(false);
            });
        }
    }

    const onLogCancel = () => {
        setShowLog(false)
    }

    const onLog = (task) => {
        setLogTask(task);
        setShowLog(true);
    }

    console.log("editingTask", editingTask);

    return (

        <div className={"flex flex-col w-full h-screen tasks"}>

            {logTask &&
                <Modal title={<div>LOGS FOR <b>{logTask.description.toUpperCase()}</b></div>}
                       onCancel={onLogCancel}
                       onOk={onLogCancel}
                       open={showLog}
                       width={"90%"}
                       style={{maxWidth: "800px"}}
                       cancelButtonProps={{ style: { display: 'none' } }}
                >
                    <List
                        dataSource={logTask.logs}
                        renderItem={(item) => (
                            <List.Item>
                                <TaskLogItem item={item} />
                            </List.Item>
                        )}
                    />
                </Modal>
            }

            {tasks && (creating || (editing && editingTask)) &&
                <Modal title={<div>{editing ? 'EDITING' : 'CREATE'} TASK FOR <b>{editingTask.description.toUpperCase()}</b></div>}
                       onCancel={onCancel}
                       onOk={handleSubmit}
                       open={creating || editing}
                       okText={editing ? 'SAVE' : "CREATE"}
                       okButtonProps={{danger: true, size: "large", loading: saving}}
                       cancelButtonProps={{size: "large", disabled: saving}} className={"task"}
                >
                    <Form form={form} layout='vertical'>
                        <div className="flex flex-col w-full">


                            <div className={"flex flex-row mt-2"}>
                                <div className="w-40 text-right font-bold mr-2 mt-1">TYPE:</div>
                                <div className={"w-64"}>
                                    <FormItem
                                        name="type"
                                        errors={validationFields}
                                        initialValue={editingTask ? editingTask.type : null}
                                    >
                                        <Select
                                            className={"w-full"}
                                            options={['idea', 'issue'].map(type => {
                                                return ({value: type, label: type})
                                            })}
                                        />
                                    </FormItem>
                                </div>
                            </div>

                            <div className={"flex flex-row mt-2"}>
                                <div className="w-40 text-right font-bold mr-2 mt-1">DESCRIPTION:</div>
                                <div className={"w-64"}>
                                    <FormItem
                                        name="description"
                                        errors={validationFields}
                                        initialValue={editingTask?.description}
                                    >
                                        <Input placeholder="" disabled={isLoading}/>
                                    </FormItem>
                                </div>
                            </div>

                            <div className={"flex flex-row mt-2"}>
                                <div className="w-40 text-right font-bold mr-2 mt-1">MORE INFO:</div>
                                <div className={"w-64"}>
                                    <FormItem
                                        name="more_info"
                                        errors={validationFields}
                                        initialValue={editingTask?.more_info}
                                    >
                                        <Input placeholder="" disabled={isLoading}/>
                                    </FormItem>
                                </div>
                            </div>

                            <div className={"flex flex-row mt-2"}>
                                <div className="w-40 text-right font-bold mr-2 mt-1">PRIORITY:</div>
                                <div className={"w-64"}>
                                    <FormItem
                                        name="priority"
                                        errors={validationFields}
                                        initialValue={editingTask ? editingTask.priority : null}
                                    >
                                        <Select
                                            className={"w-full"}
                                            options={['low', 'medium', 'high'].map(priority => {
                                                return ({value: priority, label: priority})
                                            })}
                                        />
                                    </FormItem>
                                </div>
                            </div>

                            <div className={"flex flex-row mt-2"}>
                                <div className="w-40 text-right font-bold mr-2 mt-1">STATUS:</div>
                                <div className={"w-64"}>
                                    <FormItem
                                        name="status"
                                        errors={validationFields}
                                        initialValue={editingTask ? editingTask.status : null}
                                    >
                                        <Select
                                            className={"w-full"}
                                            options={['not started', 'in progress', 'completed'].map(status => {
                                                return ({value: status, label: status})
                                            })}
                                        />
                                    </FormItem>
                                </div>
                            </div>

                        </div>
                    </Form>
                </Modal>
            }

            <SkeletonWrapper loading={!tasks} skeleton={GenericSkeleton}>
                {tasks &&
                    <div className={"flex flex-col p-0 py-1"}>

                        <div className={"w-full flex flex-col lg:flex-row items-center justify-between text-3xl font-bold uppercase bg-white mb-1 px-2 py-1 border-b-2 border-black"}>
                            <div className={"patty flex flex-row items-center text-black text-3xl lg:text-4xl"} style={{letterSpacing: "-2px"}}>
                                <div className={"flex flex-col"}>
                                    <div className={"flex flex-row items-center"}>
                                        My Tasks
                                    </div>
                                </div>
                                {loading && <SpinnerCircular size={25} thickness={200} speed={80} color="rgba(0, 0, 0, 1)" secondaryColor="rgba(0, 0, 0, 0.2)" className={"-mt-1 ml-2"}/>}
                            </div>

                            <div className={"flex flex-row mb-1 lg:mb-0"}>
                                <div className={"px-1 py-1 pl-3 rounded-2xl flex flex-row ml-1 bg-blue-100 items-center justify-center text-xs border border-gray-500"}>
                                    <div className={"uppercase mr-2 font-bold"}>Completed</div>
                                    <Switch checked={completed} size={"default"} onChange={onCompleteChange}/>
                                </div>
                            </div>
                        </div>

                        {tasks.length == 0 ?
                            <div className={`flex flex-row items-center justify-center bg-gray-50 py-3 px-3 text-gray-500 shadow-sm text-xs uppercase`}>
                                <FontAwesomeIcon icon={faMartiniGlassEmpty} className="text-sm mr-2" style={{marginTop: "2px"}} aria-hidden="true"/>
                                There are no active tasks in this tasks.
                            </div>
                            :
                            <div className={`flex flex-col `}>
                                <Table antTableProps={{dataSource: dataSource, columns: columns, pagination: false}} mobileBreakPoint={1200}/>
                            </div>
                        }

                    </div>
                }
            </SkeletonWrapper>
        </div>
    );
};

export default MyTasks;