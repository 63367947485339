import React, {useEffect, useState} from 'react';
import {Button, Collapse, DatePicker, Divider, Form, Input, notification, Pagination, Progress} from 'antd';
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import T from "../../components/T";
import Bloggers from "../../http/Bloggers";
import {SpinnerCircular} from "spinners-react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAlignLeft, faTriangleExclamation} from "@fortawesome/free-solid-svg-icons";
import FormItem from "../../components/Form/FormItem";
import moment from "moment";
import {Select} from 'antd';
import FormError from "../../components/Form/FormError";
import Settings from "../../http/Settings";

const {Option} = Select;
const hours = Array.from(Array(13).keys()).slice(11); // Generate an array of hours from 11 to 23

const ValidateOrder = () => {
    const dispatch = useDispatch();
    const {token} = useParams();
    const [loading, setLoading] = useState(false);
    const [validationFields, setValidationFields] = useState(null);
    const [username, setUsername] = useState(null);
    const [disabledDates, setDisabledDates] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(null);
    const [submitting, setSubmitting] = useState(false);
    const [form] = Form.useForm();
    const [invitation, setInvitation] = useState(null);

    useEffect(() => {
        get_invitation();
    }, []);

    const disabledDate = current => {
        const today = moment().startOf('day');
        const currentDate = moment(current).startOf('day');
        if (currentDate.isBefore(today)) return true;
        if (disabledDates.includes(moment(current).format("YYYY-MM-DD"))) {
            return true;
        }

        return false;
    };

    const get_invitation = () => {
        setLoading(true);
        Bloggers.GetInvitation(token, (response) => {
            setLoading(false);
            console.log("response.data.data:", response.data.data);
            if(response.data?.data?.invitation) {
                console.log("invitation detected: ", response.data.data.invitation);
                setInvitation(response.data.data.invitation);
            }
            else {
                setUsername(response.data.data.username);
                setDisabledDates(response.data.data.disabled_dates);
            }
        }, (err) => {
            setLoading(false);
            setError(err?.response?.data?.message);
        });
    }

    const handleSubmit = () => {
        console.log({...form.getFieldsValue(true)});
        setSubmitting(true);
        Bloggers.AcceptInvitation(token, {...form.getFieldsValue(true)}, (response) => {
            setSubmitting(false);
            notification['success']({
                message: 'Success!',
                placement: "bottomRight",
                description:
                    `Invitation has been accepted!`,
            });
            setInvitation(response.data.data.invitation);

        }, (err) => {
            setValidationFields(err.response.data.errors);
            setSubmitting(false);
        });
    }

    return (
        <div className="flex flex-col items-center justify-center mt-4">
            <div className="row w-full">
                <div className="container flex flex-col mx-auto pb-10 pt-2 px-2 " style={{maxWidth: "800px"}}>
                    <h1 className="text-2xl font-bold text-black pl-3 uppercase"><T cap>Invitation Details</T></h1>
                    <div className="w-full flex flex-col lg:flex-row">
                        {loading ?
                            <div className="p-10 flex flex-row items-center text-base uppercase justify-center bg-white border border-gray-200 w-full">
                                <SpinnerCircular size={20} color="rgba(0, 0, 0, 1)" secondaryColor="rgba(0, 0, 0, 0.44)" className="h-5 mr-2"/>
                                Loading
                            </div>
                            :
                            <div className="rounded-4xl flex-1 border shadow-xl bg-white p-4 flex flex-col items-center justify-center rounded-lg text-base">
                                {error ?
                                    <div className="text-red-900 text-base py-10 px-5">
                                        <FontAwesomeIcon icon={faTriangleExclamation} className="mr-2 text-lg" aria-hidden="true"/>
                                        {error}
                                    </div>
                                    : (
                                        username && !invitation ?
                                            <div className="flex flex-col items-start">
                                                <div className="mb-6">
                                                    Welcome to Patty Slaps, <b>{username}</b>!
                                                </div>

                                                <div className="mb-3">
                                                    We're thrilled that you're interested in trying our delicious free burgers. To schedule your burger tasting experience, please provide us with a few details and your preferred contact information.
                                                </div>

                                                <div className="mb-2">
                                                    Please fill out the following information:
                                                </div>

                                                <Form form={form} onFinish={handleSubmit} layout='vertical' className={"w-full"}>
                                                    <FormError error={error}/>

                                                    <div className="p-4 bg-gray-50 rounded-xl w-full border border-gray-300 shadow-md">
                                                        <div className="text-base font-bold">
                                                            <div className="mb-1">
                                                                Phone Number:
                                                            </div>
                                                            <FormItem name={"phone"}
                                                                      label={""}
                                                                      errors={validationFields}
                                                                      disabled={false}
                                                                      initialValue={''}
                                                            >
                                                                <Input  prefix={<FontAwesomeIcon icon={faAlignLeft} className="text-base"/>} placeholder={"Phone Number"} disabled={submitting}/>
                                                            </FormItem>
                                                        </div>

                                                        <div className="text-base font-bold -mt-2">
                                                            <div className="mb-1">
                                                                Attendance Date:
                                                            </div>
                                                            <FormItem name={"attend_date"}
                                                                      label={""}
                                                                      errors={validationFields}
                                                                      disabled={submitting}
                                                                      initialValue={''}
                                                            >
                                                                <DatePicker disabledDate={disabledDate} disabled={submitting}/>
                                                            </FormItem>
                                                        </div>

                                                        <div className="text-base font-bold -mt-2">
                                                            <div className="mb-1">
                                                                Select Visit Hour:
                                                            </div>
                                                            <FormItem name={"attend_hour"}
                                                                      label={""}
                                                                      errors={validationFields}
                                                                      disabled={false}
                                                                      initialValue={''}
                                                            >
                                                                <Select disabled={submitting}>
                                                                    <Option key={"11 AM"} value={"11 AM"}>11 AM</Option>
                                                                    <Option key={"12 PM"} value={"12 PM"}>12 PM</Option>
                                                                    <Option key={"1 PM"} value={"1 PM"}>1 PM</Option>
                                                                    <Option key={"2 PM"} value={"2 PM"}>2 PM</Option>
                                                                    <Option key={"3 PM"} value={"3 PM"}>3 PM</Option>
                                                                    <Option key={"4 PM"} value={"4 PM"}>4 PM</Option>
                                                                    <Option key={"5 PM"} value={"5 PM"}>5 PM</Option>
                                                                    <Option key={"6 PM"} value={"6 PM"}>6 PM</Option>
                                                                    <Option key={"7 PM"} value={"7 PM"}>7 PM</Option>
                                                                    <Option key={"8 PM"} value={"8 PM"}>8 PM</Option>
                                                                    <Option key={"9 PM"} value={"9 PM"}>9 PM</Option>
                                                                    <Option key={"10 PM"} value={"10 PM"}>10 PM</Option>
                                                                </Select>
                                                            </FormItem>
                                                        </div>

                                                        <div className="-mt-2">
                                                            <Button htmlType='submit' size='default' type='primary' key='submit' loading={submitting} className=''>
                                                                <T>Accept Invitation</T>
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </Form>

                                                <div className="mb-2 mt-4">
                                                    Once we receive your details, we'll confirm your visit and look forward to serving you our mouthwatering burgers. If you have any questions or need further assistance, feel free to reach out to us at [contact number].
                                                </div>

                                                <div className="mb-2">
                                                    Thank you for choosing Patty Slaps, and we can't wait to have you as our guest!
                                                </div>

                                            </div>
                                        : (
                                                invitation?
                                                    <div className={"w-full flex flex-col"}>
                                                        <div>
                                                            Hello <b>{invitation.username}</b>,
                                                        </div>

                                                        <div className="mt-4">
                                                            You have accepted our invitation to visit our store on <b>{invitation.will_attend_at.split(" ")[0]}</b>.
                                                            For the Hour of visit you chose <b>{invitation.will_attend_at_hour}</b>.
                                                        </div>

                                                        <div className="mb-2 mt-4 text-sm">
                                                            Thank you for choosing Patty Slaps, and we can't wait to have you as our guest!
                                                        </div>
                                                    </div>
                                                :null
                                        )
                                    )}
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ValidateOrder;

