import React, { useEffect, useState } from 'react';
import { Button, Form, notification, Popconfirm, Tag, Select } from "antd";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";
import {RiAddCircleLine, RiEditLine, RiFileCopyLine, RiShieldUserLine, RiUserLine, RiStopCircleLine, RiCalendarEventLine, RiMoneyDollarCircleLine, RiPercentLine, RiExchangeDollarLine, RiMoneyDollarCircleFill, RiMoneyDollarBoxLine} from "@remixicon/react";
import SkeletonWrapper from "../../../../../components/skeletons/SkeletonWrapper";
import GenericSkeleton from "../../../../../components/skeletons/GenericSkeleton";
import CouponGeneratePreset from "../../../../../http/CouponGeneratePreset";
import GeneratePresetModal from "./GeneratePresetModal";
import Amount from "./Amount";
import ManageRolesModal from "./ManageRolesModal";
import ManageDisabledFoodsModal from "./ManageDisabledFoodsModal";
import DarkGenericSkeleton from "../../../../../components/skeletons/DarkGenericSkeleton";

const { Option } = Select;

const ManageGeneratePresets = () => {
    const [form] = Form.useForm();
    const [manageForm] = Form.useForm();
    const user = useSelector(state => state.auth.user);
    const [loading, setLoading] = useState(true);
    const [couponGeneratePresets, setCouponGeneratePresets] = useState([]);
    const [filteredPresets, setFilteredPresets] = useState([]);
    const [discountPresets, setDiscountPresets] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [foods, setFoods] = useState([]);
    const [editCouponGeneratePreset, setEditCouponGeneratePreset] = useState(null);
    const [couponGeneratePresetModalVisible, setCouponGeneratePresetModalVisible] = useState(false);
    const [manageRolesModalVisible, setManageRolesModalVisible] = useState(false);
    const [manageDisabledFoodsVisible, setManageDisabledFoodsVisible] = useState(false);
    const [validationFields, setValidationFields] = useState(null);
    const [saving, setSaving] = useState(false);
    const [rolesSaving, setRolesSaving] = useState(false);
    const [selectedDepartment, setSelectedDepartment] = useState('all');

    useEffect(() => { reload(true) }, []);

    const reload = (forceShowLoading = false) => {
        if (!couponGeneratePresets || forceShowLoading)
            setLoading(true);
        CouponGeneratePreset.Index((response) => {
            setLoading(false);
            setCouponGeneratePresets(response.data.data.coupon_generate_presets);
            setDiscountPresets(response.data.data.discount_presets);
            setDepartments(response.data.data.departments);
            setFoods(response.data.data.foods);
            setFilteredPresets(response.data.data.coupon_generate_presets);
        }, (err) => {
            setLoading(false);
        });
    }

    const onCouponGeneratePresetModalCancel = () => {
        setCouponGeneratePresetModalVisible(false);
        setEditCouponGeneratePreset(null);
        setValidationFields(null);
        form.resetFields();
    }

    const onCouponGeneratePresetEdit = (preset) => {
        setEditCouponGeneratePreset(preset);
        setValidationFields(null);
        setCouponGeneratePresetModalVisible(true);
    }

    const onCouponGeneratePresetCreate = () => {
        setEditCouponGeneratePreset(null);
        setCouponGeneratePresetModalVisible(true);
    }

    const onManageRoles = (preset) => {
        setEditCouponGeneratePreset(preset);
        setManageRolesModalVisible(true);
    }

    const onManageRolesModalCancel = () => {
        setManageRolesModalVisible(false);
        manageForm.resetFields();
    }

    const onRolesSave = (values) => {
        setRolesSaving(true);
        CouponGeneratePreset.SyncRoles(editCouponGeneratePreset.id, values, (response) => {
            setLoading(false);
            setRolesSaving(false);
            setManageRolesModalVisible(false);
            reload();
        }, (err) => {
            setRolesSaving(false);
        });
    }

    const onSave = (values, isEdit) => {
        if (isEdit) {
            setSaving(true);
            CouponGeneratePreset.Update(editCouponGeneratePreset.id, values, (response) => {
                reload();
                const couponGenerate_preset = response.data.data;
                notification['success']({
                    message: 'Success!',
                    placement: "bottomRight",
                    description: `CouponGenerate Preset ${couponGenerate_preset.name} has been updated!`,
                });
                setSaving(false);
                setCouponGeneratePresetModalVisible(false);
                setValidationFields(null);
            }, (err) => {
                setValidationFields(err.response.data.errors);
                setSaving(false);
            });
        } else {
            setSaving(true);
            CouponGeneratePreset.Store(values, (response) => {
                reload();
                const couponGenerate_preset = response.data.data;
                notification['success']({
                    message: 'Success!',
                    placement: "bottomRight",
                    description: `CouponGenerate Preset ${couponGenerate_preset.name} has been created!`,
                });
                setSaving(false);
                setCouponGeneratePresetModalVisible(false);
                setValidationFields(null);
                form.resetFields();
            }, (err) => {
                setValidationFields(err.response.data.errors);
                setSaving(false);
            });
        }
    }

    const onManageDisabledFoods = (preset) => {
        setEditCouponGeneratePreset(preset);
        setManageDisabledFoodsVisible(true);
    }

    const onManageDisabledFoodsModalCancel = () => {
        setManageDisabledFoodsVisible(false);
    }

    const onDisabledFoodsSave = (disabledFoods) => {
        setSaving(true);
        CouponGeneratePreset.SyncDisabledFoods(editCouponGeneratePreset.id, { disabled_foods: disabledFoods }, (response) => {
            reload();
            notification['success']({
                message: 'Success!',
                placement: "bottomRight",
                description: 'Disabled foods have been updated!',
            });
            setSaving(false);
            setManageDisabledFoodsVisible(false);
        }, (err) => {
            notification['error']({
                message: 'Error!',
                placement: "bottomRight",
                description: err.response.data.message,
            });
            setSaving(false);
        });
    }

    const handleDuplicateConfirm = (preset) => {
        const duplicatePreset = { ...preset }; // Shallow copy of preset object
        duplicatePreset.id = null; // Clear ID for creation of new preset
        duplicatePreset.name = `${preset.name} Copy`; // Set the name to "name Copy"
        setSaving(true);
        CouponGeneratePreset.Store(duplicatePreset, (response) => {
            reload();
            notification['success']({
                message: 'Success!',
                placement: "bottomRight",
                description: `CouponGenerate Preset ${response.data.data.name} has been duplicated!`,
            });
            setSaving(false);
        }, (err) => {
            setValidationFields(err.response.data.errors);
            setSaving(false);
        });
    }

    const handleDepartmentChange = (value) => {
        setSelectedDepartment(value);
        if (value === 'all') {
            setFilteredPresets(couponGeneratePresets);
        } else {
            setFilteredPresets(couponGeneratePresets.filter(preset => preset.department_roles.some(role => role.department_id === value)));
        }
    };

    return (
        <motion.div
            initial={{ opacity: 0, scale: 1.1, y: 0 }}
            animate={{ opacity: 1, scale: 1, y: 0 }}
            className={`w-full less-margin mx-auto max-w-8xl mt-2`}
        >
            <GeneratePresetModal visible={couponGeneratePresetModalVisible} onCancel={onCouponGeneratePresetModalCancel} validationFields={validationFields} editCouponGeneratePreset={editCouponGeneratePreset} discountPresets={discountPresets} onSave={onSave} saving={saving} form={form} />
            <ManageRolesModal visible={manageRolesModalVisible} onCancel={onManageRolesModalCancel} editCouponGeneratePreset={editCouponGeneratePreset} departments={departments} onSave={onRolesSave} saving={rolesSaving} form={manageForm} />
            <ManageDisabledFoodsModal visible={manageDisabledFoodsVisible} onCancel={onManageDisabledFoodsModalCancel} editCouponGeneratePreset={editCouponGeneratePreset} foods={foods} onSave={onDisabledFoodsSave} preset={editCouponGeneratePreset} saving={saving} form={manageForm} />

            <div className="flex flex-col mx-auto w-full">
                <div className="w-full flex flex-col lg:flex-row">
                    <div className="rounded-lg flex-1 p-3 flex flex-col items-center justify-center pt-3 pb-2 w-full">
                        <SkeletonWrapper loading={loading} skeleton={DarkGenericSkeleton}>
                            <div className="text-left w-full mb-3">
                                <div className="font-bold text-lg flex flex-row items-center">
                                    Coupon Generate Presets
                                    <RiAddCircleLine className="ml-2 text-2xl cursor-pointer" aria-hidden="true" onClick={onCouponGeneratePresetCreate} />
                                </div>
                                <div>These coupon generate presets are templates that can be assigned to different roles enabling them to generate coupons on a daily basis.</div>
                            </div>

                            {filteredPresets && filteredPresets.length > 0 ?
                            <div className="text-left w-full mb-3">
                                <Select placeholder="Filter by Department" onChange={handleDepartmentChange} className="w-full">
                                    <Option value="all">All Departments</Option>
                                    {departments.map(department => (
                                        <Option key={department.id} value={department.id}>{department.name}</Option>
                                    ))}
                                </Select>
                            </div>
                            :null}

                            {filteredPresets && filteredPresets.length > 0 ?
                                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 w-full">
                                    {filteredPresets.map(couponGeneratePreset => {
                                        return (
                                            <div key={couponGeneratePreset.id} className="border border-gray-300 rounded-lg shadow-lg p-4 pb-2 bg-white flex flex-col">
                                                <div className="flex justify-between items-center">
                                                    <div className="text-xl font-bold">
                                                        {couponGeneratePreset.name}
                                                    </div>
                                                    {couponGeneratePreset.status == 0 && <Tag color="red" style={{ fontSize:"14px" }}>Disabled</Tag>}
                                                    {couponGeneratePreset.status == 1 && <Tag color="green" style={{ fontSize:"14px" }}>Active</Tag>}
                                                    {couponGeneratePreset.status == -1 && <Tag color="yellow" style={{ fontSize:"14px" }}>Pending</Tag>}
                                                </div>

                                                <div className="mt-2 text-gray-600 flex-1">

                                                        {couponGeneratePreset.issuer ?
                                                        <div className="flex items-center mb-2">
                                                            <RiUserLine className="mr-2 text-lg" /> Issuer: {couponGeneratePreset.issuer.name}
                                                        </div>
                                                    :null}

                                                    <div className="flex items-center mb-2">
                                                        {couponGeneratePreset.type == "percent"?<RiPercentLine className="mr-2 text-lg" />:<RiMoneyDollarBoxLine className="mr-2 text-lg" />} Type: {couponGeneratePreset.type}
                                                    </div>

                                                    {couponGeneratePreset.type === "balance" ?
                                                        <div className="flex items-center mb-2">
                                                            <RiMoneyDollarCircleLine className="mr-2 text-lg" /> Amount: <Amount min={couponGeneratePreset.min_amount} max={couponGeneratePreset.max_amount} />
                                                        </div>
                                                        :
                                                        <>
                                                            <div className="flex items-center mb-2">
                                                                <RiPercentLine className="mr-2 text-lg" /> Discount Percent: <Amount sign={"%"} min={couponGeneratePreset.min_amount} max={couponGeneratePreset.max_amount} />
                                                            </div>
                                                            <div className="flex items-center mb-2">
                                                                <RiCalendarEventLine className="mr-2 text-lg" /> Times to be Used: <Amount min={couponGeneratePreset.min_percent_balance} max={couponGeneratePreset.max_percent_balance} />
                                                            </div>
                                                        </>
                                                    }

                                                    <div className="flex items-center mb-2">
                                                        <RiMoneyDollarCircleLine className="mr-2 text-lg" /> Max Total Price: <Amount min={couponGeneratePreset.min_total_price} max={couponGeneratePreset.max_total_price} sign={'$'} />
                                                    </div>

                                                    <div className="flex items-center mb-2">
                                                        <RiMoneyDollarCircleLine className="mr-2 text-lg" /> Max Coupon Price: <Amount min={couponGeneratePreset.min_coupon_price} max={couponGeneratePreset.max_coupon_price} sign={'$'} />
                                                    </div>

                                                    {couponGeneratePreset.one_item ?
                                                        <div className="text-gray-700 mb-2">
                                                            {couponGeneratePreset.one_item === 1 ? "Coupon can be generated with single items only." : "Coupon can be generated for maximum of two items."}
                                                        </div>
                                                        : null
                                                    }

                                                    {couponGeneratePreset.beer === 'none' ?
                                                        <div className="flex items-center text-red-700 mb-2">
                                                            <RiStopCircleLine className="mr-2 text-lg" /> NO BEERS ALLOWED ON THIS COUPON
                                                        </div>
                                                        : null
                                                    }

                                                    {couponGeneratePreset.redeemable_in_user_branch_only ?
                                                        <div className="flex items-center text-red-700 mb-2">
                                                            <RiShieldUserLine className="mr-2 text-lg" /> CAN ONLY BE REDEEMED ON TEAM MEMBER'S BRANCH
                                                        </div>
                                                        : null
                                                    }

                                                    {couponGeneratePreset.expiration_type === "hours" ?
                                                        <div className="flex items-center mb-2">
                                                            <RiCalendarEventLine className="mr-2 text-lg" /> Expire Hours: <Amount min={couponGeneratePreset.min_expire_hours} max={couponGeneratePreset.max_expire_hours} /> HOURS
                                                        </div>
                                                        :
                                                        <div className="flex items-center mb-2">
                                                            <RiCalendarEventLine className="mr-2 text-lg" /> Expire Date: {couponGeneratePreset.expiration_date}
                                                        </div>
                                                    }

                                                    <div className="mb-2">
                                                        <strong>Frequency:</strong> {couponGeneratePreset.times_per_day} PER DAY, {couponGeneratePreset.times_per_week} PER WEEK
                                                    </div>
                                                </div>

                                                <div className="mt-2 pt-2 flex justify-end items-center border-t border-gray-200 gap-2">
                                                    <Button className="flex items-center" type={"link"} icon={<RiEditLine />} onClick={() => onCouponGeneratePresetEdit(couponGeneratePreset)}></Button>
                                                    <Popconfirm
                                                        title="Are you sure you want to duplicate this preset?"
                                                        onConfirm={() => handleDuplicateConfirm(couponGeneratePreset)}
                                                        okText="Yes"
                                                        cancelText="No"

                                                    >
                                                        <Button type={"link"} className="flex items-center" icon={<RiFileCopyLine />} ></Button>
                                                    </Popconfirm>
                                                    <Button type={"link"} className="flex items-center" icon={<RiUserLine />} onClick={() => onManageRoles(couponGeneratePreset)}></Button>
                                                    <Button type={"link"} className="flex items-center" icon={<RiShieldUserLine />} onClick={() => onManageDisabledFoods(couponGeneratePreset)}></Button>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                                :
                                <div className="w-full text-left text-gray-400 pb-1 pt-2 mt-2 border-t border-gray-200">You have no coupon generate presets on Patty Slaps.</div>
                            }
                        </SkeletonWrapper>
                    </div>
                </div>
            </div>
        </motion.div>
    );
};

export default ManageGeneratePresets;
