import React, {useEffect, useState} from 'react';
import {Form, notification, Tag} from "antd";
import {useSelector} from "react-redux";
import {motion} from "framer-motion";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faMoneyBill1Wave, faPlusCircle, faUserEdit} from "@fortawesome/free-solid-svg-icons";
import SkeletonWrapper from "../../../../../components/skeletons/SkeletonWrapper";
import GenericSkeleton from "../../../../../components/skeletons/GenericSkeleton";
import DiscountPreset from "../../../../../http/DiscountPreset";
import DiscountPresetModal from "./DiscountPresetModal";
import DefaultContainer from "../../../../../components/DefaultContainer";
import {RiAddCircleFill, RiAddLine} from "@remixicon/react";

const ManageDiscountPresets = () => {
    const [form] = Form.useForm();
    const user = useSelector(state => state.auth.user);
    const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
    const [loading, setLoading] = useState(true);
    const [discountPresets, setDiscountPresets] = useState([]);
    const [editDiscountPreset, setEditDiscountPreset] = useState(false);
    const [userModalVisible, setDiscountPresetModalVisible] = useState(false);
    const [validationFields, setValidationFields] = useState(null);
    const [saving, setSaving] = useState(false);

    useEffect(() => {
        reload(true)
    }, [])

    const reload = (forceShowLoading = false) => {
        if (!discountPresets || forceShowLoading)
            setLoading(true);
        DiscountPreset.Index((response) => {
            setLoading(false);
            setDiscountPresets(response.data.data);
        }, (err) => {
            setLoading(false);
        });
    }

    const onDiscountPresetModalCancel = () => {
        setDiscountPresetModalVisible(false);
        setEditDiscountPreset(null);
        setValidationFields(null);
        form.resetFields();
    }

    const onDiscountPresetEdit = (user) => {
        setEditDiscountPreset(user);
        setValidationFields(null);
        setDiscountPresetModalVisible(true);
    }

    const onDiscountPresetCreate = () => {
        setEditDiscountPreset(null);
        setDiscountPresetModalVisible(true);
    }

    const onSave = (values, isEdit) => {
        if (isEdit) {
            setSaving(true);
            DiscountPreset.Update(editDiscountPreset.id, values, (response) => {
                reload();
                const discount_preset = response.data.data;
                notification['success']({
                    message: 'Success!',
                    placement: "bottomRight",
                    description:
                        `Discount Preset ${discount_preset.name} has been updated!`,
                });
                setSaving(false);
                setDiscountPresetModalVisible(false);
                setValidationFields(null);
            }, (err) => {
                setValidationFields(err.response.data.errors);
                setSaving(false);
            });
        } else {
            setSaving(true);
            DiscountPreset.Store(values, (response) => {
                reload();
                const discount_preset = response.data.data;
                notification['success']({
                    message: 'Success!',
                    placement: "bottomRight",
                    description:
                        `Discount Preset ${discount_preset.name} has been created!`,
                });
                setSaving(false);
                setDiscountPresetModalVisible(false);
                setValidationFields(null);
                form.resetFields();
            }, (err) => {
                setValidationFields(err.response.data.errors);
                setSaving(false);
            });
        }
    }

    return (
            <motion.div
                initial={{opacity: 0, scale: 1.1, y: 0}}
                animate={{opacity: 1, scale: 1, y: 0}}
                className={`w-full less-margin mx-auto max-w-8xl py-2`}
            >
                {
                    <DiscountPresetModal visible={userModalVisible} onCancel={onDiscountPresetModalCancel} validationFields={validationFields} editDiscountPreset={editDiscountPreset} onSave={onSave} saving={saving} form={form}/>
                }

                <div className="flex flex-col mx-auto w-full">
                    <div className="w-full flex flex-col lg:flex-row">
                        <div
                            className="flex-1 p-3 flex flex-col items-center justify-center pt-3 pb-2 w-full">
                            <SkeletonWrapper loading={loading} skeleton={GenericSkeleton}>
                                <div className={"text-left w-full"}>
                                    <div className={"font-bold text-xl flex flex-row items-center"}>
                                        Discount Presets
                                        <RiAddCircleFill className={"ml-2 cursor-pointer"} onClick={onDiscountPresetCreate} />
                                    </div>
                                    <div>These discount presets can only be used on cashier app and as a reference for generating coupons and for reports.</div>
                                    <hr className={"mt-2 mb-2"}/>
                                </div>
                                {discountPresets && discountPresets.length > 0 ?
                                    <div className={"w-full"}>
                                        {discountPresets.map(discountPreset => {
                                            return (
                                                <div className={"mb-1 border border-gray-200 rounded-sm shadow-md mb-2 w-full px-2 py-2 md:py-1 bg-gray-50 flex items-center flex-row"}>
                                                    <div className={"flex flex-col md:flex-row md:items-center w-full flex-1"}>
                                                    <span className={`whitespace-nowrap mr-2 ${!discountPreset.status ? 'opacity-30' : null}`}>
                                                        <div className={"flex flex-row items-center font-bold"}>{discountPreset.name} {!discountPreset.status ? <Tag color={'red'} style={{marginLeft: "10px"}}>Disabled</Tag> : null}</div>
                                                        {discountPreset.percents ?
                                                            <div>{discountPreset.percents}</div>
                                                            : null}

                                                        {discountPreset.max_total_price > 0 ?
                                                            <div>Max total price: ${discountPreset.max_total_price}</div>
                                                            : null}

                                                        {discountPreset.max_coupon_price > 0 ?
                                                            <div>Max coupon price: ${discountPreset.max_coupon_price}</div>
                                                            : null}

                                                        {discountPreset.one_item > 0 ?
                                                            <div>one item only: {discountPreset.one_item}</div>
                                                            : null}

                                                    </span>
                                                    </div>
                                                    <div className={"flex flex-row items-center md:justify-end justify-center mt-2 md:mt-0"}>
                                                        <FontAwesomeIcon icon={faEdit} className="block text-lg mr-2 cursor-pointer" aria-hidden="true" onClick={() => onDiscountPresetEdit(discountPreset)}/>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    :
                                    <div className={"w-full text-center text-gray-400 pb-1"}>There are no discount presets on Patty Slaps.</div>
                                }
                            </SkeletonWrapper>
                        </div>
                    </div>
                </div>
            </motion.div>

    );
};

export default ManageDiscountPresets;