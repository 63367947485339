import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import Checkout from "../../http/Checkout";
import {partiallyUpdateAuthProfile} from "../../redux/authentication/actionCreator";
import push from "../../history";
import T from "../../components/T";
import CheckoutItem from "../Checkout/CheckoutItem";
import Styled from "styled-components";
import {Collapse} from "antd";
import {roundToTwo} from "../../services/global";
const {Panel} = Collapse;

const PrintCheckout = () => {
    const [checkout, setCheckout] = useState(null);
    const [address, setAddress] = useState(null);
    const [user, setUser] = useState(null);
    let [taxRates] = useState(global.config.taxRates);
    const {uuid} = useParams();

    useEffect(() => {
        Checkout.Validate(uuid, (response) => {
            setCheckout(response.data.data.checkout);
            setAddress(response.data.data.address);
            setUser(response.data.data.user);
        }, (err) => {
        });
    }, []);

    function calculateSubTotalPrice(checkout) {
        const isDelivery = checkout.type === "delivery";
        return checkout.items.reduce((total, item) => {
            const itemTotal = item.quantity * (isDelivery ? item.price + item.delivery_diff_price : item.price);
            return total + itemTotal;
        }, 0);
    }

    function calculateTotalTax(checkout) {
        const subTotal = calculateSubTotalPrice(checkout);
        let t = 0;
        taxRates.map(rate => t += Number((subTotal * rate).toFixed(2)))
        return t;
    }

    return (
        checkout &&
        <div className={" bg-gray-100"}>
            <div className={"p-4"}>
                <div className={"w-full flex flex-col text-center"}>
                    <div className={"w-full flex flex-col items-center justify-center border-b border-gray-200 pb-2 mb-2 patty text-lg"}>
                        <img
                            className="h-16"
                            src={process.env.PUBLIC_URL + '/images/logo-small.png'}
                            alt="Patty Slaps"
                            aria-label={"logo"}
                        />
                        {address}
                    </div>


                    <div className={"w-full text-left pb-2 mb-2 border-b border-gray-200 flex flex-col uppercase"}>
                        <div className={"flex flex-row"}>
                            <div className={"pr-2 w-24 text-right font-bold"}>Customer:</div> {user.name}
                        </div>
                        <div className={"flex flex-row"}>
                            <div className={"pr-2 w-24 text-right font-bold"}>Type:</div> {checkout.type}
                        </div>
                        {checkout.type == "delivery" &&
                            <div className={"flex flex-row"}>
                                <div className={"pr-2 w-24 text-right font-bold"}>Platform:</div>
                                {checkout.platform}
                            </div>
                        }
                    </div>

                    <div>
                        {checkout.items.map((item, index) => {
                            return (
                                <div className="flex flex-row items-top pb-1">
                                    <div className="bg-gray-200 rounded-md w-8 h-8 flex items-center justify-center">
                                        {item.quantity}x
                                    </div>
                                    <div className="flex-1 flex flex-col pl-2 w-full text-left">
                                        <div className="font-bold text-base flex-1 pt-1">{item.name}</div>
                                        {item.formatted &&
                                            <OptionsWrapper>
                                                <div dangerouslySetInnerHTML={{__html: item.formatted}} className="pl-2 border-l border-gray-100"></div>
                                            </OptionsWrapper>
                                        }
                                    </div>
                                    <div className="pl-3 pt-1">
                                        <span className="text-l">${checkout.type == "delivery"?item.price+item.delivery_diff_price:item.price}</span>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div className={"w-full border-t border-gray-200 pt-2 mt-2 flex flex-col items-center justify-center text-base"}>
                        <div className="flex-row flex justify-between w-full">
                            <span className="flex-1 text-left"><T cap>Subtotal</T></span>
                            <span>${roundToTwo(calculateSubTotalPrice(checkout))}</span>
                        </div>
                        <div className="flex-row flex justify-between w-full">
                            <span className="flex-1 text-left"><T cap>Tax</T></span>
                            <span>${roundToTwo(calculateTotalTax(checkout))}</span>
                        </div>
                        <div className="flex-row flex justify-between w-full font-bold">
                            <span className="flex-1 text-left"><T cap>Total</T></span>
                            <span>${roundToTwo(calculateSubTotalPrice(checkout) + calculateTotalTax(checkout))}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const OptionsWrapper = Styled.div`
    span {        
        margin-right:8px;
        color:black;
        font-weight:500;
    }
    
    i {
        padding-left:0px;
        color:gray;
        font-size:13px;
    }
`;


export default PrintCheckout;