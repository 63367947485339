import React, {useEffect, useState, useRef} from 'react';
import {Table, Button, Input, InputNumber, notification, Popconfirm} from 'antd';
import MeatTypeService from '../../../../http/MeatTypeService';
import DefaultContainer from "../../../../components/DefaultContainer"; // corrected path

let nextTempId = 1;

const ManageMeatTypesBulk = () => {
    const [meatTypes, setMeatTypes] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        loadMeatTypes();
    }, []);

    const loadMeatTypes = () => {
        setLoading(true);
        MeatTypeService.index(
            (resp) => {
                setLoading(false);
                // For existing records with real IDs, also ensure we have a stable 'tempId'
                const fetched = resp.data.data.map(mt => ({
                    ...mt,
                    tempId: mt.id || -(nextTempId++) // if no real id, negative temp
                }));
                setMeatTypes(fetched);
            },
            (err) => {
                setLoading(false);
                notification.error({
                    message: 'Error',
                    description: 'Failed to load Meat Types'
                });
            }
        );
    };

    // Add new row in local state
    const handleAddRow = () => {
        const newRow = {
            id: null,
            tempId: -(nextTempId++), // a stable negative key for new row
            name: '',
            percentage: 0
        };
        setMeatTypes(prev => [...prev, newRow]);
    };

    // Remove row from local state
    const handleRemoveRow = (record) => {
        const filtered = meatTypes.filter(mt => mt !== record);
        setMeatTypes(filtered);
    };

    // Inline editing for "name"
    const handleNameChange = (val, record, index) => {
        const newData = [...meatTypes];
        newData[index] = {...record, name: val};
        setMeatTypes(newData);
    };

    // Inline editing for "percentage"
    const handlePercentageChange = (val, record, index) => {
        const newData = [...meatTypes];
        newData[index] = {...record, percentage: val};
        setMeatTypes(newData);
    };

    // "Save" => call the upsertBulk endpoint with the entire array
    const handleSaveAll = () => {
        setLoading(true);
        // Transform each row to { id, name, percentage } for the server
        // ignoring 'tempId'
        const payload = meatTypes.map(mt => ({
            id: mt.id,
            name: mt.name,
            percentage: mt.percentage
        }));

        MeatTypeService.upsertBulk(
            payload,
            (resp) => {
                setLoading(false);
                notification.success({
                    message: 'Success',
                    description: resp.data.message
                });
                // The server returns updated array with real IDs.
                // We re-assign tempIds to keep stable keys
                const updated = resp.data.data.map(mt => ({
                    ...mt,
                    tempId: mt.id || -(nextTempId++)
                }));
                setMeatTypes(updated);
            },
            (err) => {
                setLoading(false);
                if (err.response && err.response.data && err.response.data.message) {
                    notification.error({
                        message: 'Error',
                        description: err.response.data.message
                    });
                } else {
                    notification.error({
                        message: 'Error',
                        description: 'Failed to save Meat Types'
                    });
                }
            }
        );
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, record, index) => (
                <Input
                    value={text}
                    onChange={(e) => handleNameChange(e.target.value, record, index)}
                />
            )
        },
        {
            title: 'Percentage',
            dataIndex: 'percentage',
            key: 'percentage',
            render: (value, record, index) => (
                <InputNumber
                    min={0}
                    max={100}
                    step={0.1}
                    value={value}
                    onChange={(val) => handlePercentageChange(val, record, index)}
                    addonAfter="%"
                    style={{width: '100%'}}
                />
            )
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure to remove this row?"
                    onConfirm={() => handleRemoveRow(record)}
                >
                    <Button danger size="small">Remove</Button>
                </Popconfirm>
            )
        }
    ];

    return (
        <DefaultContainer style={{padding: '1rem'}}>
            <div>
                <h2 className="text-xl font-bold mb-2">Manage Meat Types (Bulk Edit)</h2>

                <Button onClick={handleAddRow} style={{marginBottom: '1rem'}}>
                    Add Meat Type
                </Button>

                <Table
                    columns={columns}
                    dataSource={meatTypes}
                    // The stable row key: use id if it exists, else tempId
                    rowKey={record => record.id || record.tempId}
                    loading={loading}
                    pagination={false}
                />

                <Button
                    type="primary"
                    style={{marginTop: '1rem'}}
                    onClick={handleSaveAll}
                    loading={loading}
                >
                    Save
                </Button>
            </div>
        </DefaultContainer>
    );
};

export default ManageMeatTypesBulk;
