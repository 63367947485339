// src/http/EmailTemplateService.js

import axios from 'axios';
import { checkLoggedIn, getAuthenticationHeaders } from "./Headers";

const EmailTemplateService = {
    getVendorTemplate: (successCb, failCb) => {
        axios.get(`/email-templates/vendor-template`, getAuthenticationHeaders())
            .then(response => successCb(response))
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },

    updateVendorTemplate: (data, successCb, failCb) => {
        axios.post(`/email-templates/vendor-template/update`, data, getAuthenticationHeaders())
            .then(response => successCb(response))
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },

    getVendorOrderCancelTemplate: (successCb, failCb) => {
        axios.get(`/email-templates/vendor-order-cancel-template`, getAuthenticationHeaders())
            .then(response => successCb(response))
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },

    updateVendorOrderCancelTemplate: (data, successCb, failCb) => {
        axios.post(`/email-templates/vendor-order-cancel-template/update`, data, getAuthenticationHeaders())
            .then(response => successCb(response))
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },

    getBatchVendorTemplate: (successCb, failCb) => {
        axios.get(`/email-templates/vendor-batch-order-template`, getAuthenticationHeaders())
            .then(response => successCb(response))
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },

    updateBatchVendorTemplate: (data, successCb, failCb) => {
        axios.post(`/email-templates/vendor-batch-order-template/update`, data, getAuthenticationHeaders())
            .then(response => successCb(response))
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },

    getTemplate: (key, successCb, failCb) => {
        axios.get(`/email-templates/${key}`, getAuthenticationHeaders())
            .then(response => successCb(response))
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },

    updateTemplate: (key, data, successCb, failCb) => {
        axios.post(`/email-templates/${key}/update`, data, getAuthenticationHeaders())
            .then(response => successCb(response))
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },
};

export default EmailTemplateService;